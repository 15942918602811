import Parse from 'parse';

class CollectionBase extends Parse.Object {
  constructor(attributes, options) {
    super('PlaylistBase', attributes, options);
  }

  static query() {
    const query = new Parse.Query(CollectionBase);
    query.include('createdBy');
    query.include('items.workoutType.segments');
    query.include('items.workoutType.createdBy');
    return query;
  }

  static featured() {
    const query = CollectionBase.query();
    query.equalTo('isFeatured', true);
    query.descending('createdAt');
    return query;
  }
}

export default CollectionBase;

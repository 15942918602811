import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDrvvi9yQ6lYkp8u7Hq-LPiX6R3IwjCcAk",
  authDomain: "krew-e4128.firebaseapp.com",
  databaseURL: "https://krew-e4128.firebaseio.com",
  projectId: "krew-e4128",
  storageBucket: "krew-e4128.appspot.com",
  messagingSenderId: "890789845721",
  appId: "1:890789845721:web:5e0d75bc9344b43c9e7cc8",
  measurementId: "G-E0YMHP6DX4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;

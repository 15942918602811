import Parse from 'parse';
import Segment from './Segment';
import User from './User';

class WorkoutType extends Parse.Object {
  static VALUE_TYPE_DISTANCE = 1;
  static VALUE_TYPE_TIMED = 2;
  static VALUE_TYPE_CALORIE = 3;
  static VALUE_TYPE_CUSTOM = 4;
  static VALUE_TYPE_JUSTROW = 5;

  constructor(attributes, options) {
    super('WorkoutType', attributes, options);
  }

  banner() {
     
  }

  static query() {
    const query = new Parse.Query(WorkoutType);
    query.notEqualTo('isDeleted', true);
    query.include('createdBy');
    query.include('segments');
    return query;
  }

  static featuredWorkoutsQuery() {
    const query = WorkoutType.query();
    query.matchesQuery('createdBy', User.featuredUsersQuery());
    query.equalTo('isFeatured', true);
    query.descending('createdAt');
    return query;
  }

  static rateTargetForSegment(segments, index, workoutSplit, previousWorkout) {
    if (workoutSplit && workoutSplit.targetRate) {
      return [workoutSplit.targetRate, workoutSplit.targetRateTolerance || 2];
    }

    let previousTarget = 0;
    let returnValue = null;
    segments.forEach((segment, idx) => {
      let targetRateVariable = segment.get('targetRateVariable');
      if (targetRateVariable && targetRateVariable.type) {
        if (
          targetRateVariable.type === WorkoutType.TARGET_TYPE_PREVIOUS_TARGET
        ) {
          if (previousTarget > 0) {
            let delta = targetRateVariable['delta'] || 0;
            previousTarget = coerceIn(previousTarget + delta, 12, 35);
          } else {
            previousTarget = 0;
          }
        } else if (
          targetRateVariable.type === WorkoutType.TARGET_TYPE_PREVIOUS_WORKOUT
        ) {
          let delta = targetRateVariable.delta || 0;
          previousTarget = coerceIn(
            previousWorkout.averageRate + delta,
            12,
            35
          );
        } else {
          previousTarget = 0;
        }
      } else if (segment.get('targetRate') > 0) {
        previousTarget = segment.get('targetRate');
      } else {
        previousTarget = 0;
      }

      if (idx === index && previousTarget > 0) {
        let tolerance = segment.get('targetRateTolerance') || 2;
        returnValue = [coerceIn(previousTarget, 0, 36), tolerance];
        return;
      }
    });

    return returnValue;
  }

  static paceTargetForSegment(segments, index, workoutSplit, previousWorkout) {
    if (workoutSplit && workoutSplit.targetPace) {
      return [workoutSplit.targetPace, workoutSplit.targetPaceTolerance || 2];
    }

    const personalRecordsDistance = {};
    const personalRecordsTime = {};
    let previousTarget = 0;
    let returnValue = null;
    segments.forEach((segment, idx) => {
      const targetPaceVariable = segment.get('targetPaceVariable');
      if (targetPaceVariable && targetPaceVariable.type) {
        if (targetPaceVariable.type === WorkoutType.TARGET_TYPE_PR_DISTANCE) {
          const base = targetPaceVariable.base;
          const delta = targetPaceVariable.delta || 0;
          const record = personalRecordsDistance[base];

          if (record && base) {
            previousTarget = coerceIn(record + delta, 70, 180);
          } else {
            previousTarget = 0;
          }
        } else if (
          targetPaceVariable.type === WorkoutType.TARGET_TYPE_PR_TIME
        ) {
          const base = targetPaceVariable.base;
          const delta = targetPaceVariable.delta || 0;
          const record = personalRecordsTime[base];

          if (record && base) {
            previousTarget = coerceIn(record + delta, 70, 180);
          } else {
            previousTarget = 0;
          }
        } else if (
          targetPaceVariable.type === WorkoutType.TARGET_TYPE_PREVIOUS_TARGET
        ) {
          if (previousTarget > 0) {
            const delta = targetPaceVariable.delta || 0;
            previousTarget = previousTarget + delta;
          } else {
            previousTarget = 0;
          }
        } else if (
          targetPaceVariable.type ===
            WorkoutType.TARGET_TYPE_PREVIOUS_WORKOUT &&
          previousWorkout
        ) {
          const delta = targetPaceVariable.delta || 0;
          previousTarget = previousWorkout.averagePace + delta;
        } else {
          previousTarget = 0;
        }
      } else if (segment.get('targetPace') > 0) {
        previousTarget = segment.get('targetPace');
      } else {
        previousTarget = 0;
      }

      if (idx === index && previousTarget > 0) {
        let tolerance = segment.get('targetPaceTolerance') || 2;
        returnValue = [previousTarget, tolerance];
        return;
      }
    });
    return returnValue;
  }

  getSegmentType() {
    return this.get('valueType') === 4 ? 'Interval' : 'Split';
  }

  getSegments() {
    if (this.has('segments')) return this.get('segments');
    if (
      this.get('valueType') === WorkoutType.VALUE_TYPE_CUSTOM &&
      !this.has('segments')
    ) {
      return [];
    }

    const segments = [];
    const splitLength = WorkoutType.getCalculatedSplitLength(
      this.get('valueType'),
      this.get('value')
    );
    let segmentValueType;
    switch (this.get('valueType')) {
      case WorkoutType.VALUE_TYPE_DISTANCE:
        segmentValueType = Segment.VALUE_TYPE_DISTANCE;
        break;
      case WorkoutType.VALUE_TYPE_TIMED:
        segmentValueType = Segment.VALUE_TYPE_TIMED;
        break;

      default:
        segmentValueType = null;
    }

    const value = this.get('value');
    const splits = this.get('splits') || [];
    for (let split = 1; split <= Math.ceil(value / splitLength); split++) {
      let work, targetRate;
      if (split * splitLength > value) {
        work = value % splitLength;
      } else {
        work = splitLength;
      }

      if (splits && splits[split - 1]) {
        targetRate = splits[split - 1];
      }

      const segment = new Segment();
      segment.set('value', work);
      segment.set('valueType', segmentValueType);
      segment.set('targetRate', targetRate);

      segments.push(segment);
    }

    return segments;
  }

  static getCalculatedSplitLength(valueType, value) {
    //if (this.get('splitLength')) return this.get('splitLength');

    if (value <= 0) return 0;

    switch (valueType) {
      case WorkoutType.VALUE_TYPE_TIMED:
        if (value <= 60) return 20;
        if (value === 240) return 60;
        return parseInt(Math.ceil(value / 5));

      case WorkoutType.VALUE_TYPE_DISTANCE:
        if (value <= 500) return 100;
        if (value === 2000) return 500;
        if (value === 42195) return 2000;
        return parseInt(Math.ceil(value / 5));

      default:
        return value / 5;
    }
  }
}

const coerceIn = (value, min, max) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

export default WorkoutType;

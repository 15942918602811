import Parse, { User } from 'parse';

class RelationShip extends Parse.Object {
  static TYPE_FRIEND = 'FRIEND';
  static TYPE_COACH = 'COACH';
  static TYPE_STUDENT = 'STUDENT';
  static TYPE_FOLLOW = 'FOLLOW';
  static TYPE_FOLLOWEDBY = 'FOLLOWEDBY';

  static STATUS_PENDING = 'PENDING';
  static STATUS_REJECTED = 'REJECTED';
  static STATUS_APPROVED = 'APPROVED';

  constructor(attributes, options) {
    super('RelationShip', attributes, options);
  }

  static query() {
    const query = new Parse.Query(RelationShip);
    query.equalTo('user1', User.createWithoutData(User.current().id));
    return query;
  }

  static friendsQuery() {
    const query = RelationShip.query();
    query.equalTo('type', RelationShip.TYPE_FRIEND);
    query.equalTo('status', RelationShip.STATUS_APPROVED);
    return query;
  }
}

export default RelationShip;

import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { StyledTab, StyledTabs } from "../../components/StyledTabs";
import { fetchWorkoutIfNeeded } from "../../redux/ducks/workout";
import WorkoutInformation from "../WorkoutTypeDetail/components/WorkoutInformation";
import WorkoutComments from "../WorkoutTypeDetail/components/WorkoutComments";
import WorkoutSummary from "./components/WorkoutSummary/WorkoutSummary";
import { getContent } from "@mui-treasury/layout";
import styled from "styled-components";
import ReactTimeago from "react-timeago";

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(15),
    padding: theme.spacing(3),
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const WorkoutDetail = ({ user }) => {
  const { url, params, path } = useRouteMatch();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.workout.byId[params.id]) || {
    isFetching: true,
  };

  useEffect(() => {
    dispatch(
      fetchWorkoutIfNeeded({ userId: params.userId || user.id, id: params.id })
    );
  }, [dispatch, user, params]);

  if (result.isFetching)
    return (
      <>
        <Header user={user} title="Loading workout details.." subtitle="" />
        <Content
          className={classes.container}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Content>
      </>
    );

  /*
  if (error)
    return (
      <Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <Typography variant='h4' paragraph>
            Uhoh
          </Typography>
          <Typography variant='body1'>{error}</Typography>
        </div>
      </Content>
    );
    */

  const workout = result.item;
  const workoutType = workout.get("workoutType");
  return (
    <>
      <Header
        user={user}
        title={workoutType.get("name")}
        subtitle={
          <>
            Workout finished <ReactTimeago date={workout.get("finishTime")} />{" "}
            by {user.get("username")}
          </>
        }
      />
      <Content className={classes.container}>
        <StyledTabs
          className={classes.tabsContainer}
          value={location.pathname}
          variant="standard"
        >
          <StyledTab label="Summary" value={url} to={url} />

          <StyledTab
            label="Details"
            value={url + "/details"}
            to={url + "/details"}
          />
          <StyledTab
            label="Comments"
            value={url + "/comments"}
            to={url + "/comments"}
          />
          <StyledTab
            label="Leaderboard"
            value={url + "/leaderboards"}
            to={url + "/leaderboards"}
            disabled
          />
        </StyledTabs>
        <Switch>
          <Route
            path={path}
            render={() => <WorkoutSummary workout={workout} />}
            exact
          />
          <Route path={`${path}/details`} exact>
            <WorkoutInformation workoutType={workoutType} />
          </Route>
          <Route path={`${path}/comments`} exact>
            <WorkoutComments workoutType={workoutType} />
          </Route>
          <Route path={`${path}/leaderboards`} exact>
            leaderboards
          </Route>
        </Switch>
      </Content>
    </>
  );
};

export default WorkoutDetail;

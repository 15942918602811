import {
  Avatar,
  createMuiTheme,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BarChartRounded as ActivityIcon,
  BuildRounded,
  CalendarTodayRounded,
  HomeRounded as HomeIcon,
  PlaylistPlayRounded as PlaylistPlayIcon,
} from "@material-ui/icons";
import Layout, {
  getCollapseBtn,
  getDrawerSidebar,
  getFooter,
  getSidebarContent,
  Root,
} from "@mui-treasury/layout";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { renderRoutes } from "react-router-config";
import styled from "styled-components";
import KrewIcon from "../../components/KrewIcon";
import ListItemLink from "../../components/ListItemLink";
import { User } from "../../parse";
import { refreshUser } from "../../redux/ducks/global";
import theme from "../../theme";

const DrawerSidebar = getDrawerSidebar(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Footer = getFooter(styled);

const drawerTheme = createMuiTheme({
  palette: {
    common: { black: "rgba(0, 0, 0, 1)", white: "#fff" },
    background: { paper: "#284a76", default: "#113058" },
    primary: {
      light: "#00ff00",
      main: "#C0C5CA",
      dark: "#00ff00",
      contrastText: "#fff",
    },
    secondary: {
      light: "#00ff00",
      main: "#00ff00",
      dark: "#00ff00",
      contrastText: "#fff",
    },
    text: {
      primary: "#C0C5CA",
      secondary: "#fff",
      disabled: "#00ff00",
      hint: "#00ff00",
    },
    divider: "#758399",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  logo: {
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const scheme = Layout();

scheme.configureHeader((builder) => {
  builder
    .create("header")
    .registerConfig("xs", {
      position: "relative",
    })
    .registerConfig("md", {
      position: "relative",
    });
});

scheme.configureEdgeSidebar((builder) => {
  builder
    .create("sidebar", { anchor: "left" })
    .registerTemporaryConfig("xs", {
      anchor: "left",
      width: 256, // 'auto' is only valid for temporary variant
    })
    .registerPermanentConfig("md", {
      width: 256, // px, (%, rem, em is compatible)
      collapsible: true,
      collapsedWidth: 64,
    });
});

const UserProfile = ({ user }) => {
  const username = user.get("username");
  const usersubscription = user.isPremiumSubscriber()
    ? "premium subscriber"
    : "basic member";
  return (
    <>
      <Divider />
      <List dense>
        <ListItem component={ListItemLink} to="/me" button>
          <ListItemAvatar>
            <Avatar src={user.has("image") && user.get("image").url()} />
          </ListItemAvatar>
          <ListItemText primary={username} secondary={usersubscription} />
        </ListItem>
      </List>
      <Divider />
    </>
  );
};

const Main = ({ route, location }) => {
  const classes = useStyles();
  const user = User.current();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(refreshUser());
  }, []);

  return (
    <Root scheme={scheme}>
      {({ state: { sidebar } }) => (
        <div className={classes.root}>
          <CssBaseline />
          <ThemeProvider theme={drawerTheme}>
            <DrawerSidebar sidebarId="sidebar">
              <SidebarContent>
                <div
                  style={{
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: drawerTheme.palette.background.default,
                  }}
                >
                  <KrewIcon className={classes.logo} />
                </div>
                <Divider />
                <div>
                  <List
                    dense
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      !sidebar.sidebar.collapsed && (
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Home
                        </ListSubheader>
                      )
                    }
                  >
                    <ListItem
                      component={ListItemLink}
                      to="/dashboard"
                      button
                      exact
                    >
                      <ListItemIcon>
                        <HomeIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                    {/*<ListItem component={ListItemLink} to='/explorer' button>
                      <ListItemIcon>
                        <PublicIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText primary='Explore' />
                  </ListItem>*/}
                    <ListItem component={ListItemLink} to="/collections" button>
                      <ListItemIcon>
                        <PlaylistPlayIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Collections" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List
                    dense
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      !sidebar.sidebar.collapsed && (
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Activity
                        </ListSubheader>
                      )
                    }
                  >
                    <ListItem component={ListItemLink} to="/activities" button>
                      <ListItemIcon>
                        <ActivityIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Workouts" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List
                    dense
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      !sidebar.sidebar.collapsed && (
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Live Workouts
                        </ListSubheader>
                      )
                    }
                  >
                    <ListItem
                      component={ListItemLink}
                      to="/liveworkouts"
                      button
                    >
                      <ListItemIcon>
                        <CalendarTodayRounded color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Scheduled" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List
                    dense
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      !sidebar.sidebar.collapsed && (
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Create
                        </ListSubheader>
                      )
                    }
                  >
                    <ListItem component={ListItemLink} to="/builder" button>
                      <ListItemIcon>
                        <BuildRounded color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="My Custom" />
                    </ListItem>
                  </List>
                  <Divider />
                </div>
              </SidebarContent>
              {!sidebar.sidebar.collapsed && user && (
                <UserProfile user={user} />
              )}

              <CollapseBtn style={{ backgroundColor: "inherit" }} />
            </DrawerSidebar>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            {renderRoutes(route.routes, { user })}
            <Footer style={{ maxHeight: 64, textAlign: "center" }}>
              © KREW. {new Date().getFullYear()}
            </Footer>
          </ThemeProvider>
        </div>
      )}
    </Root>
  );
};

export default Main;

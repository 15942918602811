import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DurationPicker from '../../../components/DurationPicker/DurationPicker';
import { TimeFormat } from '../../../components/TimeFormat';
import { Segment } from '../../../parse';
import WorkoutType from '../../../parse/WorkoutType';
import {
  setSplitIntervals,
  setSplitLength,
  setValue,
} from '../../../redux/ducks/workoutbuilder';

const WorkTime = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.workoutbuilder);

  const [workError, setWorkError] = useState(null);
  const [splitLengthError, setSplitLengthError] = useState(null);
  const [splitLengthLocal, setSplitLengthLocal] = useState(state.splitLength);

  const updateSplits = () => {
    const { value, splitLength, splitIntervals } = state;
    const splitNum = Math.ceil(value / splitLength);
    if (value < 20 || value > 3600 * 10 - 1 || splitLength === 0) {
      return;
    } else if (splitNum > 30) {
      return;
    }

    const currentSplitNum = splitIntervals.length;
    let newSplitIntervals = [...splitIntervals];

    for (let i = 0; i < currentSplitNum; i++) {
      newSplitIntervals[i].value =
        (i + 1) * splitLength > value ? value % splitLength : splitLength;
    }

    if (currentSplitNum < splitNum) {
      for (let i = currentSplitNum; i < splitNum; i++) {
        const splitWork =
          (i + 1) * splitLength > value ? value % splitLength : splitLength;
        newSplitIntervals.push({
          valueType: Segment.VALUE_TYPE_TIMED,
          value: splitWork,
          restValue: null,
          restType: null,
          restDescription: null,
          targets: {
            rate: {
              value: 0,
              type: -1,
              delta: 0,
            },
            pace: {
              value: 0,
              type: -1,
              delta: 0,
              base: null,
            },
            hr: {
              type: -1,
              value: [0, 100],
            },
          },
        });
      }
    } else if (currentSplitNum > splitNum) {
      newSplitIntervals = newSplitIntervals.splice(0, splitNum);
    }

    dispatch(setSplitIntervals(newSplitIntervals));
  };

  useEffect(() => {
    const value = state.value;
    if (!value || value < 20 || value > 3600 * 10 - 1) {
      setWorkError('Time must be between 20s and 9:59:59.');
    } else {
      setWorkError(null);
    }
  }, [state.value]);

  useEffect(() => {
    setSplitLengthLocal(state.splitLength);
  }, [state.splitLength]);

  const updateSplitLength = (value) => {
    validateSplitLength(
      value,
      WorkoutType.getCalculatedSplitLength(WorkoutType.VALUE_TYPE_TIMED, value)
    );
  };

  const validateSplitLength = (maxSplitLength, splitLength) => {
    const value = state.value;
    const minSplitLength = Math.max(20, Math.ceil(maxSplitLength / 30));

    setSplitLengthLocal(splitLength);
    if (maxSplitLength < 20 || value > 3600 * 10 - 1) return;

    if (
      !splitLength ||
      splitLength < minSplitLength ||
      splitLength > maxSplitLength
    ) {
      setSplitLengthError(
        `Split time must be between ${TimeFormat.format(
          minSplitLength
        )} and ${TimeFormat.format(maxSplitLength)}.`
      );
    } else {
      setSplitLengthError(null);
      dispatch(setSplitLength(splitLength));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateSplits();
    }, 200);
    return () => clearTimeout(timer);
  }, [state.splitLength]); // eslint-disable-line

  return (
    <Card>
      <CardHeader title='Work time' />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid xs item>
            <Typography variant='h5' align='center' gutterBottom>
              Time
            </Typography>
            <DurationPicker
              duration={state.value}
              onDurationChange={(value) => {
                dispatch(setValue(WorkoutType.VALUE_TYPE_TIMED, value));
                updateSplitLength(value);
              }}
            />
            {workError && (
              <Typography variant='body1' color='error'>
                {workError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Divider orientation='vertical' />
          </Grid>
          <Grid xs item>
            <Typography variant='h5' align='center' gutterBottom>
              Split length
            </Typography>
            <DurationPicker
              duration={splitLengthLocal}
              onDurationChange={(splitLength) =>
                validateSplitLength(state.value, splitLength)
              }
            />
            {splitLengthError && (
              <Typography variant='body1' color='error'>
                {splitLengthError}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WorkTime;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const KrewIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
        <polygon
          points='17.268749237060547,8.258783340454102 13.576122283935547,11.999998092651367 17.268749237060547,15.741212844848633 17.268749237060547,22.807693481445312 6.731250762939453,11.999998092651367 17.268749237060547,1.1923065185546875 17.268749237060547,8.258783340454102 '
          opacity={0.6}
        />
        <polygon points='13.576122283935547,11.999998092651367 6.731250762939453,18.934934616088867 6.731250762939453,5.065062522888184 ' />
      </svg>
    </SvgIcon>
  );
};

export default KrewIcon;

import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ChevronRightRounded as ChevronRightIcon } from "@material-ui/icons";
import React from "react";
import Linkify from "react-linkify";
import Pluralize from "react-pluralize";
import { useSelector } from "react-redux";
import CreatedByCard from "../../../components/CreatedByCard";
import ListItemLink from "../../../components/ListItemLink";
import WorkoutTypeValueText from "../../../components/WorkoutTypeValueText";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  media: {
    height: 240,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

const CollectionInformation = ({ base }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.global.user);
  const createdBy = base.get("createdBy");
  const description = base.get("description") || "";
  const items = base.get("items");
  return (
    <>
      <Grid spacing={2} container>
        <Grid xs={12} lg={8} item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                {base.has("image") && (
                  <CardMedia
                    className={classes.media}
                    image={base.get("image").url()}
                  />
                )}
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Information
                  </Typography>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {description.trim() === "" ? (
                      <Typography variant="body1" paragraph>
                        <i>No description given</i>
                      </Typography>
                    ) : (
                      description.split("\n").map((description, idx) => (
                        <Typography key={idx} variant="body1" paragraph>
                          {description}
                        </Typography>
                      ))
                    )}
                  </Linkify>

                  {user && createdBy.id === user.id && (
                    <>
                      <Divider className={classes.divider} />

                      <Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="overline" gutterBottom>
                              Subscribers
                            </Typography>
                            <Typography variant="h6">
                              {(base.get("members") || []).length}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={<Pluralize singular="Workout" count={items.length} />}
                />
                <CardContent class={classes.cardContent}>
                  <List>
                    {items.map((item) => {
                      const workoutType = item.get("workoutType");
                      const image = workoutType.has("image")
                        ? workoutType.get("image").url()
                        : base.has("image")
                        ? base.get("image").url()
                        : createdBy.has("image")
                        ? createdBy.get("image").url()
                        : null;
                      return (
                        <ListItem
                          component={ListItemLink}
                          to={"/explorer/" + workoutType.id}
                          button
                          exact
                        >
                          <ListItemAvatar>
                            <Avatar variant="rounded" src={image} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={workoutType.get("name")}
                            secondary={
                              <WorkoutTypeValueText
                                workoutType={workoutType}
                                isCreator={
                                  user &&
                                  workoutType.get("createdBy").id === user.id
                                }
                              />
                            }
                          />
                          <ListItemSecondaryAction>
                            <ChevronRightIcon />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} lg={4} item>
          <CreatedByCard createdBy={createdBy} />
        </Grid>
        <Grid xs={12} lg={8} item></Grid>
      </Grid>
    </>
  );
};

export default CollectionInformation;

import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import { TimeFormat } from '../../../../../components/TimeFormat';

const useStyles = makeStyles((theme) => ({
  statscard: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #0000001f',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: '1px solid #0000001f',
      },
    },
  },
}));

const WorkoutStatsOverview = ({ workout }) => {
  const classes = useStyles();
  return (
    <Paper>
      <Grid spacing={0} container>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Meters
          </Typography>
          <Typography variant='h3'>
            <NumberFormat
              value={workout.get('meters')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Time
          </Typography>
          <Typography variant='h3'>
            <TimeFormat time={workout.get('duration')} withMsPrecision />
          </Typography>
        </Grid>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Pace
          </Typography>
          <Typography variant='h3'>
            <TimeFormat
              time={workout.get('averageSplitTime')}
              withMsPrecision
            />
          </Typography>
        </Grid>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Rate
          </Typography>
          <Typography variant='h3'>
            <NumberFormat
              value={workout.get('averageSPM')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WorkoutStatsOverview;

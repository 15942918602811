import Parse from 'parse';

class Workout extends Parse.Object {
  constructor(attributes, options) {
    super('Workout', attributes, options);
  }

  static query() {
    const query = new Parse.Query(Workout);
    query.notEqualTo('isArchived', true);
    query.include('createdBy');
    query.include('comment.createdBy');
    query.include('workoutType.createdBy');
    query.include('workoutType.segments');
    return query;
  }
}

export default Workout;

import { combineReducers } from 'redux';
import { reducer as dashboard } from './ducks/dashboard';
import { reducer as activity } from './ducks/activity';
import { reducer as workout } from './ducks/workout';
import { reducer as workouttype } from './ducks/workouttype';
import { reducer as comments } from './ducks/comments';
import { reducer as live } from './ducks/live';
import { reducer as mycustom } from './ducks/mycustom';
import { reducer as collections } from './ducks/collections';
import { reducer as global, LOGOUT_SUCCESS } from './ducks/global';
import { reducer as workoutbuilder } from './ducks/workoutbuilder';

const combinedReducers = combineReducers({
  global,
  dashboard,
  activity,
  workout,
  workouttype,
  comments,
  live,
  mycustom,
  collections,
  workoutbuilder,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;

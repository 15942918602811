import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import TimeFormat from '../../../components/TimeFormat/TimeFormat';

const useStyles = makeStyles((theme) => ({
  statscard: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #0000001f',
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: '1px solid #0000001f',
      },
    },
  },
}));

const StatsOverview = ({ stats }) => {
  const classes = useStyles();
  return (
    <Paper>
      <Grid spacing={0} container>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Meters rowed
          </Typography>
          <Typography variant='h3'>
            <NumberFormat
              value={stats.metersRowed.value}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Total workouts
          </Typography>
          <Typography variant='h3'>
            <NumberFormat
              value={stats.totalWorkouts.value}
              displayType='text'
              thousandSeparator={true}
            />
          </Typography>
        </Grid>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Time rowed
          </Typography>
          <Typography variant='h3'>
            <TimeFormat time={stats.timeRowed.value} withMsPrecision />
          </Typography>
        </Grid>
        <Grid className={classes.statscard} xs={12} sm={6} md={3} item>
          <Typography variant='overline' gutterBottom>
            Calories burned
          </Typography>
          <Typography variant='h3'>
            <NumberFormat
              value={stats.caloriesBurned.value}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatsOverview;

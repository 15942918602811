import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ChevronRightRounded as ChevronRightIcon } from '@material-ui/icons';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TimeFormat } from '../../../components/TimeFormat';
import { fetchRecentActivityIfNeeded } from '../../../redux/ducks/dashboard';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    overflow: 'auto',
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  tableRow: {
    cursor: 'pointer',
  },
}));

const RecentActivity = ({ user }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const recentActivity = useSelector((state) => state.dashboard.recentActivity);

  useEffect(() => {
    dispatch(fetchRecentActivityIfNeeded({ user }));
  }, [dispatch, user]);
  return (
    <Card className={classes.root}>
      <CardHeader
        title='Recent activity'
        action={
          <Button
            aria-label='See all activity'
            onClick={() => history.push('/activities')}
          >
            See all activity <ChevronRightIcon />
          </Button>
        }
      />
      <CardContent className={classes.content}>
        {recentActivity.isFetching ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          recentActivity.items && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Workout</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Distance</TableCell>
                  <TableCell>Pace</TableCell>
                  <TableCell>SPM</TableCell>
                  <TableCell>Calories</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentActivity.items.map((workout, index) => (
                  <TableRow
                    key={index}
                    onClick={() => history.push('/activities/' + workout.id)}
                    className={classes.tableRow}
                    hover
                  >
                    <TableCell>
                      {workout.get('workoutType').get('name')}
                    </TableCell>
                    <TableCell>
                      <TimeFormat
                        time={workout.get('duration')}
                        withMsPrecision
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={workout.get('meters')}
                        displayType='text'
                        thousandSeparator={true}
                        decimalScale={0}
                      />
                    </TableCell>
                    <TableCell>
                      <TimeFormat
                        time={workout.get('averageSplitTime')}
                        withMsPrecision
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={workout.get('averageSPM')}
                        displayType='text'
                        thousandSeparator={true}
                        decimalScale={0}
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={workout.get('caloriesBurned')}
                        displayType='text'
                        thousandSeparator={true}
                        decimalScale={0}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        )}
      </CardContent>
    </Card>
  );
};

export default RecentActivity;

import Parse from 'parse'

class Comment extends Parse.Object {
  constructor(attributes, options) {
    super('Comment', attributes, options);
  }

  static query() {
    return Parse.Query(Comment)
  }
}

export default Comment

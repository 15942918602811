import React from 'react';
import { Segment } from '../../../parse';

const HRValue = ({ value: { type, value } }) => {
  if (value[0] === 0 && value[1] === 100) {
    return <i>No target</i>;
  } else if (type === Segment.HRM_TARGET_TYPE_CUSTOM) {
    return (
      <>
        {value[0]}% - {value[1]}%
      </>
    );
  } else {
    return (
      <>
        Zone <b>{type + 1}</b>
      </>
    );
  }
};

export default HRValue;

import moment from "moment";
import { combineReducers } from "redux";
import { Workout } from "../../parse";

export const REQUEST_WORKOUTS_BY_MONTH = "activity/REQUEST_WORKOUTS_BY_MONTH";
export const RECEIVE_WORKOUTS_BY_MONTH = "activity/RECEIVE_WORKOUTS_BY_MONTH";
export const SELECT_MONTH = "activity/SELECT_MONTH";
export const SET_VIEW_TYPE = "activity/SET_VIEW_TYPE";
export const SET_FILTER = "activity/SET_FILTER";

export function setViewType(viewType) {
  return {
    type: SET_VIEW_TYPE,
    viewType,
  };
}

const defaultViewType = localStorage.getItem("activity.viewType") || "calendar";
const viewType = (state = defaultViewType, action) => {
  switch (action.type) {
    case SET_VIEW_TYPE:
      localStorage.setItem("activity.viewType", action.viewType);
      return action.viewType;
    default:
      return state;
  }
};

export function selectMonth(year, month) {
  return {
    type: SELECT_MONTH,
    payload: { year, month },
  };
}

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1;
const selectedMonth = (state = { year, month }, action) => {
  switch (action.type) {
    case SELECT_MONTH:
      return { ...action.payload };
    default:
      return state;
  }
};

// #region Filter
export function setFilter(key, value) {
  return {
    type: SET_FILTER,
    key,
    value,
  };
}

const filter = (
  state = {
    valueType: 0,
  },
  action
) => {
  switch (action.type) {
    case SET_FILTER:
      return { ...state.filter, [action.key]: action.value };
    default:
      return state;
  }
};
// #endregion

// #region Workouts
export const fetchWorkoutsIfNeeded = (payload) => {
  return (dispatch, getState) => {
    const { year, month } = payload;
    if (shouldFetchWorkouts(getState(), year, month)) {
      return dispatch(fetchWorkouts(payload));
    }
  };
};

function requestWorkouts(user, year, month) {
  return {
    type: REQUEST_WORKOUTS_BY_MONTH,
    user,
    year,
    month,
  };
}

function receiveWorkouts(year, month, items) {
  return {
    type: RECEIVE_WORKOUTS_BY_MONTH,
    receivedAt: Date.now(),
    year,
    month,
    items,
  };
}

function fetchWorkouts(payload) {
  return async (dispatch) => {
    const { user, year, month } = payload;
    const date = new Date(`${year}-${month}-01`);
    dispatch(requestWorkouts(user, year, month));
    const query = Workout.query();
    query.equalTo("createdBy", user);
    query.greaterThanOrEqualTo(
      "finishTime",
      moment(date).startOf("month").toDate()
    );
    query.lessThanOrEqualTo("finishTime", moment(date).endOf("month").toDate());
    query.descending("finishTime");
    const items = await query.find();
    return dispatch(receiveWorkouts(year, month, items));
  };
}

function shouldFetchWorkouts(state, year, month) {
  const workouts = state.activity.workoutsByMonth[`${year}_${month}`];
  if (!workouts) {
    return true;
  } else if (workouts.isFetching) {
    return false;
  } else {
    return workouts.didInvalidate;
  }
}

const workouts = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_WORKOUTS_BY_MONTH:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_WORKOUTS_BY_MONTH:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};

const workoutsByMonth = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_WORKOUTS_BY_MONTH:
    case RECEIVE_WORKOUTS_BY_MONTH:
      const { year, month } = action;
      const key = `${year}_${month}`;
      return Object.assign({}, state, {
        [key]: workouts(state[key], action),
      });
    default:
      return state;
  }
};
// #endregion

export const reducer = combineReducers({
  viewType,
  selectedMonth,
  workoutsByMonth,
  filter,
});

import { TextField } from "@material-ui/core";
import React from "react";

const Weight = ({ weight, setWeight, isMetric }) => {
  if (isMetric) {
    return (
      <TextField
        fullWidth
        label="Weight (kg)"
        variant="outlined"
        type="number"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
      />
    );
  } else {
    return (
      <TextField
        fullWidth
        label="Weight (lbs)"
        variant="outlined"
        type="number"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
      />
    );
  }
};

export default Weight;

import { combineReducers } from "redux";
import { Collection, CollectionBase, RelationShip } from "../../parse";

export const REQUEST_FEATURED_COLLECTIONS =
  "collections/REQUEST_FEATURED_COLLECTIONS";
export const RECEIVE_FEATURED_COLLECTIONS =
  "collections/RECEIVE_FEATURED_COLLECTIONS";

export const REQUEST_COLLECTION_BY_ID = "collections/REQUEST_COLLECTION_BY_ID";
export const REQUEST_COLLECTION_BY_ID_ERROR =
  "collections/REQUEST_COLLECTION_BY_ID_ERROR";
export const RECEIVE_COLLECTION_BY_ID = "collections/RECEIVE_COLLECTION_BY_ID";

export const REQUEST_COLLECTION_BASE_BY_ID =
  "collections/REQUEST_COLLECTION_BASE_BY_ID";
export const REQUEST_COLLECTION_BASE_BY_ID_ERROR =
  "collections/REQUEST_COLLECTION_BASE_BY_ID_ERROR";
export const RECEIVE_COLLECTION_BASE_BY_ID =
  "collections/RECEIVE_COLLECTION_BASE_BY_ID";

export const SET_CREATED_BY = "collections/SET_CREATED_BY";
export const CREATED_BY_COMMUNITY = "community";
export const CREATED_BY_FRIENDS = "friends";
export const CREATED_BY_ME = "me";

export const REQUEST_COLLECTIONS = "collections/REQUEST_COLLECTIONS";
export const RECEIVE_COLLECTIONS = "collections/RECEIVE_COLLECTIONS";

// #region Filter
export function setCreatedBy(createdBy) {
  return {
    type: SET_CREATED_BY,
    createdBy,
  };
}

const createdBy = (state = CREATED_BY_COMMUNITY, action) => {
  switch (action.type) {
    case SET_CREATED_BY:
      return action.createdBy;
    default:
      return state;
  }
};
// #endregion

// #region Collections
export const fetchCollectionsIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchCollections(getState())) {
      return dispatch(fetchCollections(payload));
    }
  };
};

function requestCollections() {
  return {
    type: REQUEST_COLLECTIONS,
  };
}

function receiveCollections(items) {
  return {
    type: RECEIVE_COLLECTIONS,
    receivedAt: Date.now(),
    items,
  };
}

function fetchCollections(payload) {
  return async (dispatch) => {
    dispatch(requestCollections());

    let query = CollectionBase.query();
    const { createdBy } = payload;

    switch (createdBy) {
      case CREATED_BY_COMMUNITY:
        query.equalTo("isPublic", true);
        break;
      case CREATED_BY_FRIENDS:
        query.equalTo("isFriend", true);
        query.matchesKeyInQuery(
          "createdBy",
          "user2.objectId",
          RelationShip.friendsQuery()
        );
        break;
      case CREATED_BY_ME:
        query = Collection.query();
        break;
      default:
        break;
    }

    query.descending("updatedAt");
    const items = await query.find();
    return dispatch(receiveCollections(items));
  };
}

function shouldFetchCollections(state) {
  const collections = state.collections.collections;
  if (collections.length === 0) {
    return true;
  } else if (collections.isFetching) {
    return false;
  } else {
    return true;
  }
}

const collections = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COLLECTIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_COLLECTIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

// #region Featured Collections
export const fetchFeaturedCollectionsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchFeaturedCollections(getState())) {
      return dispatch(fetchFeaturedCollections());
    }
  };
};

function requestFeaturedCollections() {
  return {
    type: REQUEST_FEATURED_COLLECTIONS,
  };
}

function receiveFeaturedCollections(items) {
  return {
    type: RECEIVE_FEATURED_COLLECTIONS,
    receivedAt: Date.now(),
    items,
  };
}

function fetchFeaturedCollections(payload) {
  return async (dispatch) => {
    dispatch(requestFeaturedCollections(payload));
    const query = CollectionBase.featured();
    const items = await query.find();
    return dispatch(receiveFeaturedCollections(items));
  };
}

function shouldFetchFeaturedCollections(state) {
  const collections = state.collections.featured;
  if (collections.items.length === 0) {
    return true;
  } else if (collections.isFetching) {
    return false;
  } else {
    return collections.didInvalidate;
  }
}

const featured = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FEATURED_COLLECTIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_FEATURED_COLLECTIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

// #region Collection
export const fetchCollectionIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchCollection(getState(), payload.id)) {
      return dispatch(fetchCollection(payload.id));
    }
  };
};

function requestCollection(id) {
  return {
    type: REQUEST_COLLECTION_BY_ID,
    id,
  };
}

function requestCollectionError(id, code, error) {
  return {
    type: REQUEST_COLLECTION_BY_ID_ERROR,
    id,
    code,
    error,
  };
}

function receiveCollection(id, item) {
  return {
    type: RECEIVE_COLLECTION_BY_ID,
    receivedAt: Date.now(),
    id,
    item,
  };
}

function fetchCollection(id) {
  return async (dispatch) => {
    dispatch(requestCollection(id));
    try {
      const query = Collection.query();
      const item = await query.get(id);
      return dispatch(receiveCollection(id, item));
    } catch (e) {
      return dispatch(requestCollectionError(id, e.code, e.message));
    }
  };
}

function shouldFetchCollection(state, id) {
  const collection = state.collections.byId[id];
  if (!collection) {
    return true;
  } else if (collection.isFetching) {
    return false;
  } else {
    return collection.didInvalidate;
  }
}

const collection = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COLLECTION_BY_ID:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_COLLECTION_BY_ID:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: action.item,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_COLLECTION_BY_ID:
    case RECEIVE_COLLECTION_BY_ID:
      return Object.assign({}, state, {
        [action.id]: collection(state[action.id], action),
      });
    default:
      return state;
  }
};
// #endregion

// #region CollectionBase
export const fetchCollectionBaseIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchCollectionBase(getState(), payload.id)) {
      return dispatch(fetchCollectionBase(payload.id));
    }
  };
};

function requestCollectionBase(id) {
  return {
    type: REQUEST_COLLECTION_BASE_BY_ID,
    id,
  };
}

function requestCollectionBaseError(id, code, error) {
  return {
    type: REQUEST_COLLECTION_BASE_BY_ID_ERROR,
    id,
    code,
    error,
  };
}

function receiveCollectionBase(id, item) {
  return {
    type: RECEIVE_COLLECTION_BASE_BY_ID,
    receivedAt: Date.now(),
    id,
    item,
  };
}

function fetchCollectionBase(id) {
  return async (dispatch) => {
    dispatch(requestCollectionBase(id));
    try {
      console.log("here");
      const query = CollectionBase.query();
      const item = await query.get(id);
      console.log("her2");
      return dispatch(receiveCollectionBase(id, item));
    } catch (e) {
      return dispatch(requestCollectionBaseError(id, e.code, e.message));
    }
  };
}

function shouldFetchCollectionBase(state, id) {
  const collection = state.collections.byBaseId[id];
  if (!collection) {
    return true;
  } else if (collection.isFetching) {
    return false;
  } else {
    return collection.didInvalidate;
  }
}

const collectionBase = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COLLECTION_BASE_BY_ID:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_COLLECTION_BASE_BY_ID:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: action.item,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};

const byBaseId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_COLLECTION_BASE_BY_ID:
    case RECEIVE_COLLECTION_BASE_BY_ID:
      return Object.assign({}, state, {
        [action.id]: collectionBase(state[action.id], action),
      });
    default:
      return state;
  }
};
// #endregion

export const reducer = combineReducers({
  byId,
  byBaseId,
  featured,
  createdBy,
  collections,
});

import React from 'react';

class TimeFormat extends React.Component {
  static format(time, withMsPrecision, keepEmpty = false) {
    let ms = '';
    if (withMsPrecision) {
      ms = '.' + parseFloat(time).toFixed(1).slice(-1);
    }
    const sec_num = parseInt(time, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return (
      [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0 || keepEmpty)
        .join(':') + ms
    );
  }

  render() {
    const { time, withMsPrecision = false } = this.props;
    return TimeFormat.format(time, withMsPrecision);
  }
}

export default TimeFormat;

import { CircularProgress, makeStyles } from '@material-ui/core';
import { User } from 'parse';
import React, { useEffect } from 'react';
import { getContent } from '@mui-treasury/layout';
import styled from 'styled-components';

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Logout = ({ history }) => {
  const classes = useStyles();
  useEffect(() => {
    async function logout(history) {
      await User.logOut();
      history.push('/auth/login');
    }
    logout(history);
  }, [history]);

  return (
    <Content className={classes.root}>
      <CircularProgress color='primary' />
    </Content>
  );
};

export default Logout;

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { renderRoutes } from "react-router-config";
import ErrorBoundary from "./components/ErrorBoundary";
import routes from "./routes";

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ErrorBoundary>{renderRoutes(routes)}</ErrorBoundary>
    </MuiPickersUtilsProvider>
  );
};

export default App;

import React from 'react';
import { Segment } from '../../../parse';

const RateValue = ({ value: { type, value, delta }, previousWorkout }) => {
  if (
    type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
    type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT
  ) {
    const deltaObject =
      delta < 0 ? (
        <>
          <b>{delta}</b> SPM
        </>
      ) : delta > 0 ? (
        <>
          <b>+{delta}</b> SPM
        </>
      ) : null;

    if (type === Segment.TARGET_TYPE_PREVIOUS_TARGET) {
      return <>Previous target {deltaObject}</>;
    } else if (type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT) {
      return (
        <>
          {previousWorkout} {deltaObject}
        </>
      );
    }
  } else {
    if (value === 0) {
      return <i>No target</i>;
    } else if (value === 1) {
      return <b>FREE</b>;
    } else if (value === 26) {
      return <b>MAX</b>;
    } else {
      return (
        <>
          <b>{value + 10}</b> SPM
        </>
      );
    }
  }
};

export default RateValue;

import { combineReducers } from 'redux';
import { WorkoutType } from '../../parse';

export const REQUEST_WORKOUT_TYPE = 'workouttype/REQUEST_WORKOUT_TYPE';
export const RECEIVE_WORKOUT_TYPE = 'workouttype/RECEIVE_WORKOUT_TYPE';

// #region Workout
export const fetchWorkoutTypeIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchWorkoutType(getState(), payload.id)) {
      return dispatch(fetchWorkoutType(payload.id));
    }
  };
};

function requestWorkoutType(id) {
  return {
    type: REQUEST_WORKOUT_TYPE,
    id,
  };
}

function receiveWorkoutType(id, item) {
  return {
    type: RECEIVE_WORKOUT_TYPE,
    receivedAt: Date.now(),
    id,
    item,
  };
}

function fetchWorkoutType(id) {
  return async (dispatch) => {
    dispatch(requestWorkoutType(id));
    const query = WorkoutType.query();
    const item = await query.get(id);
    return dispatch(receiveWorkoutType(id, item));
  };
}

function shouldFetchWorkoutType(state, id) {
  const workout = state.workouttype.byId[id];
  if (!workout) {
    return true;
  } else if (workout.isFetching) {
    return false;
  } else {
    return workout.didInvalidate;
  }
}

const workoutType = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_WORKOUT_TYPE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_WORKOUT_TYPE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: action.item,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

const byId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_WORKOUT_TYPE:
    case RECEIVE_WORKOUT_TYPE:
      return Object.assign({}, state, {
        [action.id]: workoutType(state[action.id], action),
      });
    default:
      return state;
  }
};

export const reducer = combineReducers({
  byId,
});

import {
  AppBar,
  Backdrop,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Slider,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  DeleteRounded,
  LoopRounded,
  SaveRounded,
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getContent } from '@mui-treasury/layout';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ContentCopyIcon from '../../components/ContentCopyIcon';
import DurationPicker from '../../components/DurationPicker/DurationPicker';
import Header from '../../components/Header';
import SubscriptionRequired from '../../components/SubscriptionRequired';
import { TimeFormat } from '../../components/TimeFormat';
import { Segment, WorkoutType } from '../../parse';
import { invalidateCustomWorkouts } from '../../redux/ducks/mycustom';
import {
  addInterval,
  duplicateInterval,
  fetchWorkoutTypeIfNeeded,
  newWorkoutType,
  removeInterval,
  resetWorkoutBuilder,
  saveWorkoutType,
  setCurrentSplitInterval,
  setIntervalRest,
  setIntervalValue,
  setSplitIntervals,
} from '../../redux/ducks/workoutbuilder';
import BasicInformation from './components/BasicInformation';
import HRValue from './components/HRValue';
import PaceValue from './components/PaceValue';
import RateValue from './components/RateValue';
import SettingsTags from './components/SettingsTags';
import TargetHR from './components/TargetHR';
import TargetPace from './components/TargetPace';
import TargetRate from './components/TargetRate';
import WorkDistance from './components/WorkDistance';
import AddToCollection from './components/AddToCollection';
import WorkTime from './components/WorkTime';

const Content = getContent(styled);

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(4),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  panel: {
    display: 'block',
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  addNewInterval: {
    border: '2px dashed #cccccc',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: theme.spacing(2),
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  toolbarSpacer: {
    flexGrow: 1,
  },
  chipGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const WorkoutBuilder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.workoutbuilder);
  const params = useParams();
  const classes = useStyles();
  const user = useSelector((state) => state.global.user);

  useEffect(() => {
    return history.block(() => {
      dispatch(resetWorkoutBuilder());
    });
  }, [history, dispatch]);

  useEffect(() => {
    switch (params.id) {
      case 'distance':
        dispatch(newWorkoutType(WorkoutType.VALUE_TYPE_DISTANCE, 100, 100));
        break;
      case 'time':
        dispatch(newWorkoutType(WorkoutType.VALUE_TYPE_TIMED, 60, 60));
        break;
      case 'custom':
        dispatch(newWorkoutType(WorkoutType.VALUE_TYPE_CUSTOM, 0, 0));
        break;
      default:
        dispatch(fetchWorkoutTypeIfNeeded({ id: params.id }));
    }
  }, [dispatch, user, params]);

  const handlePanelChange = (panelIndex) => (_, isExpanded) => {
    dispatch(setCurrentSplitInterval(isExpanded ? panelIndex : false));
  };

  const applyTargetsToComing = (currentSplit) => {
    const newSplits = [...state.splitIntervals];
    for (let i = currentSplit; i < newSplits.length; i++) {
      newSplits[i].targets = { ...newSplits[currentSplit].targets };
    }
    dispatch(setSplitIntervals(newSplits));
  };

  // RATE
  const segmentCanUsePreviousTargetRate = (index) => {
    if (state.splitIntervals[index - 1]) {
      const target = state.splitIntervals[index - 1].targets.rate;
      if (target.type === Segment.TARGET_TYPE_CUSTOM && target.value > 0) {
        return true;
      } else if (target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET) {
        return true;
      }
    }

    return false;
  };

  /*const segmentNeedsPreviousTargetRate = (index) => {
    if (state.splitIntervals[index]) {
      const target = state.splitIntervals[index].targets.rate;
      return target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET;
    }
    return false;
  };*/

  // PACE
  const segmentCanUsePreviousTargetPace = (index) => {
    if (state.splitIntervals[index - 1]) {
      const target = state.splitIntervals[index - 1].targets.pace;
      if (target.type === Segment.TARGET_TYPE_CUSTOM && target.value > 0) {
        return true;
      } else if (
        target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
        target.type === Segment.TARGET_TYPE_PR_DISTANCE ||
        target.type === Segment.TARGET_TYPE_PR_DISTANCE
      ) {
        return true;
      }
    }

    return false;
  };

  /*const segmentNeedsPreviousTargetPace = (index) => {
    if (state.splitIntervals[index]) {
      const target = state.splitIntervals[index].targets.pace;
      return target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET;
    }
    return false;
  };*/

  if (state.workoutType.isSaved) {
    dispatch(invalidateCustomWorkouts());
    return <Redirect to='/builder' />;
  }

  if (!user || state.workoutType.isFetching)
    return (
      <>
        <Header user={user} title='Loading workout details..' subtitle='' />
        <Content className={classes.container}>
          <CircularProgress color='primary' />
        </Content>
      </>
    );

  if (
    state.valueType === WorkoutType.VALUE_TYPE_CUSTOM &&
    !user.isPremiumSubscriber()
  )
    return (
      <>
        <Header user={user} title='Workout Builder' subtitle='' />
        <Content className={classes.container}>
          <SubscriptionRequired />
        </Content>
      </>
    );

  const canEditWorkout =
    user.get('roles') === 'admin' ||
    !(state.workoutType.item && state.workoutType.item.get('usageCount') > 0);

  return (
    <>
      <Header
        user={user}
        title='Workout Builder'
        subtitle="Workouts created in builder will show up in your KREW app, you might have to press the 'Create' tab for the list to be updated."
      />

      <Content className={classes.container}>
        {state.workoutType.isSaving && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Grid container spacing={2}>
          <Grid xs={12} lg={8} item>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <BasicInformation />
              </Grid>

              {!canEditWorkout && (
                <Grid xs={12} item>
                  <Card>
                    <CardHeader title='Information' />
                    <Divider />
                    <CardContent>
                      <Typography variant='body1' gutterbottom>
                        This workout has been used at least once, therefore you
                        can not edit anything that changes how the workout is
                        setup up.
                      </Typography>
                      <Typography variant='body1' gutterbottom>
                        You can still change title, description and some
                        settings.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {canEditWorkout &&
                state.valueType === WorkoutType.VALUE_TYPE_DISTANCE && (
                  <Grid xs={12} item>
                    <WorkDistance />
                  </Grid>
                )}
              {canEditWorkout &&
                state.valueType === WorkoutType.VALUE_TYPE_TIMED && (
                  <Grid xs={12} item>
                    <WorkTime />
                  </Grid>
                )}

              {canEditWorkout && (
                <Grid xs={12} item>
                  {state.splitIntervals.map((split, index) => (
                    <ExpansionPanel
                      key={index}
                      expanded={state.currentSplitInterval === index}
                      onChange={handlePanelChange(index)}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>
                          {state.valueType === WorkoutType.VALUE_TYPE_CUSTOM &&
                            'Interval'}
                          {state.valueType !== WorkoutType.VALUE_TYPE_CUSTOM &&
                            'Split'}{' '}
                          {index + 1}
                        </Typography>
                        <Fade in={state.currentSplitInterval !== index}>
                          <Typography className={classes.secondaryHeading}>
                            {split.valueType ===
                              Segment.VALUE_TYPE_DISTANCE && (
                              <NumberFormat
                                value={split.value}
                                displayType='text'
                                thousandSeparator={true}
                                decimalScale={0}
                                suffix='m'
                              />
                            )}
                            {split.valueType === Segment.VALUE_TYPE_TIMED && (
                              <TimeFormat time={split.value} />
                            )}
                            {' • Rate: '}
                            <RateValue
                              value={split.targets.rate}
                              previousWorkout={
                                state.linkedWorkoutTypes &&
                                Object.keys(state.linkedWorkoutTypes).length > 0
                                  ? Object.keys(state.linkedWorkoutTypes)[0]
                                  : null
                              }
                            />
                            {' • Pace: '}
                            <PaceValue
                              value={split.targets.pace}
                              previousWorkout={
                                state.linkedWorkoutTypes &&
                                Object.keys(state.linkedWorkoutTypes).length > 0
                                  ? Object.keys(state.linkedWorkoutTypes)[0]
                                  : null
                              }
                            />
                            {' • HR: '}
                            <HRValue value={split.targets.hr} />
                          </Typography>
                        </Fade>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.panel}>
                        {state.valueType === WorkoutType.VALUE_TYPE_CUSTOM && (
                          <>
                            <Grid container spacing={2}>
                              <Grid xs item>
                                <div className={classes.chipGroup}>
                                  <Chip
                                    label='Distance'
                                    color={
                                      split.valueType ===
                                      Segment.VALUE_TYPE_DISTANCE
                                        ? 'primary'
                                        : ''
                                    }
                                    onClick={(_) =>
                                      dispatch(
                                        setIntervalValue({
                                          index,
                                          valueType:
                                            Segment.VALUE_TYPE_DISTANCE,
                                          value: 100,
                                        })
                                      )
                                    }
                                    clickable
                                  />
                                  <Chip
                                    label='Time'
                                    color={
                                      split.valueType ===
                                      Segment.VALUE_TYPE_TIMED
                                        ? 'primary'
                                        : ''
                                    }
                                    onClick={(_) =>
                                      dispatch(
                                        setIntervalValue({
                                          index,
                                          valueType: Segment.VALUE_TYPE_TIMED,
                                          value: 60,
                                        })
                                      )
                                    }
                                    clickable
                                  />
                                </div>

                                {split.valueType ===
                                  Segment.VALUE_TYPE_TIMED && (
                                  <DurationPicker
                                    duration={split.value}
                                    onDurationChange={(value) =>
                                      dispatch(
                                        setIntervalValue({
                                          index,
                                          valueType: Segment.VALUE_TYPE_TIMED,
                                          value,
                                        })
                                      )
                                    }
                                  />
                                )}
                                {split.valueType ===
                                  Segment.VALUE_TYPE_DISTANCE && (
                                  <NumberFormat
                                    customInput={TextField}
                                    label='Distance'
                                    variant='outlined'
                                    margin='normal'
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fullWidth
                                    size='medium'
                                    value={split.value}
                                    onValueChange={(values) =>
                                      dispatch(
                                        setIntervalValue({
                                          index,
                                          valueType:
                                            Segment.VALUE_TYPE_DISTANCE,
                                          value: values.floatValue,
                                        })
                                      )
                                    }
                                  />
                                )}
                              </Grid>
                              <Grid item>
                                <Divider orientation='vertical' />
                              </Grid>
                              <Grid xs item>
                                <div className={classes.chipGroup}>
                                  <Chip
                                    label='Fixed'
                                    color={
                                      split.restType ===
                                      Segment.REST_TYPE_NORMAL
                                        ? 'primary'
                                        : ''
                                    }
                                    onClick={(_) =>
                                      dispatch(
                                        setIntervalRest({
                                          index,
                                          restType: Segment.REST_TYPE_NORMAL,
                                          restValue: split.restValue,
                                        })
                                      )
                                    }
                                    clickable
                                  />
                                  <Chip
                                    label='Undefined'
                                    color={
                                      split.restType ===
                                      Segment.REST_TYPE_VARIABLE
                                        ? 'primary'
                                        : ''
                                    }
                                    onClick={(_) =>
                                      dispatch(
                                        setIntervalRest({
                                          index,
                                          restType: Segment.REST_TYPE_VARIABLE,
                                          restValue: split.restValue,
                                        })
                                      )
                                    }
                                    clickable
                                  />
                                </div>

                                <br />
                                <Grid container justify='space-between'>
                                  <Grid item>
                                    <Typography variant='h5'>REST</Typography>
                                  </Grid>

                                  <Grid item>
                                    {split.restType ===
                                      Segment.REST_TYPE_NORMAL && (
                                      <TimeFormat time={split.restValue} />
                                    )}
                                    {split.restType ===
                                      Segment.REST_TYPE_VARIABLE && 'Undefined'}
                                  </Grid>
                                </Grid>

                                <Slider
                                  value={split.restValue}
                                  step={5}
                                  valueLabelDisplay='off'
                                  onChange={(_, value) =>
                                    dispatch(
                                      setIntervalRest({
                                        index,
                                        restType: Segment.REST_TYPE_NORMAL,
                                        restValue: value,
                                      })
                                    )
                                  }
                                  min={0}
                                  max={600}
                                  disabled={
                                    split.restType ===
                                    Segment.REST_TYPE_VARIABLE
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                          </>
                        )}

                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TargetRate
                              index={index}
                              target={split.targets.rate}
                              linkedWorkoutType={
                                state.linkedWorkoutTypes &&
                                Object.keys(state.linkedWorkoutTypes).length > 0
                                  ? Object.keys(state.linkedWorkoutTypes)[0]
                                  : null
                              }
                              hasPreviousTargetRate={segmentCanUsePreviousTargetRate(
                                index
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TargetPace
                              index={index}
                              target={split.targets.pace}
                              linkedWorkoutType={
                                state.linkedWorkoutTypes &&
                                Object.keys(state.linkedWorkoutTypes).length > 0
                                  ? Object.keys(state.linkedWorkoutTypes)[0]
                                  : null
                              }
                              hasPreviousTargetPace={segmentCanUsePreviousTargetPace(
                                index
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Divider className={classes.divider} />

                        <TargetHR index={index} target={split.targets.hr} />
                      </ExpansionPanelDetails>

                      {state.valueType !== WorkoutType.VALUE_TYPE_CUSTOM && (
                        <>
                          <Divider />

                          <AppBar
                            elevation={0}
                            position='relative'
                            color='#fff'
                          >
                            <Toolbar
                              className={classes.toolbar}
                              variant='dense'
                            >
                              <IconButton
                                edge='start'
                                color='inherit'
                                onClick={() =>
                                  dispatch(
                                    setCurrentSplitInterval(
                                      state.currentSplitInterval - 1
                                    )
                                  )
                                }
                                disabled={state.currentSplitInterval === 0}
                              >
                                <ChevronLeftRounded />
                              </IconButton>
                              <div className={classes.toolbarSpacer} />
                              <Tooltip title='Copy targets to coming splits'>
                                <IconButton
                                  color='inherit'
                                  onClick={() =>
                                    applyTargetsToComing(
                                      state.currentSplitInterval
                                    )
                                  }
                                >
                                  <LoopRounded />
                                </IconButton>
                              </Tooltip>

                              <IconButton
                                edge='end'
                                color='inherit'
                                onClick={() =>
                                  dispatch(
                                    setCurrentSplitInterval(
                                      state.currentSplitInterval + 1
                                    )
                                  )
                                }
                                disabled={
                                  state.currentSplitInterval >=
                                  state.splitIntervals.length - 1
                                }
                              >
                                <ChevronRightRounded />
                              </IconButton>
                            </Toolbar>
                          </AppBar>
                        </>
                      )}

                      {state.valueType === WorkoutType.VALUE_TYPE_CUSTOM && (
                        <>
                          <Divider />
                          <AppBar
                            elevation={0}
                            position='relative'
                            color='#fff'
                          >
                            <Toolbar
                              className={classes.toolbar}
                              variant='dense'
                            >
                              <Tooltip title='Previous interval'>
                                <IconButton
                                  edge='start'
                                  color='inherit'
                                  onClick={() =>
                                    dispatch(
                                      setCurrentSplitInterval(
                                        state.currentSplitInterval - 1
                                      )
                                    )
                                  }
                                  disabled={state.currentSplitInterval === 0}
                                >
                                  <ChevronLeftRounded />
                                </IconButton>
                              </Tooltip>
                              <div className={classes.toolbarSpacer} />
                              <Tooltip title='Delete interval'>
                                <IconButton
                                  onClick={(_) =>
                                    dispatch(removeInterval(index))
                                  }
                                  color='error'
                                >
                                  <DeleteRounded />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='Duplicate interval'>
                                <IconButton
                                  onClick={(_) =>
                                    dispatch(duplicateInterval(index))
                                  }
                                  color='inherit'
                                  disabled={state.splitIntervals.length === 30}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title='Copy targets to coming intervals'
                                onClick={() =>
                                  applyTargetsToComing(
                                    state.currentSplitInterval
                                  )
                                }
                                disabled={
                                  state.currentSplitInterval >=
                                  state.splitIntervals.length - 1
                                }
                              >
                                <IconButton color='inherit'>
                                  <LoopRounded />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='Next interval'>
                                <IconButton
                                  edge='end'
                                  color='inherit'
                                  onClick={() =>
                                    dispatch(
                                      setCurrentSplitInterval(
                                        state.currentSplitInterval + 1
                                      )
                                    )
                                  }
                                  disabled={
                                    state.currentSplitInterval >=
                                    state.splitIntervals.length - 1
                                  }
                                >
                                  <ChevronRightRounded />
                                </IconButton>
                              </Tooltip>
                            </Toolbar>
                          </AppBar>
                        </>
                      )}
                    </ExpansionPanel>
                  ))}
                </Grid>
              )}
              {canEditWorkout &&
                state.valueType === WorkoutType.VALUE_TYPE_CUSTOM &&
                state.splitIntervals.length < 30 && (
                  <Grid xs={12} item>
                    <Button
                      className={classes.addNewInterval}
                      onClick={(_) => dispatch(addInterval())}
                      fullWidth
                    >
                      Add new interval
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid xs={12} lg={4} item>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <SettingsTags canEditWorkout={canEditWorkout} />
              </Grid>
              <Grid xs={12} item>
                <AddToCollection workoutTypeId={params.id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Fab
          disabled={state.isSaving}
          variant='extended'
          color='primary'
          className={classes.fab}
          onClick={(_) => dispatch(saveWorkoutType())}
        >
          <SaveRounded className={classes.extendedIcon} />
          Save workout
        </Fab>
      </Content>
    </>
  );
};

export default WorkoutBuilder;

import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Header from "../../components/Header";
import Collections from "./components/Collections";
import RecentActivity from "./components/RecentActivity";
import StatsOverview from "./components/StatsOverview";
import FeaturedWorkouts from "./components/FeaturedWorkouts";
import { getContent } from "@mui-treasury/layout";
import styled from "styled-components";

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
}));

const Dashboard = ({ user }) => {
  const classes = useStyles();
  let stats = null;
  if (user.has("userStats")) {
    stats = user.get("userStats").get("allTime") || {};
  }
  return (
    <>
      <Header
        user={user}
        title="Dashboard"
        subtitle={
          "Welcome to KREW web dashboard, we will be rolling out new functionality as we go.\nPlease report any issues in our Facebook group!"
        }
      />
      <Content className={classes.container}>
        <Grid spacing={2} container>
          {stats && stats.totalWorkouts.value > 0 && (
            <Grid xs={12} item>
              <StatsOverview stats={stats} />
            </Grid>
          )}

          <Grid xs={12} item>
            <FeaturedWorkouts />
          </Grid>

          <Grid xs={12} lg={8} item>
            <RecentActivity user={user} />
          </Grid>
          <Grid xs={12} lg={4} item>
            <Collections user={user} />
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default Dashboard;

import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [thunkMiddleware];
export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

import React from "react";
import {
  Card,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
} from "@material-ui/core";
import ListItemLink from "./ListItemLink";
import { ChevronRightRounded as ChevronRightIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  media: {
    height: 240,
  },
}));

const CreatedByCard = ({ createdBy }) => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            alt={createdBy.get("username")}
            src={createdBy.has("image") && createdBy.get("image").url()}
          />
        }
        title={
          <>
            <Typography variant="overline">Created by</Typography>{" "}
            <Typography variant="h5">{createdBy.get("username")}</Typography>
          </>
        }
        disableTypography
      />
      {/*
      <CardContent className={classes.cardContent}>
        <List>
          <ListItem component={ListItemLink} to='/explorer' button exact>
            <ListItemText primary={`More from ${createdBy.get('username')}`} />
            <ListItemSecondaryAction>
              <ChevronRightIcon />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            component={ListItemLink}
            to={'/profiles/' + createdBy.id}
            button
            exact
          >
            <ListItemText primary='Full profile' />
            <ListItemSecondaryAction>
              <ChevronRightIcon />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>*/}
    </Card>
  );
};

export default CreatedByCard;

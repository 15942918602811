import Parse from 'parse';
import { WorkoutType, User, Segment } from '../../parse';
import CollectionItem from '../../parse/CollectionItem';

export const RESET = 'workoutbuilder/RESET';
export const NEW_WORKOUT_TYPE = 'workoutbuilder/NEW_WORKOUT_TYPE';
export const REQUEST_WORKOUT_TYPE = 'workout/REQUEST_WORKOUT_TYPE';
export const RECEIVE_WORKOUT_TYPE = 'workout/RECEIVE_WORKOUT_TYPE';
export const SAVE_WORKOUT_TYPE = 'workoutbuilder/SAVE_WORKOUT_TYPE';
export const SAVED_WORKOUT_TYPE = 'workoutbuilder/SAVED_WORKOUT_TYPE';

export const SET_LINKED_WORKOUT_TYPES =
  'workoutbuilder/SET_LINKED_WORKOUT_TYPES';
export const SET_WORKOUT_TYPE = 'workoutbuilder/SET_WORKOUT_TYPE';
export const SET_TITLE = 'workoutbuilder/SET_TITLE';
export const SET_DESCRIPTION = 'workoutbuilder/SET_DESCRIPTION';
export const SET_IMAGE = 'workoutbuilder/SET_IMAGE';
export const SET_FILE_OBJECT = 'workoutbuilder/SET_FILE_OBJECT';

export const SET_PUBLIC = 'workoutbuilder/SET_PUBLIC';
export const SET_FRIEND = 'workoutbuilder/SET_FRIEND';
export const SET_FEATURED = 'workoutbuilder/SET_FEATURED';
export const SET_TAGS = 'workoutbuilder/SET_TAGS';
export const TOGGLE_TAG = 'workoutbuilder/TOGGLE_TAG';

export const SET_WORK_VALUE = 'workoutbuilder/SET_WORK_VALUE';
export const SET_SPLIT_LENGTH = 'workoutbuilder/SET_SPLIT_LENGTH';
export const SET_SPLIT_INTERVALS = 'workoutbuilder/SET_SPLIT_INTERVALS';
export const SET_TARGET_PACE = 'workoutbuilder/SET_TARGET_PACE';
export const SET_TARGET_RATE = 'workoutbuilder/SET_TARGET_RATE';
export const SET_TARGET_HEART_RATE = 'workoutbuilder/SET_TARGET_HEART_RATE';

export const SET_CURRENT_SPLIT_INTERVAL =
  'workoutbuilder/SET_CURRENT_SPLIT_INTERVAL';
export const ADD_INTERVAL = 'workoutbuilder/ADD_INTERVAL';
export const REMOVE_INTERVAL = 'workoutbuilder/REMOVE_INTERVAL';
export const DUPLICATE_INTERVAL = 'workoutbuilder/DUPLICATE_INTERVAL';
export const SET_INTERVAL_VALUE = 'workoutbuilder/SET_INTERVAL_VALUE';
export const SET_INTERVAL_REST = 'workoutbuilder/SET_INTERVAL_REST';

export const TOGGLE_ADD_TO_COLLECTION =
  'workoutbuilder/TOGGLE_ADD_TO_COLLECTION';

export const resetWorkoutBuilder = () => {
  return {
    type: RESET,
  };
};

export const newWorkoutType = (valueType, value, splitLength) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.workoutbuilder.valueType === null) {
      return dispatch({
        type: NEW_WORKOUT_TYPE,
        valueType,
        value,
        splitLength,
      });
    }
  };
};

export const fetchWorkoutTypeIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchWorkoutType(getState())) {
      return dispatch(fetchWorkoutType(payload));
    }
  };
};

function requestWorkoutType(payload) {
  return {
    type: REQUEST_WORKOUT_TYPE,
    ...payload,
  };
}

function receiveWorkoutType(id, item) {
  return {
    type: RECEIVE_WORKOUT_TYPE,
    receivedAt: Date.now(),
    id,
    item,
  };
}

function fetchWorkoutType(payload) {
  return async (dispatch) => {
    dispatch(requestWorkoutType(payload));
    const user = Parse.User.current();
    const query = WorkoutType.query();
    query.equalTo('createdBy', User.createWithoutData(user.id));
    const workoutType = await query.get(payload.id);

    dispatch(setLinkedWorkoutTypes(workoutType.get('linkedWorkoutTypes')));
    dispatch(setValue(workoutType.get('valueType'), workoutType.get('value')));
    dispatch(setSplitLength(workoutType.get('splitLength')));
    dispatch(setTitle(workoutType.get('name')));
    dispatch(setDescription(workoutType.get('descriptionText')));
    if (workoutType.has('image')) {
      dispatch(setImage(workoutType.get('image').url()));
    }
    dispatch(setTags(workoutType.get('filterTags') || [0, 0, 0, 0, 0, 0]));
    dispatch(setPublic(workoutType.get('isPublic')));
    dispatch(setFriend(workoutType.get('isFriend')));
    dispatch(setFeatured(workoutType.get('isFeatured')));

    const splitIntervals = [];
    workoutType.get('segments').forEach((segment) => {
      const splitInterval = {
        valueType: segment.get('valueType'),
        value: segment.get('value'),
        restType: segment.get('restType'),
        restValue: segment.get('restValue'),
        targets: {
          rate: {
            value: 0,
            type: -1,
            delta: 0,
          },
          pace: {
            value: 0,
            type: -1,
            delta: 0,
            base: null,
          },
          hr: {
            type: -1,
            value: [0, 100],
          },
        },
      };

      // Rate
      if (segment.has('targetRateVariable')) {
        const targetRate = segment.get('targetRateVariable');
        splitInterval.targets.rate = {
          type: targetRate.type,
          value: 0,
          delta: targetRate.delta,
        };
      } else if (segment.has('targetRate')) {
        const targetRate = segment.get('targetRate');
        if (targetRate === 1) {
          splitInterval.targets.rate = {
            type: Segment.TARGET_TYPE_CUSTOM,
            value: 1,
          };
        } else if (targetRate > 1) {
          splitInterval.targets.rate = {
            type: Segment.TARGET_TYPE_CUSTOM,
            value: targetRate - 10,
          };
        }
      }

      // Pace
      if (segment.has('targetPaceVariable')) {
        const targetPace = segment.get('targetPaceVariable');
        splitInterval.targets.pace = {
          type: targetPace.type,
          value: 0,
          delta: targetPace.delta,
          base: targetPace.base,
        };
      } else if (segment.has('targetPace')) {
        const targetPace = segment.get('targetPace');
        if (targetPace > 0) {
          splitInterval.targets.pace = {
            type: Segment.TARGET_TYPE_CUSTOM,
            value: targetPace - 89,
          };
        }
      }

      // HR
      if (segment.has('targetHeartRate')) {
        const targetHeartRate = segment.get('targetHeartRate');
        if (targetHeartRate.type === Segment.HRM_TARGET_TYPE_CUSTOM) {
          splitInterval.targets.hr = {
            type: Segment.HRM_TARGET_TYPE_CUSTOM,
            value: [targetHeartRate.low, targetHeartRate.high],
          };
        } else if (targetHeartRate.type >= 0 && targetHeartRate.type <= 4) {
          splitInterval.targets.hr = {
            type: targetHeartRate.type,
            value: User.heartRateZones[targetHeartRate.type],
          };
        }
      }

      splitIntervals.push(splitInterval);
    });
    dispatch(setSplitIntervals(splitIntervals));

    return dispatch(receiveWorkoutType(payload.id, workoutType));
  };
}

function shouldFetchWorkoutType(state) {
  const workout = state.workoutbuilder.workoutType;
  if (!workout.item) {
    return true;
  } else if (workout.isFetching) {
    return false;
  } else {
    return workout.didInvalidate;
  }
}

export const saveWorkoutType = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const collections = state.global.collections;
    const user = state.global.user;
    const workoutBuilder = state.workoutbuilder;

    dispatch({ type: SAVE_WORKOUT_TYPE });

    const workoutType = workoutBuilder.workoutType.item || new WorkoutType();

    if (workoutBuilder.fileObject) {
      const workoutImage = new Parse.File(
        workoutBuilder.fileObject.name,
        workoutBuilder.fileObject
      );
      await workoutImage.save();

      workoutType.set('image', workoutImage);
    }

    workoutType.set('type', 4);
    workoutType.set('isPremium', false);
    workoutType.set('isDefault', false);

    workoutType.set('valueType', workoutBuilder.valueType);
    workoutType.set('isPublic', workoutBuilder.isPublic);
    workoutType.set('isFriend', workoutBuilder.isFriend);
    workoutType.set('isFeatured', workoutBuilder.isFeatured);
    workoutType.set('value', workoutBuilder.value);
    workoutType.set('splitLength', workoutBuilder.splitLength);
    workoutType.set('createdBy', User.createWithoutData(user.id));
    workoutType.set('name', workoutBuilder.title);
    workoutType.set('descriptionText', workoutBuilder.description);
    workoutType.set('filterTags', workoutBuilder.tags);

    const segments = workoutType.getSegments();
    for (let i = 0; i < workoutBuilder.splitIntervals.length; i++) {
      if (!segments[i]) {
        segments[i] = new Segment();
      }

      const splitInterval = workoutBuilder.splitIntervals[i];
      const segment = segments[i];
      segment.set('valueType', splitInterval.valueType);
      segment.set('value', splitInterval.value);
      segment.set('restType', splitInterval.restType);
      segment.set('restValue', splitInterval.restValue);

      // Rate
      const rate = splitInterval.targets.rate;
      segment.set('targetRate', null);
      segment.set('targetRateVariable', null);
      segment.set('targetRateTolerance', null);
      if (rate.type === Segment.TARGET_TYPE_CUSTOM && rate.value > 0) {
        segment.set('targetRateTolerance', 2);
        if (rate.value === 1) {
          segment.set('targetRate', 1);
        } else {
          segment.set('targetRate', rate.value + 10);
        }
      } else if (
        rate.type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
        rate.type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT
      ) {
        segment.set('targetRateTolerance', 2);
        segment.set('targetRateVariable', {
          type: rate.type,
          delta: rate.delta,
        });
      }

      // Pace
      const pace = splitInterval.targets.pace;
      segment.set('targetPace', null);
      segment.set('targetPaceVariable', null);
      segment.set('targetPaceTolerance', null);
      if (pace.type === Segment.TARGET_TYPE_CUSTOM && pace.value > 0) {
        segment.set('targetPaceTolerance', 2);
        segment.set('targetPace', pace.value + 89);
      } else if (
        pace.type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
        pace.type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT
      ) {
        segment.set('targetPaceTolerance', 2);
        segment.set('targetPaceVariable', {
          type: pace.type,
          delta: pace.delta,
        });
      } else if (
        pace.type === Segment.TARGET_TYPE_PR_DISTANCE ||
        pace.type === Segment.TARGET_TYPE_PR_TIME
      ) {
        segment.set('targetPaceTolerance', 2);
        segment.set('targetPaceVariable', {
          type: pace.type,
          delta: pace.delta,
          base: pace.base,
        });
      }

      // HR
      const hr = splitInterval.targets.hr;
      segment.set('targetHeartRate', null);
      if (hr.value[0] > 0 || hr.value[1] < 100) {
        if (hr.type === Segment.HRM_TARGET_TYPE_CUSTOM) {
          segment.set('targetHeartRate', {
            type: hr.type,
            low: hr.value[0],
            high: hr.value[1],
          });
        } else {
          segment.set('targetHeartRate', {
            type: hr.type,
          });
        }
      }
    }

    workoutType.set(
      'segments',
      segments.slice(0, workoutBuilder.splitIntervals.length)
    );

    await workoutType.save();

    if (collections.items) {
      const collectionUpdates = [];
      collections.items.forEach((collection) => {
        const base = collection.get('base');
        const items = base.get('items');

        if (
          workoutBuilder.addToCollections[base.id] &&
          items.filter((item) => item.get('workoutType').id === workoutType.id)
            .length === 0
        ) {
          const item = new CollectionItem();
          item.set(
            'workoutType',
            WorkoutType.createWithoutData(workoutType.id)
          );
          base.add('items', item);
          collectionUpdates.push(base);
        } else if (
          !workoutBuilder.addToCollections[base.id] &&
          items.filter((item) => item.get('workoutType').id === workoutType.id)
            .length > 0
        ) {
          base.removeAll(
            'items',
            items.filter(
              (item) => item.get('workoutType').id === workoutType.id
            )
          );
          collectionUpdates.push(base);
        }
      });

      if (collectionUpdates.length > 0) {
        await Parse.Object.saveAll(collectionUpdates);
      }
    }

    dispatch({ type: SAVED_WORKOUT_TYPE });
  };
};

export const setLinkedWorkoutTypes = (linkedWorkoutTypes) => {
  return {
    type: SET_LINKED_WORKOUT_TYPES,
    linkedWorkoutTypes,
  };
};

export const setTitle = (title) => {
  return {
    type: SET_TITLE,
    title,
  };
};

export const setDescription = (description) => {
  return {
    type: SET_DESCRIPTION,
    description,
  };
};

export const setImage = (image) => {
  return {
    type: SET_IMAGE,
    image,
  };
};

export const setFileObject = (fileObject) => {
  return {
    type: SET_FILE_OBJECT,
    fileObject,
  };
};

export const setPublic = (state) => {
  return {
    type: SET_PUBLIC,
    state,
  };
};

export const setFriend = (state) => {
  return {
    type: SET_FRIEND,
    state,
  };
};

export const setFeatured = (state) => {
  return {
    type: SET_FEATURED,
    state,
  };
};

export const toggleTag = (index) => {
  return {
    type: TOGGLE_TAG,
    index,
  };
};

export const setTags = (tags) => {
  return {
    type: SET_TAGS,
    tags,
  };
};

export const setValue = (valueType, value) => {
  return {
    type: SET_WORK_VALUE,
    valueType,
    value,
  };
};

export const setSplitLength = (splitLength) => {
  return {
    type: SET_SPLIT_LENGTH,
    splitLength,
  };
};

export const setSplitIntervals = (splitIntervals) => {
  return {
    type: SET_SPLIT_INTERVALS,
    splitIntervals,
  };
};

export const setTargetPace = (payload) => {
  return {
    type: SET_TARGET_PACE,
    payload,
  };
};

export const setTargetRate = (payload) => {
  return {
    type: SET_TARGET_RATE,
    payload,
  };
};

export const setTargetHeartRate = (payload) => {
  return {
    type: SET_TARGET_HEART_RATE,
    payload,
  };
};

export const setCurrentSplitInterval = (index) => {
  return {
    type: SET_CURRENT_SPLIT_INTERVAL,
    index,
  };
};

export const addInterval = () => {
  return {
    type: ADD_INTERVAL,
  };
};

export const removeInterval = (index) => {
  return {
    type: REMOVE_INTERVAL,
    index,
  };
};

export const duplicateInterval = (index) => {
  return {
    type: DUPLICATE_INTERVAL,
    index,
  };
};

export const setIntervalValue = (payload) => {
  return {
    type: SET_INTERVAL_VALUE,
    payload,
  };
};

export const setIntervalRest = (payload) => {
  return {
    type: SET_INTERVAL_REST,
    payload,
  };
};

export const toggleAddToCollection = ({ base, state }) => {
  return {
    type: TOGGLE_ADD_TO_COLLECTION,
    base,
    state,
  };
};

const initialState = {
  currentSplitInterval: false,
  workoutType: { isFetching: false, isSaving: false },
  linkedWorkoutTypes: null,
  fileObject: null,
  image: '',
  title: '',
  description: '',
  isPublic: false,
  isFriend: false,
  isFeatured: false,
  tags: [0, 0, 0, 0, 0, 0],
  valueType: null,
  value: 0,
  splitLength: 0,
  splitIntervals: [],
  addToCollections: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...initialState };
    case NEW_WORKOUT_TYPE:
      return {
        ...initialState,
        valueType: action.valueType,
        value: action.value,
        splitLength: action.splitLength,
      };
    case REQUEST_WORKOUT_TYPE:
      return Object.assign({}, state, {
        workoutType: {
          isSaving: false,
          isFetching: true,
          didInvalidate: false,
        },
      });
    case RECEIVE_WORKOUT_TYPE:
      return Object.assign({}, state, {
        workoutType: {
          isSaving: false,
          isFetching: false,
          didInvalidate: false,
          item: action.item,
          lastUpdated: action.receivedAt,
        },
      });
    case SAVE_WORKOUT_TYPE:
      return Object.assign({}, state, {
        workoutType: {
          ...state.workoutType,
          isSaving: true,
        },
      });
    case SAVED_WORKOUT_TYPE:
      return Object.assign({}, state, {
        workoutType: {
          ...state.workoutType,
          isSaving: false,
          isSaved: true,
        },
      });
    case SET_LINKED_WORKOUT_TYPES:
      return { ...state, linkedWorkoutTypes: action.linkedWorkoutTypes };
    case SET_TITLE:
      return { ...state, title: action.title };
    case SET_DESCRIPTION:
      return { ...state, description: action.description };
    case SET_IMAGE:
      return { ...state, image: action.image };
    case SET_FILE_OBJECT:
      return {
        ...state,
        fileObject: action.fileObject,
        image: URL.createObjectURL(action.fileObject),
      };
    case SET_PUBLIC:
      return { ...state, isPublic: action.state };
    case SET_FRIEND:
      return { ...state, isFriend: action.state };
    case SET_FEATURED:
      return { ...state, isFeatured: action.state };
    case SET_TAGS:
      return { ...state, tags: action.tags };
    case TOGGLE_TAG:
      const tags = [...state.tags];
      tags[action.index] = tags[action.index] === 0 ? 1 : 0;
      return { ...state, tags };
    case SET_WORK_VALUE:
      return { ...state, valueType: action.valueType, value: action.value };
    case SET_SPLIT_LENGTH:
      return { ...state, splitLength: action.splitLength };
    case SET_SPLIT_INTERVALS:
      return { ...state, splitIntervals: action.splitIntervals };
    case SET_TARGET_PACE:
      return {
        ...state,
        splitIntervals: state.splitIntervals.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              targets: {
                ...item.targets,
                pace: {
                  type: action.payload.type,
                  value: action.payload.value,
                  delta: action.payload.delta,
                  base: action.payload.base,
                },
              },
            };
          }
          return item;
        }),
      };
    case SET_TARGET_RATE:
      return {
        ...state,
        splitIntervals: state.splitIntervals.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              targets: {
                ...item.targets,
                rate: {
                  type: action.payload.type,
                  value: action.payload.value,
                  delta: action.payload.delta,
                },
              },
            };
          }
          return item;
        }),
      };
    case SET_TARGET_HEART_RATE:
      return {
        ...state,
        splitIntervals: state.splitIntervals.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              targets: {
                ...item.targets,
                hr: {
                  type: action.payload.type,
                  value: action.payload.value,
                },
              },
            };
          }
          return item;
        }),
      };
    case SET_CURRENT_SPLIT_INTERVAL:
      return {
        ...state,
        currentSplitInterval: action.index,
      };
    case ADD_INTERVAL:
      return {
        ...state,
        currentSplitInterval: state.splitIntervals.length,
        splitIntervals: [
          ...state.splitIntervals,
          {
            valueType: Segment.VALUE_TYPE_DISTANCE,
            value: 100,
            restValue: 0,
            restType: Segment.REST_TYPE_NORMAL,
            restDescription: null,
            targets: {
              rate: {
                type: Segment.TARGET_TYPE_NONE,
                value: 0,
                delta: 0,
              },
              pace: {
                type: Segment.TARGET_TYPE_NONE,
                value: 0,

                delta: 0,
                base: null,
              },
              hr: {
                type: Segment.HRM_TARGET_TYPE_CUSTOM,
                value: [0, 100],
              },
            },
          },
        ],
      };
    case REMOVE_INTERVAL:
      return {
        ...state,
        currentSplitInterval: Math.min(
          action.index,
          state.splitIntervals.length - 2
        ),
        splitIntervals: [
          ...state.splitIntervals.slice(0, action.index),
          ...state.splitIntervals.slice(action.index + 1),
        ],
      };
    case DUPLICATE_INTERVAL:
      return {
        ...state,
        currentSplitInterval: action.index + 1,
        splitIntervals: [
          ...state.splitIntervals.slice(0, action.index),
          { ...state.splitIntervals[action.index] },
          ...state.splitIntervals.slice(action.index),
        ],
      };
    case SET_INTERVAL_VALUE:
      return {
        ...state,
        splitIntervals: state.splitIntervals.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              valueType: action.payload.valueType,
              value: action.payload.value,
            };
          }
          return item;
        }),
      };
    case SET_INTERVAL_REST:
      return {
        ...state,
        splitIntervals: state.splitIntervals.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              restType: action.payload.restType,
              restValue: action.payload.restValue,
            };
          }
          return item;
        }),
      };
    case TOGGLE_ADD_TO_COLLECTION:
      const addToCollections = { ...state.addToCollections };
      addToCollections[action.base] = action.state;
      return { ...state, addToCollections };
    default:
      return state;
  }
};

import React from 'react';
import { WorkoutType } from '../parse';
import NumberFormat from 'react-number-format';
import { TimeFormat } from './TimeFormat';
import Pluralize from 'react-pluralize';

const WorkoutTypeValueText = ({ workoutType, isCreator }) => {
  const value = workoutType.get('value');
  const type = workoutType.get('valueType');
  const segments = workoutType.getSegments();
  const usageCount = workoutType.get('usageCount');
  let usageCountText = isCreator ? (
    <>
      <NumberFormat
        displayType='text'
        thousandSeparator={true}
        decimalScale={0}
        prefix=' • Workout finished '
        suffix={usageCount > 1 ? ' times' : ' time'}
        value={usageCount}
      />{' '}
    </>
  ) : (
    ''
  );
  usageCountText = '';

  switch (type) {
    case WorkoutType.VALUE_TYPE_DISTANCE:
      return (
        <>
          <NumberFormat
            displayType='text'
            thousandSeparator={true}
            decimalScale={0}
            value={value}
            suffix='m • Single Distance'
          />
          {usageCountText}
        </>
      );

    case WorkoutType.VALUE_TYPE_TIMED:
      return (
        <>
          <TimeFormat time={value} />m • Single Time
          {usageCountText}
        </>
      );

    case WorkoutType.VALUE_TYPE_CUSTOM:
      return (
        <>
          <Pluralize singular='interval' count={segments.length} />{' '}
          {usageCountText}
        </>
      );

    default:
      return <></>;
  }
};

export default WorkoutTypeValueText;

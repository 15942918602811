import { Grid, TextField } from "@material-ui/core";
import React from "react";

const Height = ({ height, setHeight, isMetric }) => {
  let feet, inches;
  if (!isMetric) {
    feet = parseInt(height / 12);
    inches = parseInt(height % 12);
  }

  if (isMetric) {
    return (
      <TextField
        fullWidth
        label="Height (cm)"
        variant="outlined"
        name="textmask"
        value={height}
        onChange={(e) => setHeight(e.target.value)}
        type="number"
        inputProps={{
          min: 0,
          max: 300,
        }}
      />
    );
  } else {
    return (
      <Grid spacing={2} container>
        <Grid xs={6} item>
          <TextField
            fullWidth
            label="Feet"
            variant="outlined"
            type="number"
            value={feet}
            onChange={(e) => {
              setHeight(parseInt(e.target.value || 0) * 12 + inches);
            }}
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            label="Inches"
            variant="outlined"
            type="number"
            value={inches}
            onChange={(e) => {
              setHeight(feet * 12 + parseInt(e.target.value || 0));
            }}
          />
        </Grid>
      </Grid>
    );
  }
};

export default Height;

import { combineReducers } from 'redux';
import { WorkoutType, CollectionBase } from '../../parse';

export const REQUEST_WORKOUT_TYPE_COMMENTS =
  'comments/REQUEST_WORKOUT_TYPE_COMMENTS';
export const RECEIVE_WORKOUT_TYPE_COMMENTS =
  'comments/RECEIVE_WORKOUT_TYPE_COMMENTS';

export const REQUEST_COLLECTION_BASE_COMMENTS =
  'comments/REQUEST_COLLECTION_BASE_COMMENTS';
export const RECEIVE_COLLECTION_BASE_COMMENTS =
  'comments/RECEIVE_COLLECTION_BASE_COMMENTS';

// #region WorkoutType
export const fetchWorkoutTypeCommentsIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchWorkoutTypeComments(getState(), payload.id)) {
      return dispatch(fetchWorkoutTypeComments(payload.id));
    }
  };
};

function requestWorkoutTypeComments(id) {
  return {
    type: REQUEST_WORKOUT_TYPE_COMMENTS,
    id,
  };
}

function receiveWorkoutTypeComments(id, items) {
  return {
    type: RECEIVE_WORKOUT_TYPE_COMMENTS,
    receivedAt: Date.now(),
    id,
    items,
  };
}

function fetchWorkoutTypeComments(id) {
  return async (dispatch) => {
    dispatch(requestWorkoutTypeComments(id));
    const workoutType = WorkoutType.createWithoutData(id);
    const query = workoutType.relation('comments').query();
    query.include('createdBy');
    query.descending('createdAt');
    const items = await query.find();
    return dispatch(receiveWorkoutTypeComments(id, items));
  };
}

function shouldFetchWorkoutTypeComments(state, id) {
  const comments = state.comments.byWorkoutTypeId[id];
  if (!comments) {
    return true;
  } else if (comments.isFetching) {
    return false;
  } else {
    return comments.didInvalidate;
  }
}

const workoutTypeComments = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_WORKOUT_TYPE_COMMENTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_WORKOUT_TYPE_COMMENTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};

const byWorkoutTypeId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_WORKOUT_TYPE_COMMENTS:
    case RECEIVE_WORKOUT_TYPE_COMMENTS:
      return Object.assign({}, state, {
        [action.id]: workoutTypeComments(state[action.id], action),
      });
    default:
      return state;
  }
};
// #endregion

// #region CollectionBase
export const fetchCollectionBaseCommentsIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchCollectionBaseComments(getState(), payload.id)) {
      return dispatch(fetchCollectionBaseComments(payload.id));
    }
  };
};

function requestCollectionBaseComments(id) {
  return {
    type: REQUEST_COLLECTION_BASE_COMMENTS,
    id,
  };
}

function receiveCollectionBaseComments(id, items) {
  return {
    type: RECEIVE_COLLECTION_BASE_COMMENTS,
    receivedAt: Date.now(),
    id,
    items,
  };
}

function fetchCollectionBaseComments(id) {
  return async (dispatch) => {
    dispatch(requestCollectionBaseComments(id));
    const collectionBase = CollectionBase.createWithoutData(id);
    const query = collectionBase.relation('comments').query();
    query.include('createdBy');
    query.descending('createdAt');
    const items = await query.find();
    return dispatch(receiveCollectionBaseComments(id, items));
  };
}

function shouldFetchCollectionBaseComments(state, id) {
  const comments = state.comments.byCollectionBaseId[id];
  if (!comments) {
    return true;
  } else if (comments.isFetching) {
    return false;
  } else {
    return comments.didInvalidate;
  }
}

const collectionBaseComments = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COLLECTION_BASE_COMMENTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_COLLECTION_BASE_COMMENTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};

const byCollectionBaseId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_COLLECTION_BASE_COMMENTS:
    case RECEIVE_COLLECTION_BASE_COMMENTS:
      return Object.assign({}, state, {
        [action.id]: collectionBaseComments(state[action.id], action),
      });
    default:
      return state;
  }
};
// #endregion

export const reducer = combineReducers({
  byWorkoutTypeId,
  byCollectionBaseId,
});

import {
  AppBar,
  Avatar,
  createMuiTheme,
  CssBaseline,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Toolbar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useRouteMatch } from "react-router-dom";
import KrewIcon from "../../components/KrewIcon";
import { TimeFormat } from "../../components/TimeFormat";
import theme from "../../theme";
import { useSelector, useDispatch } from "react-redux";
import { fetchLiveWorkoutIfNeeded } from "../../redux/ducks/live";
import app from "../../firebase";

const liveWorkoutTheme = createMuiTheme({
  palette: {
    common: { black: "rgba(0, 0, 0, 1)", white: "#fff" },
    background: { paper: "#333A45", default: "#1F2833" },
    primary: {
      light: "#00ff00",
      main: "#333A45",
      dark: "#00ff00",
      contrastText: "#fff",
    },
    secondary: {
      light: "#00ff00",
      main: "#fff",
      dark: "#00ff00",
      contrastText: "#fff",
    },
    text: {
      primary: "#C0C5CA",
      secondary: "#fff",
      disabled: "#00ff00",
      hint: "#00ff00",
    },
    divider: "#1F2833",
  },
});

const useStyles = makeStyles(() => ({
  root: {
    background: liveWorkoutTheme.palette.background.default,
    height: "100vh",
    padding: theme.spacing(2),
  },
  logo: {
    width: 32,
    height: 32,
    //marginRight: theme.spacing(1),
    margin: "0 auto",
  },
  title: {
    flexGrow: 1,
  },
  distance: {
    textAlign: "right",
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: liveWorkoutTheme.spacing(10),
    height: liveWorkoutTheme.spacing(10),
  },
  panelroot: {
    padding: "0 8px",
  },
  panelcontent: {
    margin: "8px 0",
  },
}));

const RaceParticipant = ({ workoutType, participants, participant }) => {
  const classes = useStyles();
  const progressMax = workoutType.get("value");
  const progress = (participant.distance / progressMax) * 100;
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        classes={{ root: classes.panelroot, content: classes.panelcontent }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{
            background: `linear-gradient(90deg, #434c59 ${progress}%, transparent ${progress}%)`,
          }}
        >
          <Grid item>
            <Avatar
              alt={participant.username}
              src={participant.image}
              className={classes.avatar}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="textPrimary">
              {participant.username}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {participant.status ? (
                <div>{participant.status}</div>
              ) : (
                <>
                  <TimeFormat time={participant.pace} withMsPrecision /> @{" "}
                  {participant.spm} SPM
                </>
              )}
            </Typography>
          </Grid>
          <Grid className={classes.distance} style={{ flex: 1 }} item>
            <Typography variant="h5" color="textSecondary">
              <NumberFormat
                value={participant.totaldistance}
                displayType="text"
                thousandSeparator={true}
                suffix="m"
                decimalScale={0}
              />
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 60 }}>#</TableCell>
              <TableCell style={{ width: 60 }}>Time</TableCell>
              <TableCell style={{ width: 60 }}>Distance</TableCell>
              <TableCell style={{ width: 60 }}>Pace</TableCell>
              <TableCell>SPM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participant.segments &&
              Object.values(participant.segments).map((segment, index) => (
                <TableRow key={index} hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <TimeFormat time={segment.time} withMsPrecision />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={segment.distance}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </TableCell>
                  <TableCell>
                    <TimeFormat time={segment.pace} withMsPrecision />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={segment.spm}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const LiveWorkoutBigScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { userId, workoutId } = match.params;
  const [participants, setParticipants] = useState();
  const result = useSelector(
    (state) =>
      state.live.byId[workoutId] || {
        isFetching: true,
      }
  );
  useEffect(() => {
    dispatch(fetchLiveWorkoutIfNeeded({ userId, id: workoutId }));
  }, [dispatch, userId, workoutId]);
  useEffect(() => {
    if (result.isFetching || result.isError) return;

    const raceRef = app.database().ref(`race/${result.item.id}`);
    const participantsRef = raceRef.child("participants");
    participantsRef.on("value", (snapshot) => {
      const participants = snapshot.toJSON();
      if (participants) {
        setParticipants(Object.values(participants));
      }
    });
    return () => {
      raceRef.off();
    };
  }, [result]);

  return (
    <ThemeProvider theme={liveWorkoutTheme}>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <KrewIcon className={classes.logo} />
          </Toolbar>
        </AppBar>
        <Toolbar />
        {result.isFetching && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!result.isFetching && result.isError && (
          <Typography variant="h4" className={classes.loading}>
            Error loading live workout ({result.code})
          </Typography>
        )}
        {!result.isFetching && participants && (
          <div>
            {participants
              .sort((item) => item.totaltime / item.totaldistance)
              .map((participant) => (
                <RaceParticipant
                  workoutType={result.item.get("workoutType")}
                  participants={participants}
                  participant={participant}
                />
              ))}
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default LiveWorkoutBigScreen;

import { combineReducers } from 'redux';
import { Workout, User } from '../../parse';

export const REQUEST_WORKOUT = 'workout/REQUEST_WORKOUT';
export const RECEIVE_WORKOUT = 'workout/RECEIVE_WORKOUT';

// #region Workout
export const fetchWorkoutIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchWorkout(getState(), payload)) {
      return dispatch(fetchWorkout(payload));
    }
  };
};

function requestWorkout(payload) {
  return {
    type: REQUEST_WORKOUT,
    ...payload,
  };
}

function receiveWorkout(id, item) {
  return {
    type: RECEIVE_WORKOUT,
    receivedAt: Date.now(),
    id,
    item,
  };
}

function fetchWorkout(payload) {
  return async (dispatch) => {
    dispatch(requestWorkout(payload));
    const query = Workout.query();
    query.equalTo('createdBy', User.createWithoutData(payload.userId));
    const item = await query.get(payload.id);
    return dispatch(receiveWorkout(payload.id, item));
  };
}

function shouldFetchWorkout(state, { id }) {
  const workout = state.workout.byId[id];
  if (!workout) {
    return true;
  } else if (workout.isFetching) {
    return false;
  } else {
    return workout.didInvalidate;
  }
}

const workout = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_WORKOUT:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_WORKOUT:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: action.item,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

const byId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_WORKOUT:
    case RECEIVE_WORKOUT:
      return Object.assign({}, state, {
        [action.id]: workout(state[action.id], action),
      });
    default:
      return state;
  }
};

export const reducer = combineReducers({
  byId,
});

import React from 'react';
import { WorkoutType } from '../parse';
import { Chip } from '@material-ui/core';

const RateTarget = ({ workoutType, segments, index }) => {
  const rate = WorkoutType.rateTargetForSegment(segments, index);
  const target = rate ? rate[0] : 0;

  let result = '-';
  let rateChip = null;
  let deltaChip = null;

  switch (true) {
    case target === 0:
      result = '-';
      break;
    case target === 1:
      result = 'FREE';
      break;
    case target === 36:
      result = 'MAX';
      break;
    case target < 35:
      result = target;
      break;
    default:
      result = 'N/A';
      break;
  }

  if (segments[index]) {
    const segment = segments[index];
    const targetPaceVariable = segment.get('targetPaceVariable');
    if (targetPaceVariable && targetPaceVariable.type !== undefined) {
      let delta = 0;

      switch (targetPaceVariable.type) {
        case WorkoutType.TARGET_TYPE_PREVIOUS_TARGET:
          delta = targetPaceVariable.delta || 0;
          rateChip = <Chip size='small' label='Prev' color='primary' />;
          break;

        case WorkoutType.TARGET_TYPE_PREVIOUS_WORKOUT:
          const linkedWorkoutTypes = workoutType.get('linkedWorkoutTypes');
          if (
            linkedWorkoutTypes &&
            Object.keys(linkedWorkoutTypes).length > 0
          ) {
            delta = targetPaceVariable.delta || 0;
            rateChip = (
              <Chip
                size='small'
                label={Object.keys(linkedWorkoutTypes)[0].toUpperCase()}
                color='primary'
              />
            );
          }
          break;

        default:
      }

      if (delta < 0) {
        deltaChip = (
          <Chip
            size='small'
            label={delta}
            style={{ backgroundColor: '#479D2E', color: '#fff' }}
          />
        );
      } else if (delta > 0) {
        deltaChip = (
          <Chip
            size='small'
            label={'+' + delta}
            style={{ backgroundColor: '#C5B331', color: '#fff' }}
          />
        );
      }
    }
  }

  /*
targetRateVariable?.let {
                var delta = 0
                when (it["type"]) {
                    WorkoutTypeDTO.TARGET_TYPE_PREVIOUS_TARGET -> {
                        delta = it["delta"] ?: 0
                        holder.rateType.apply {
                            isVisible = true
                            text = "Prev"
                        }
                    }
                    WorkoutTypeDTO.TARGET_TYPE_PREVIOUS_WORKOUT -> {
                        delta = it["delta"] ?: 0
                        holder.rateType.apply {
                            isVisible = true
                            text = workoutType.linkedWorkoutTypes?.keys?.first()?.toUpperCase()
                        }
                    }
                }

                if (delta < 0) {
                    holder.rateDelta.apply {
                        isVisible = true
                        text = delta.toString()
                        backgroundTintList =
                            getColorStateList(
                                resources,
                                R.color.delta_negative, null
                            )
                    }
                } else if (delta > 0) {
                    holder.rateDelta.apply {
                        isVisible = true
                        text = "+${delta}"
                        backgroundTintList =
                            getColorStateList(
                                resources,
                                R.color.delta_positive, null
                            )
                    }
                }

                Unit
            }
            */

  return (
    <>
      {result}
      {rateChip && (
        <div>
          {rateChip} {deltaChip}
        </div>
      )}
    </>
  );
};

export default RateTarget;

import React, { useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Avatar,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectionBaseCommentsIfNeeded } from '../../../redux/ducks/comments';
import ReactTimeago from 'react-timeago';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 240,
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const CollectionComments = ({ base }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(
    (state) => state.comments.byCollectionBaseId[base.id]
  ) || {
    isFetching: true,
  };

  useEffect(() => {
    dispatch(fetchCollectionBaseCommentsIfNeeded({ id: base.id }));
  }, [dispatch, base]);

  if (result.isFetching)
    return (
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Card>
            <CardContent>
              <Grid container spacing={2} justify='center' alignItems='center'>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <Typography variant='h4'>Loading comments..</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );

  const comments = result.items;
  if (comments.length === 0)
    return (
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Card>
            <CardContent>
              <Grid container spacing={2} justify='center' alignItems='center'>
                <Grid item>
                  <Typography variant='h4'>No comments here yet..</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container spacing={2}>
        {comments &&
          comments.map((comment, index) => {
            const createdBy = comment.get('createdBy');
            return (
              <Grid key={index} item xs={12}>
                <Card>
                  <CardHeader
                    title={createdBy.get('username')}
                    subheader={
                      <>Commented {<ReactTimeago date={comment.createdAt} />}</>
                    }
                    avatar={
                      <Avatar
                        className={classes.avatar}
                        alt={createdBy.get('username')}
                        src={
                          createdBy.has('image') && createdBy.get('image').url()
                        }
                      >
                        {createdBy.get('username').substr(0, 1).toUpperCase()}
                      </Avatar>
                    }
                  />
                  {comment.has('image') && (
                    <CardMedia
                      className={classes.media}
                      image={comment.get('image').url()}
                    />
                  )}
                  {comment.get('comment') && (
                    <CardContent>{comment.get('comment')}</CardContent>
                  )}
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default CollectionComments;

import Parse, { User } from 'parse';
import { combineReducers } from 'redux';
import { LiveWorkout, RelationShip } from '../../parse';

export const REQUEST_LIVE_WORKOUT_SCHEDULE =
  'live/REQUEST_LIVE_WORKOUT_SCHEDULE';
export const RECEIVE_LIVE_WORKOUT_SCHEDULE =
  'live/RECEIVE_LIVE_WORKOUT_SCHEDULE';

export const REQUEST_LIVE_WORKOUT = 'live/REQUEST_LIVE_WORKOUT';
export const REQUEST_LIVE_WORKOUT_ERROR = 'live/REQUEST_LIVE_WORKOUT_ERROR';
export const RECEIVE_LIVE_WORKOUT = 'live/RECEIVE_LIVE_WORKOUT';

export const SET_ORGANIZER = 'live/SET_ORGANIZER';
export const ORGANIZER_ANYONE = 'anyone';
export const ORGANIZER_FRIENDS = 'friends';
export const ORGANIZER_ME = 'me';

export const SET_SORT_ORDER = 'live/SET_SORT_ORDER';
export const SORT_ORDER_POPULAR = 'popular';
export const SORT_ORDER_UPCOMING = 'upcoming';
export const SORT_ORDER_RECENT = 'recent';

// #region Filter
export function setOrganizer(organizer) {
  return {
    type: SET_ORGANIZER,
    organizer,
  };
}

const organizer = (state = ORGANIZER_ANYONE, action) => {
  switch (action.type) {
    case SET_ORGANIZER:
      return action.organizer;
    default:
      return state;
  }
};

export function setSortOrder(sortOrder) {
  return {
    type: SET_SORT_ORDER,
    sortOrder,
  };
}

const sortOrder = (state = SORT_ORDER_UPCOMING, action) => {
  switch (action.type) {
    case SET_SORT_ORDER:
      return action.sortOrder;
    default:
      return state;
  }
};
// #endregion

// #region Workouts
export const fetchLiveWorkoutsScheduleIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchLiveWorkoutsSchedule(getState())) {
      return dispatch(fetchLiveWorkoutsSchedule(payload));
    }
  };
};

function requestLiveWorkoutsSchedule() {
  return {
    type: REQUEST_LIVE_WORKOUT_SCHEDULE,
  };
}

function receiveLiveWorkoutsSchedule(items) {
  return {
    type: RECEIVE_LIVE_WORKOUT_SCHEDULE,
    receivedAt: Date.now(),
    items,
  };
}

function fetchLiveWorkoutsSchedule(payload) {
  return async (dispatch) => {
    dispatch(requestLiveWorkoutsSchedule());

    const now = new Date();
    const publicLiveWorkouts = new Parse.Query(LiveWorkout);
    publicLiveWorkouts.equalTo('isPublic', true);
    publicLiveWorkouts.greaterThan('scheduledStartTime', now);

    const joinedLiveWorkouts = new Parse.Query(LiveWorkout);
    joinedLiveWorkouts.greaterThan('scheduledStartTime', now);
    joinedLiveWorkouts.containedIn('interested', [
      User.createWithoutData(User.current().id),
    ]);

    const friendsLiveWorkouts = new Parse.Query(LiveWorkout);
    friendsLiveWorkouts.greaterThan('scheduledStartTime', now);
    friendsLiveWorkouts.equalTo('isFriend', true);
    friendsLiveWorkouts.matchesKeyInQuery(
      'createdBy',
      'user2.objectId',
      RelationShip.friendsQuery()
    );

    const myCreatedLiveWorkouts = new Parse.Query(LiveWorkout);
    myCreatedLiveWorkouts.greaterThan('scheduledStartTime', now);
    myCreatedLiveWorkouts.equalTo(
      'createdBy',
      User.createWithoutData(User.current().id)
    );

    let query;
    const { organizer, sortOrder } = payload;

    switch (organizer) {
      case ORGANIZER_ANYONE:
        query = Parse.Query.or(
          publicLiveWorkouts,
          joinedLiveWorkouts,
          friendsLiveWorkouts,
          myCreatedLiveWorkouts
        );
        break;
      case ORGANIZER_FRIENDS:
        query = friendsLiveWorkouts;
        break;
      case ORGANIZER_ME:
        query = myCreatedLiveWorkouts;
        break;
      default:
        query = LiveWorkout.query();
    }

    switch (sortOrder) {
      case SORT_ORDER_POPULAR:
        query.descending('interestedCount');
        break;
      case SORT_ORDER_UPCOMING:
        query.ascending('scheduledStartTime');
        break;
      case SORT_ORDER_RECENT:
        query.descending('createdAt');
        break;

      default:
    }

    query.include('workoutType.segments');
    query.include('workoutType.createdBy');
    query.include('interested');
    query.include('createdBy');

    const items = await query.find();
    return dispatch(receiveLiveWorkoutsSchedule(items));
  };
}

function shouldFetchLiveWorkoutsSchedule(state) {
  const workouts = state.live.workouts;
  if (workouts.length === 0) {
    return true;
  } else if (workouts.isFetching) {
    return false;
  } else {
    return true;
  }
}

const workouts = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_LIVE_WORKOUT_SCHEDULE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_LIVE_WORKOUT_SCHEDULE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

// #region Workout
export const fetchLiveWorkoutIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchLiveWorkout(getState(), payload.id)) {
      return dispatch(fetchLiveWorkout(payload));
    }
  };
};

function requestLiveWorkout(id) {
  return {
    type: REQUEST_LIVE_WORKOUT,
    id,
  };
}

function requestLiveWorkoutError(id, code, error) {
  return {
    type: REQUEST_LIVE_WORKOUT_ERROR,
    id,
    code,
    error,
  };
}

function receiveLiveWorkout(id, item) {
  return {
    type: RECEIVE_LIVE_WORKOUT,
    receivedAt: Date.now(),
    id,
    item,
  };
}

function fetchLiveWorkout(payload) {
  return async (dispatch) => {
    dispatch(requestLiveWorkout(payload.id));
    try {
      const query = LiveWorkout.query();
      query.equalTo('createdBy', User.createWithoutData(payload.userId));
      const item = await query.get(payload.id);
      return dispatch(receiveLiveWorkout(payload.id, item));
    } catch (e) {
      return dispatch(requestLiveWorkoutError(payload.id, e.code, e.message));
    }
  };
}

function shouldFetchLiveWorkout(state, id) {
  const workout = state.live.byId[id];
  if (!workout) {
    return true;
  } else if (workout.isFetching) {
    return false;
  } else {
    return workout.didInvalidate;
  }
}

const workout = (
  state = {
    isFetching: false,
    didInvalidate: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_LIVE_WORKOUT:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_LIVE_WORKOUT:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: action.item,
        lastUpdated: action.receivedAt,
      });
    case REQUEST_LIVE_WORKOUT_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        didInvalidate: true,
        code: action.code,
        error: action.error,
      });
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_LIVE_WORKOUT:
    case REQUEST_LIVE_WORKOUT_ERROR:
    case RECEIVE_LIVE_WORKOUT:
      return Object.assign({}, state, {
        [action.id]: workout(state[action.id], action),
      });
    default:
      return state;
  }
};
// #endregion

export const reducer = combineReducers({
  workouts,
  organizer,
  sortOrder,
  byId,
});

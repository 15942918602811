import {
  Backdrop,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import qs from "query-string";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import KrewIcon from "../../../components/KrewIcon/KrewIcon";
import { User } from "../../../parse";
import "../../../parse-config";
import { setUser } from "../../../redux/ducks/global";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paper: {
    padding: theme.spacing(3),
    maxWidth: 800,
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  logo: {
    color: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const values = qs.parse(location.search);
  const next = values.next || "/dashboard";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await User.logIn(username, password);
      await user.fetchWithInclude("userStats");
      enqueueSnackbar(
        `Welcome ${user.get("username")}, you're now logged in!`,
        { variant: "success" }
      );
      dispatch(setUser(user));
      history.push(next);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      {isLoading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <KrewIcon className={classes.logo} />
      <Paper className={classes.paper}>
        <Typography variant="h4">Sign in</Typography>
        <form onSubmit={handleLogin} noValidate>
          <TextField
            className={classes.formControl}
            variant="outlined"
            label="Username or Email"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              type: "text",
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleRoundedIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className={classes.formControl}
            variant="outlined"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              type: "password",
              startAdornment: (
                <InputAdornment position="start">
                  <LockRoundedIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            variant="contained"
            className={classes.formControl}
            color="primary"
          >
            Log in
          </Button>
        </form>

        {/*
        <Divider className={classes.divider} />

        <Link component={RouterLink} to='/auth/signup'>
          Don't have an account?
          </Link>*/}
      </Paper>
    </div>
  );
};

export default Login;

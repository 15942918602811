import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DistanceIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg height='24.0' width='24.0' xmlns='http://www.w3.org/2000/svg'>
        <path d='M21,6L3,6c-1.1,0 -2,0.9 -2,2v8c0,1.1 0.9,2 2,2h18c1.1,0 2,-0.9 2,-2L23,8c0,-1.1 -0.9,-2 -2,-2zM21,16L3,16L3,8h2v4h2L7,8h2v4h2L11,8h2v4h2L15,8h2v4h2L19,8h2v8z' />
      </svg>
    </SvgIcon>
  );
};

export default DistanceIcon;

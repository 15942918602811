import { Grid, TextField } from '@material-ui/core';
import React from 'react';

const DurationPicker = ({ duration = 0, onDurationChange }) => {
  console.log('** DURATION UPDATED: ', duration);
  const sec_num = parseInt(duration, 10);
  const hours = Math.floor(sec_num / 3600) || 0;
  const minutes = Math.floor(sec_num / 60) % 60 || 0;
  const seconds = sec_num % 60 || 0;

  const updateDuration = (hours, minutes, seconds) => {
    onDurationChange && onDurationChange(hours * 3600 + minutes * 60 + seconds);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            label='Hours'
            type='number'
            margin='normal'
            value={hours}
            onChange={(e) =>
              updateDuration(parseInt(e.target.value), minutes, seconds)
            }
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label='Minutes'
            type='number'
            margin='normal'
            value={minutes}
            onChange={(e) =>
              updateDuration(hours, parseInt(e.target.value), seconds)
            }
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label='Seconds'
            type='number'
            margin='normal'
            value={seconds}
            onChange={(e) =>
              updateDuration(hours, minutes, parseInt(e.target.value))
            }
            variant='outlined'
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DurationPicker;

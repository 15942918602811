import { Workout, User, WorkoutType } from '../../parse';
import { combineReducers } from 'redux';

export const REQUEST_RECENT_ACTIVITY = 'dashboard/REQUEST_RECENT_ACTIVITY';
export const RECEIVE_RECENT_ACTIVITY = 'dashboard/RECEIVE_RECENT_ACTIVITY';

export const REQUEST_FEATURED_WORKOUTS = 'dashboard/REQUEST_FEATURED_WORKOUTS';
export const RECEIVE_FEATURED_WORKOUTS = 'dashboard/RECEIVE_FEATURED_WORKOUTS';

// #region Recent Activity
export const fetchRecentActivityIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchRecentActivity(getState())) {
      return dispatch(fetchRecentActivity(payload));
    }
  };
};

function requestRecentActivity() {
  return {
    type: REQUEST_RECENT_ACTIVITY,
  };
}

function receiveRecentActivity(items) {
  return {
    type: RECEIVE_RECENT_ACTIVITY,
    receivedAt: Date.now(),
    items,
  };
}

function fetchRecentActivity(payload) {
  return async (dispatch) => {
    dispatch(requestRecentActivity(payload));
    const query = Workout.query();
    query.equalTo('createdBy', User.createWithoutData(payload.user.id));
    query.limit(5);
    query.descending('finishTime');
    const items = await query.find();
    return dispatch(receiveRecentActivity(items));
  };
}

function shouldFetchRecentActivity(state) {
  const recentActivity = state.dashboard.recentActivity;
  if (recentActivity.items.length === 0) {
    return true;
  } else if (recentActivity.isFetching) {
    return false;
  } else {
    return recentActivity.didInvalidate;
  }
}

const recentActivity = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_RECENT_ACTIVITY:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_RECENT_ACTIVITY:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

// #region Featured Workouts
export const fetchFeaturedWorkoutsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchFeaturedWorkouts(getState())) {
      return dispatch(fetchFeaturedWorkouts());
    }
  };
};

function requestFeaturedWorkouts() {
  return {
    type: REQUEST_FEATURED_WORKOUTS,
  };
}

function receiveFeaturedWorkouts(items) {
  return {
    type: RECEIVE_FEATURED_WORKOUTS,
    receivedAt: Date.now(),
    items,
  };
}

function fetchFeaturedWorkouts(payload) {
  return async (dispatch) => {
    dispatch(requestFeaturedWorkouts(payload));
    const query = WorkoutType.featuredWorkoutsQuery();
    const result = await query.find();
    const sortedResult = result.sort(
      (a, b) =>
        a.get('createdBy').get('rotationRank') -
          b.get('createdBy').get('rotationRank') ||
        a.createdAt.getTime() - b.createdAt.getTime()
    );
    const items = [
      ...new Map(
        sortedResult.map((item) => [item.get('createdBy').id, item])
      ).values(),
    ];

    return dispatch(receiveFeaturedWorkouts(items));
  };
}

function shouldFetchFeaturedWorkouts(state) {
  const workouts = state.dashboard.featuredWorkouts;
  if (workouts.items.length === 0) {
    return true;
  } else if (workouts.isFetching) {
    return false;
  } else {
    return workouts.didInvalidate;
  }
}

const featuredWorkouts = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_FEATURED_WORKOUTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_FEATURED_WORKOUTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

export const reducer = combineReducers({
  recentActivity,
  featuredWorkouts,
});

import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Root } from "@mui-treasury/layout";
import React from "react";
import theme from "../theme";
import bug from "./undraw_bug_fixing_oc7a.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Root>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <Card style={{ maxWidth: 600, margin: "40px auto" }}>
              <CardHeader title="Something went wrong." />
              <CardMedia image={bug} style={{ height: 220 }} />
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Please report this error to support@krewfit.net
                </Typography>

                <code style={{ whiteSpace: "pre-wrap" }}>
                  {window.location.href}
                  <br />
                  {this.state.error && this.state.error.toString()}
                </code>
              </CardContent>
            </Card>
          </ThemeProvider>
        </Root>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Segment } from '../../../parse';
import WorkoutType from '../../../parse/WorkoutType';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSplitLength,
  setValue,
  setSplitIntervals,
} from '../../../redux/ducks/workoutbuilder';

const WorkDistance = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.workoutbuilder);

  const [workError, setWorkError] = useState(null);
  const [splitLengthError, setSplitLengthError] = useState(null);
  const [splitLengthLocal, setSplitLengthLocal] = useState(state.splitLength);

  const updateSplits = () => {
    const { value, splitLength, splitIntervals } = state;
    const splitNum = Math.ceil(value / splitLength);
    if (value < 100 || value > 50000 || splitLength === 0) {
      return;
    } else if (splitNum > 30) {
      return;
    }

    const currentSplitNum = splitIntervals.length;
    let newSplitIntervals = [...splitIntervals];

    for (let i = 0; i < currentSplitNum; i++) {
      newSplitIntervals[i].value =
        (i + 1) * splitLength > value ? value % splitLength : splitLength;
    }

    if (currentSplitNum < splitNum) {
      for (let i = currentSplitNum; i < splitNum; i++) {
        const splitWork =
          (i + 1) * splitLength > value ? value % splitLength : splitLength;
        newSplitIntervals.push({
          valueType: Segment.VALUE_TYPE_DISTANCE,
          value: splitWork,
          restValue: null,
          restType: null,
          restDescription: null,
          targets: {
            rate: {
              value: 0,
              type: -1,
              delta: 0,
            },
            pace: {
              value: 0,
              type: -1,
              delta: 0,
              base: null,
            },
            hr: {
              type: -1,
              value: [0, 100],
            },
          },
        });
      }
    } else if (currentSplitNum > splitNum) {
      newSplitIntervals = newSplitIntervals.splice(0, splitNum);
    }

    dispatch(setSplitIntervals(newSplitIntervals));
  };

  useEffect(() => {
    const value = state.value;
    if (!value || value < 100 || value > 50000) {
      setWorkError('Distance must be between 100m and 50,000m.');
    } else {
      setWorkError(null);
    }
  }, [state.value]);

  useEffect(() => {
    setSplitLengthLocal(state.splitLength);
  }, [state.splitLength]);

  const updateSplitLength = (value) => {
    validateSplitLength(
      value,
      WorkoutType.getCalculatedSplitLength(
        WorkoutType.VALUE_TYPE_DISTANCE,
        value
      )
    );
  };

  const validateSplitLength = (maxSplitLength, splitLength) => {
    const value = state.value;
    const minSplitLength = Math.max(100, Math.ceil(maxSplitLength / 30));

    setSplitLengthLocal(splitLength);
    if (maxSplitLength < 100 || value > 50000) return;

    if (
      !splitLength ||
      splitLength < minSplitLength ||
      splitLength > maxSplitLength
    ) {
      setSplitLengthError(
        `Split distance must be between ${minSplitLength}m and ${maxSplitLength}m.`
      );
    } else {
      setSplitLengthError(null);
      dispatch(setSplitLength(splitLength));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateSplits();
    }, 200);
    return () => clearTimeout(timer);
  }, [state.splitLength]); // eslint-disable-line

  return (
    <Card>
      <CardHeader title='Work Distance' />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <NumberFormat
              customInput={TextField}
              label='Distance'
              variant='outlined'
              margin='normal'
              thousandSeparator={true}
              decimalScale={0}
              fullWidth
              size='medium'
              value={state.value}
              error={workError != null}
              helperText={workError}
              onValueChange={(values) => {
                dispatch(
                  setValue(WorkoutType.VALUE_TYPE_DISTANCE, values.floatValue)
                );
                updateSplitLength(values.floatValue);
              }}
            />
          </Grid>
          <Grid xs={6} item>
            <NumberFormat
              customInput={TextField}
              label='Split distance'
              variant='outlined'
              margin='normal'
              thousandSeparator={true}
              decimalScale={0}
              fullWidth
              size='medium'
              value={splitLengthLocal}
              error={splitLengthError != null}
              helperText={splitLengthError}
              onValueChange={(values) =>
                validateSplitLength(state.value, values.floatValue)
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WorkDistance;

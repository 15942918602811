import {
  Grid,
  Card,
  makeStyles,
  CardContent,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { getContent } from "@mui-treasury/layout";
import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import data from "./data.json";

const chartTypes = [
  {
    key: "line",
    label: "Line Chart",
  },
  {
    key: "scatter",
    label: "Scatter Chart",
  },
];

const availableItems = {
  heartRate: "Heart Rate",
  meters: "Distance",
  time: "Time",
  splitTime: "Pace",
  strokeLength: "Stroke Length",
  strokesPerMinute: "Rate",
  watts: "Power",
};

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
}));

const CustomGraphs = ({ user }) => {
  const classes = useStyles();
  const [chartType, setChartType] = useState("scatter");
  const [x, setX] = useState("strokesPerMinute");
  const [y, setY] = useState("splitTime");
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: chartType,
      zoomType: "xy",
    },
    title: {
      floating: true,
      text: "",
    },
    xAxis: {
      title: {
        enabled: true,
        text: availableItems[x],
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      title: {
        text: availableItems[y],
      },
    },
    /*legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "top",
      x: 100,
      y: 70,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
      borderWidth: 1,
    },*/
    plotOptions: {
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.x} cm, {point.y} kg",
        },
      },
    },
    series: [
      {
        name: "Female",
        color: "rgba(223, 83, 83, .5)",
        data: data.objects.map(
          (item) => item.interval == 0 && [item[x], item[y]]
        ),
      },
    ],
  });

  /*
  const result = useSelector(
    (state) => state.collections.byBaseId[params.id]
  ) || {
    isFetching: true,
  };

  useEffect(() => {
    dispatch(fetchCollectionBaseIfNeeded({ id: params.id }));
  }, [dispatch, params]);

  if (result.isFetching)
    return (
      <>
        <Header user={user} title="Loading collection details.." subtitle="" />
        <Content
          className={classes.container}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Content>
      </>
    );*/

  return (
    <>
      <Header
        user={user}
        title="Graph Builder"
        subtitle="Build custom graphs"
      />
      <Content className={classes.container}>
        <Grid spacing={2} direction="row-reverse" container>
          <Grid xs={12} lg={4} xl={3} item>
            <Card className={classes.content}>
              <CardContent>
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="type-label">Type of Chart</InputLabel>
                      <Select
                        labelId="type-label"
                        value={chartType}
                        onChange={(e) => setChartType(e.target.value)}
                        label="Type of Chart"
                      >
                        {chartTypes.map((type) => (
                          <MenuItem value={type.key}>{type.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="x-label">X-Axis</InputLabel>
                      <Select
                        labelId="x-label"
                        value={x}
                        onChange={(e) => setX(e.target.value)}
                        label="X-Axis"
                      >
                        {Object.keys(availableItems).map((key) => (
                          <MenuItem value={key}>{availableItems[key]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={6} item>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="y-label">Y-Axis</InputLabel>
                      <Select
                        labelId="y-label"
                        value={y}
                        onChange={(e) => setY(e.target.value)}
                        label="Y-Axis"
                      >
                        {Object.keys(availableItems).map((key) => (
                          <MenuItem value={key}>{availableItems[key]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} lg={8} xl={9} item>
            <Card className={classes.content}>
              <CardContent>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default CustomGraphs;

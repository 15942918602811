import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import ReactTimeago from "react-timeago";
import Header from "../../components/Header";
import WorkoutTypeValueText from "../../components/WorkoutTypeValueText";
import ListItemLink from "../../components/ListItemLink";
import {
  fetchLiveWorkoutsScheduleIfNeeded,
  ORGANIZER_ANYONE,
  ORGANIZER_FRIENDS,
  ORGANIZER_ME,
  setOrganizer,
  setSortOrder,
  SORT_ORDER_POPULAR,
  SORT_ORDER_RECENT,
  SORT_ORDER_UPCOMING,
} from "../../redux/ducks/live";
import { getContent } from "@mui-treasury/layout";
import styled from "styled-components";
import LiveWorkoutDetails from "./LiveWorkoutDetails";

const Content = getContent(styled);

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  tableRow: {
    cursor: "pointer",
  },
  inline: {
    display: "inline",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
}));

const LiveWorkouts = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const organizer = useSelector((state) => state.live.organizer);
  const sortOrder = useSelector((state) => state.live.sortOrder);
  const result = useSelector((state) => state.live.workouts) || {
    isFetching: true,
    items: [],
  };
  useEffect(() => {
    dispatch(fetchLiveWorkoutsScheduleIfNeeded({ organizer, sortOrder }));
  }, [dispatch, organizer, sortOrder]);
  const workouts = result.items;

  return (
    <>
      <Switch>
        <Route path="/liveworkouts/:id" exact>
          <LiveWorkoutDetails />
        </Route>
      </Switch>
      <Header
        user={user}
        title="Live workouts"
        subtitle="Upcoming live workouts for you to participate in!"
      />
      <Content className={classes.container}>
        <Grid spacing={2} direction="row-reverse" container>
          <Grid xs={12} lg={4} xl={3} item>
            <Card className={classes.content}>
              <CardHeader
                title="Filter"
                subheader="Only show workouts matching the below criteria"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Organized by
                </Typography>
                <Box marginBottom={3}>
                  <Chip
                    label="Anyone"
                    color={organizer === ORGANIZER_ANYONE ? "primary" : ""}
                    onClick={() => dispatch(setOrganizer(ORGANIZER_ANYONE))}
                  />{" "}
                  <Chip
                    label="Friends"
                    color={organizer === ORGANIZER_FRIENDS ? "primary" : ""}
                    onClick={() => dispatch(setOrganizer(ORGANIZER_FRIENDS))}
                  />{" "}
                  <Chip
                    label="Me"
                    color={organizer === ORGANIZER_ME ? "primary" : ""}
                    onClick={() => dispatch(setOrganizer(ORGANIZER_ME))}
                  />
                </Box>

                <FormControl fullWidth variant="outlined">
                  <InputLabel id="sorting">Sorted by</InputLabel>
                  <Select
                    labelId="sorting"
                    label="Sorted by"
                    value={sortOrder}
                    onChange={(e) => dispatch(setSortOrder(e.target.value))}
                    displayEmpty
                  >
                    <MenuItem value={SORT_ORDER_POPULAR}>Popular</MenuItem>
                    <MenuItem value={SORT_ORDER_UPCOMING}>Upcoming</MenuItem>
                    <MenuItem value={SORT_ORDER_RECENT}>
                      Recently added
                    </MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} lg={8} xl={9} item>
            <Card>
              {result.isFetching && (
                <CardContent>
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                </CardContent>
              )}
              {!result.isFetching && workouts.length === 0 && (
                <CardContent>
                  <div className={classes.loading}>
                    <Typography variant="h4">
                      No live workouts matching current filters..
                    </Typography>
                  </div>
                </CardContent>
              )}
              {!result.isFetching && workouts.length > 0 && (
                <List>
                  {workouts &&
                    workouts.map((workout, index) => {
                      const createdBy = workout.get("createdBy");
                      const workoutType = workout.get("workoutType");
                      const image = workoutType.has("image")
                        ? workoutType.get("image").url()
                        : createdBy.has("image")
                        ? createdBy.get("image").url()
                        : null;
                      return (
                        <ListItem
                          component={ListItemLink}
                          to={`/liveworkouts/${workout.id}`}
                          key={index}
                          button
                          alignItems="flex-start"
                        >
                          <ListItemAvatar>
                            <Badge
                              badgeContent={
                                <CheckCircleRounded
                                  fontSize="small"
                                  htmlColor="#81C784"
                                />
                              }
                              invisible={
                                workout
                                  .get("interested")
                                  .map((i) => i.id)
                                  .indexOf(user.id) === -1
                              }
                            >
                              <Avatar src={image} />
                            </Badge>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <>
                                {workout.get("title")} by{" "}
                                {createdBy.get("username")}
                              </>
                            }
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Starts{" "}
                                  <ReactTimeago
                                    date={workout.get("scheduledStartTime")}
                                  />
                                </Typography>
                                {` – `}
                                <WorkoutTypeValueText
                                  workoutType={workoutType}
                                />
                              </>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                history.push(
                                  "/live-workout/" +
                                    createdBy.id +
                                    "/" +
                                    workout.id
                                )
                              }
                            >
                              &lt;&lt; WATCH LIVE &gt;&gt;
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Chip
                              color="primary"
                              label={workout.get("interested").length}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              )}
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default LiveWorkouts;

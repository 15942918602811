import React from "react";
import { Redirect } from "react-router-dom";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";
import { User } from "./parse";
import {
  Activity,
  CollectionBaseDetail,
  CollectionDetail,
  Collections,
  Dashboard,
  Explorer,
  LiveWorkouts,
  LiveWorkoutBigScreen,
  Login,
  Logout,
  MyAccount,
  MyCustom,
  NotFound,
  Signup,
  Subscription,
  WorkoutDetail,
  WorkoutTypeDetail,
  CustomGraphs,
} from "./views";
import WorkoutBuilder from "./views/WorkoutBuilder/WorkoutBuilder";

const requireAuth = (component, props) => {
  const user = User.current();
  if (!user) {
    return <Redirect to={"/auth/login?next=" + props.location.pathname} />;
  }

  return component;
};

/*const requirePremium = (component, props) => {
  const user = User.current();
  if (!user.isPremiumSubscriber()) {
    return (
      <Redirect to={'/subscription/required?next=' + props.location.pathname} />
    );
  }

  return component;
};*/

const routes = [
  {
    path: "/auth",
    component: MinimalLayout,
    routes: [
      {
        path: "/auth/login",
        component: Login,
        exact: true,
      },
      {
        path: "/auth/signup",
        component: Signup,
        exact: true,
      },
      {
        path: "/auth/logout",
        component: Logout,
        exact: true,
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    path: "/live-workout/:userId/:workoutId",
    component: LiveWorkoutBigScreen,
  },
  {
    path: "/subscription",
    component: MinimalLayout,
    routes: [
      {
        path: "/subscription/:userId",
        component: Subscription,
      },
    ],
  },
  {
    component: MainLayout,
    routes: [
      {
        path: "/",
        exact: true,
        render: () => {
          return <Redirect to="/dashboard" />;
        },
      },
      {
        path: "/dashboard",
        exact: true,
        render: (props) => requireAuth(<Dashboard {...props} />, props),
      },
      {
        path: "/collections",
        render: (props) => requireAuth(<Collections {...props} />, props),
        exact: true,
      },
      {
        path: "/collections/shared/:id",
        component: CollectionBaseDetail,
      },
      {
        path: "/collections/:id",
        render: (props) => requireAuth(<CollectionDetail {...props} />, props),
      },
      {
        path: "/explorer",
        render: (props) => requireAuth(<Explorer {...props} />, props),
        //render: (props) => requireAuth(<InProgress {...props} />, props),
        exact: true,
      },
      {
        path: "/explorer/:id",
        component: WorkoutTypeDetail,
      },
      {
        path: "/activities",
        render: (props) => requireAuth(<Activity {...props} />, props),
        //render: (props) => requireAuth(<InProgress {...props} />, props),
        exact: true,
      },
      {
        path: "/activities/shared/:userId/:id",
        render: (props) => requireAuth(<WorkoutDetail {...props} />, props),
      },
      {
        path: "/activities/:id/customgraphs",
        render: (props) => requireAuth(<CustomGraphs {...props} />, props),
      },
      {
        path: "/activities/:id",
        render: (props) => requireAuth(<WorkoutDetail {...props} />, props),
      },
      {
        path: "/liveworkouts",
        render: (props) => requireAuth(<LiveWorkouts {...props} />, props),
      },
      {
        path: "/builder",
        render: (props) => requireAuth(<MyCustom {...props} />, props),
        exact: true,
      },
      {
        path: "/builder/:id?",
        render: (props) => requireAuth(<WorkoutBuilder {...props} />, props),
      },
      {
        path: "/me",
        render: (props) => requireAuth(<MyAccount {...props} />, props),
      },
      {
        component: NotFound,
      },
    ],
  },
];

export default routes;

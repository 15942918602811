import Parse from 'parse';

Parse.User.allowCustomUserClass(true);

class User extends Parse.User {
  constructor() {
    super('_User');
  }

  static heartRateZones = [
    [65, 75],
    [75, 80],
    [80, 85],
    [85, 90],
    [90, 100],
  ];

  static query() {
    const query = new Parse.Query(User);
    query.include('userStats');
    return query;
  }

  static featuredUsersQuery() {
    const query = User.query();
    query.equalTo('isFeatured', true);
    query.ascending('rotationRank');
    return query;
  }

  isPremiumSubscriber = () => {
    if (this.has('paidThru')) {
      return this.get('paidThru') > new Date();
    }
    return false;
  };
}

export default User;

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  CalendarTodayRounded,
  NavigateBeforeRounded as NavigateBeforeIcon,
  NavigateNextRounded as NavigateNextIcon,
  TableChartRounded,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTimeago from "react-timeago";
import Header from "../../components/Header";
import { TimeFormat } from "../../components/TimeFormat";
import { WorkoutType } from "../../parse";
import {
  fetchWorkoutsIfNeeded,
  selectMonth,
  setFilter,
  setViewType,
} from "../../redux/ducks/activity";
import { getContent } from "@mui-treasury/layout";
import styled from "styled-components";
import DistanceGraph from "./components/DistanceGraph";

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  tableRow: {
    cursor: "pointer",
  },
}));

const filterActivity = (activities, filter) => {
  return activities.filter((activity) => {
    const workoutType = activity.get("workoutType");
    if (filter.valueType) {
      return workoutType.get("valueType") === filter.valueType;
    }

    return true;
  });
};

const Activity = ({ user }) => {
  const calendarRef = React.createRef();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const viewType = useSelector((state) => state.activity.viewType);
  const { year, month } = useSelector((state) => state.activity.selectedMonth);
  const filter = useSelector((state) => state.activity.filter);

  const result = useSelector(
    (state) => state.activity.workoutsByMonth[`${year}_${month}`]
  ) || {
    isFetching: true,
    items: [],
  };
  useEffect(() => {
    if (year && month) {
      calendarRef.current.getApi().gotoDate(new Date(`${year}-${month}-01`));
      dispatch(fetchWorkoutsIfNeeded({ user, year, month }));
    }
  }, [calendarRef, dispatch, user, year, month]);

  const previousMonth = () => {
    const date = moment(new Date(`${year}-${month}-01`))
      .subtract(1, "month")
      .toDate();
    dispatch(selectMonth(date.getFullYear(), date.getMonth() + 1));
  };
  const nextMonth = () => {
    const date = moment(new Date(`${year}-${month}-01`))
      .add(1, "month")
      .toDate();
    dispatch(selectMonth(date.getFullYear(), date.getMonth() + 1));
  };
  const thisMonth = () => {
    const date = new Date();
    dispatch(selectMonth(date.getFullYear(), date.getMonth() + 1));
  };

  const workouts = filterActivity(result.items, filter);
  const events = workouts.map((workout) => ({
    id: workout.id,
    start: moment(workout.get("finishTime"))
      .subtract(workout.get("totalTime"), "s")
      .toDate(),
    end: workout.get("finishTime"),
    title: workout.get("workoutType").get("name"),
    backgroundColor:
      theme.palette.valueType[workout.get("workoutType").get("valueType")]
        .background,
    borderColor:
      theme.palette.valueType[workout.get("workoutType").get("valueType")]
        .border,
  }));
  return (
    <>
      <Header
        user={user}
        title="Activity"
        subtitle={
          "An overview of your activity per month. Tap any activity to view in more detail."
        }
      />
      <Content className={classes.container}>
        <Grid spacing={2} direction="row-reverse" container>
          <Grid xs={12} lg={4} xl={3} item>
            <Card className={classes.content}>
              <CardHeader
                title="Filter"
                subheader="Only show workouts matching the below criteria"
              />
              <CardContent>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="valueType">Type of Workout</InputLabel>
                  <Select
                    labelId="valueType"
                    label="Type of Workout"
                    value={filter.valueType}
                    onChange={(e) =>
                      dispatch(setFilter("valueType", e.target.value))
                    }
                    displayEmpty
                  >
                    <MenuItem value={0}>Any</MenuItem>
                    <MenuItem value={WorkoutType.VALUE_TYPE_DISTANCE}>
                      Single distance
                    </MenuItem>
                    <MenuItem value={WorkoutType.VALUE_TYPE_TIMED}>
                      Single time
                    </MenuItem>
                    <MenuItem value={WorkoutType.VALUE_TYPE_CUSTOM}>
                      Custom intervals
                    </MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} lg={8} xl={9} item>
            <Card className={classes.content}>
              <CardContent>
                <Grid
                  justify="center"
                  alignItems="center"
                  spacing={2}
                  className={classes.monthGroup}
                  container
                >
                  <Grid
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    item
                  >
                    <ButtonGroup
                      variant="contained"
                      color="primary"
                      disabled={result.isFetching}
                    >
                      <Button onClick={previousMonth}>
                        <NavigateBeforeIcon />
                      </Button>
                      <Button onClick={thisMonth}>Today</Button>
                      <Button onClick={nextMonth}>
                        <NavigateNextIcon />
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    item
                  >
                    <Typography variant="h4">
                      {moment(`${year}-${month}-1`).format("MMMM, YYYY")}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    item
                  >
                    <ToggleButtonGroup
                      size="small"
                      exclusive
                      onChange={(e, type) => {
                        if (type) dispatch(setViewType(type));
                      }}
                      value={viewType}
                    >
                      <ToggleButton value="calendar">
                        <CalendarTodayRounded />
                      </ToggleButton>
                      <ToggleButton value="list">
                        <TableChartRounded />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {result.isFetching && (
                    <Grid xs={12} item>
                      <LinearProgress />
                    </Grid>
                  )}

                  <DistanceGraph
                    year={year}
                    month={month}
                    workouts={workouts}
                  />

                  <Grid item xs={12} hidden={viewType !== "calendar"}>
                    <FullCalendar
                      ref={calendarRef}
                      defaultView="dayGridMonth"
                      plugins={[dayGridPlugin]}
                      events={events}
                      headerToolbar={false}
                      eventClick={(e) => {
                        history.push("/activities/" + e.event.id);
                      }}
                      editable
                    />
                  </Grid>
                  <Grid item xs={12} hidden={viewType !== "list"}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Workout</TableCell>
                          <TableCell>Time</TableCell>
                          <TableCell>Distance</TableCell>
                          <TableCell>Pace</TableCell>
                          <TableCell>Rate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workouts &&
                          workouts.map((workout, index) => {
                            const workoutType = workout.get("workoutType");
                            return (
                              <TableRow
                                key={index}
                                className={classes.tableRow}
                                onClick={() =>
                                  history.push("/activities/" + workout.id)
                                }
                                hover
                              >
                                <TableCell>
                                  <ReactTimeago
                                    date={workout.get("finishTime")}
                                  />
                                </TableCell>
                                <TableCell>{workoutType.get("name")}</TableCell>
                                <TableCell>
                                  <TimeFormat time={workout.get("duration")} />
                                </TableCell>
                                <TableCell>
                                  <NumberFormat
                                    value={workout.get("meters")}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TimeFormat
                                    time={workout.get("averageSplitTime")}
                                    withMsPrecision
                                  />
                                </TableCell>
                                <TableCell>
                                  <NumberFormat
                                    value={workout.get("averageSPM")}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default Activity;

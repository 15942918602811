import React from 'react';
import { Grid, Typography, Slider, Chip, makeStyles } from '@material-ui/core';
import { Segment } from '../../../parse';
import PaceValue from './PaceValue';
import { setTargetPace } from '../../../redux/ducks/workoutbuilder';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const TargetPace = ({
  index,
  target,
  linkedWorkoutType,
  hasPreviousTargetPace,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant='h5'>TARGET PACE</Typography>
        </Grid>

        <Grid item>
          <PaceValue value={target} previousWorkout={linkedWorkoutType} />
        </Grid>
      </Grid>

      {target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
      target.type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT ||
      target.type === Segment.TARGET_TYPE_PR_DISTANCE ||
      target.type === Segment.TARGET_TYPE_PR_TIME ? (
        <Slider
          value={30 - target.delta}
          step={1}
          valueLabelDisplay='off'
          onChange={(_, value) =>
            dispatch(
              setTargetPace({
                ...target,
                index,
                delta: 30 - value,
              })
            )
          }
          min={0}
          max={60}
        />
      ) : (
        <Slider
          value={target.value}
          step={1}
          valueLabelDisplay='off'
          onChange={(_, value) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_CUSTOM,
                value,
                delta: 0,
                base: null,
              })
            )
          }
          min={0}
          max={91}
        />
      )}

      <div className={classes.chipGroup}>
        <Chip
          label='Fixed'
          color={target.type === Segment.TARGET_TYPE_CUSTOM ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_CUSTOM,
                value: 0,
                delta: 0,
                base: null,
              })
            )
          }
          clickable
        />
        <Chip
          label='500m'
          color={
            target.type === Segment.TARGET_TYPE_PR_DISTANCE &&
            target.base === 500
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_DISTANCE,
                value: 0,
                delta: 0,
                base: 500,
              })
            )
          }
          clickable
        />
        <Chip
          label='1k'
          color={
            target.type === Segment.TARGET_TYPE_PR_DISTANCE &&
            target.base === 1000
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_DISTANCE,
                value: 0,
                delta: 0,
                base: 1000,
              })
            )
          }
          clickable
        />
        <Chip
          label='2k'
          color={
            target.type === Segment.TARGET_TYPE_PR_DISTANCE &&
            target.base === 2000
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_DISTANCE,
                value: 0,
                delta: 0,
                base: 2000,
              })
            )
          }
          clickable
        />
        <Chip
          label='5k'
          color={
            target.type === Segment.TARGET_TYPE_PR_DISTANCE &&
            target.base === 5000
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_DISTANCE,
                value: 0,
                delta: 0,
                base: 5000,
              })
            )
          }
          clickable
        />
        <Chip
          label='6k'
          color={
            target.type === Segment.TARGET_TYPE_PR_DISTANCE &&
            target.base === 6000
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_DISTANCE,
                value: 0,
                delta: 0,
                base: 6000,
              })
            )
          }
          clickable
        />
        <Chip
          label='10k'
          color={
            target.type === Segment.TARGET_TYPE_PR_DISTANCE &&
            target.base === 10000
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_DISTANCE,
                value: 0,
                delta: 0,
                base: 10000,
              })
            )
          }
          clickable
        />
        <Chip
          label='1min'
          color={
            target.type === Segment.TARGET_TYPE_PR_TIME && target.base === 60
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_TIME,
                value: 0,
                delta: 0,
                base: 60,
              })
            )
          }
          clickable
        />
        <Chip
          label='4min'
          color={
            target.type === Segment.TARGET_TYPE_PR_TIME && target.base === 240
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_TIME,
                value: 0,
                delta: 0,
                base: 240,
              })
            )
          }
          clickable
        />
        <Chip
          label='30min'
          color={
            target.type === Segment.TARGET_TYPE_PR_TIME && target.base === 1800
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_TIME,
                value: 0,
                delta: 0,
                base: 1800,
              })
            )
          }
          clickable
        />
        <Chip
          label='60min'
          color={
            target.type === Segment.TARGET_TYPE_PR_TIME && target.base === 3600
              ? 'primary'
              : ''
          }
          onClick={(_) =>
            dispatch(
              setTargetPace({
                index,
                type: Segment.TARGET_TYPE_PR_TIME,
                value: 0,
                delta: 0,
                base: 3600,
              })
            )
          }
          clickable
        />
        {hasPreviousTargetPace && (
          <>
            <Chip
              label='Previous target'
              color={
                target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET
                  ? 'primary'
                  : ''
              }
              onClick={(_) =>
                dispatch(
                  setTargetPace({
                    index,
                    type: Segment.TARGET_TYPE_PREVIOUS_TARGET,
                    value: 0,
                    delta: 0,
                    base: null,
                  })
                )
              }
              clickable
            />
          </>
        )}
        {linkedWorkoutType && (
          <>
            <Chip
              label={`Previous workout (${linkedWorkoutType})`}
              color={
                target.type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT
                  ? 'primary'
                  : ''
              }
              onClick={(_) =>
                dispatch(
                  setTargetPace({
                    index,
                    type: Segment.TARGET_TYPE_PREVIOUS_WORKOUT,
                    value: 0,
                    delta: 0,
                    base: null,
                  })
                )
              }
              clickable
            />
          </>
        )}
      </div>
    </>
  );
};

export default TargetPace;

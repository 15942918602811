import { Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';

const SubscriptionRequired = () => {
  return (
    <Card>
      <CardHeader title='The feature you are trying to use requires a premium subscription' />
      <CardContent>
        Subscribing to KREW is not yet available through the website, please
        open the app to subscribe and then reload this page.
      </CardContent>
    </Card>
  );
};

export default SubscriptionRequired;

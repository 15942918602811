import Parse from 'parse';

class Segment extends Parse.Object {
  static VALUE_TYPE_TIMED = 1;
  static VALUE_TYPE_DISTANCE = 2;

  static REST_TYPE_NORMAL = 0;
  static REST_TYPE_VARIABLE = 1;

  static HRM_TARGET_TYPE_CUSTOM = -1;
  static HRM_TARGET_TYPE_ZONE1 = 0;
  static HRM_TARGET_TYPE_ZONE2 = 1;
  static HRM_TARGET_TYPE_ZONE3 = 2;
  static HRM_TARGET_TYPE_ZONE4 = 3;
  static HRM_TARGET_TYPE_ZONE5 = 4;

  static TARGET_TYPE_NONE = -1;
  static TARGET_TYPE_PR_DISTANCE = 0;
  static TARGET_TYPE_PR_TIME = 1;
  static TARGET_TYPE_PREVIOUS_TARGET = 2;
  static TARGET_TYPE_CUSTOM = 3;
  static TARGET_TYPE_PREVIOUS_WORKOUT = 4;

  constructor(attributes, options) {
    super('Segment', attributes, options);
  }

  static query() {
    return Parse.Query(Segment);
  }
}

export default Segment;

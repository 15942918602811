import React from 'react';

class DistanceFormat extends React.Component {
  static format(meters) {
    if (meters >= 1000) {
      return (parseFloat(meters) / 1000).toFixed(1).replace('.0', '') + 'k';
    } else {
      return meters + 'm';
    }
  }

  render() {
    const { meters } = this.props;
    return DistanceFormat.format(meters);
  }
}

export default DistanceFormat;

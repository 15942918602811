import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { fetchFeaturedCollectionsIfNeeded } from '../../../redux/ducks/collections';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    overflow: 'auto',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  media: {
    height: 240,
  },
}));

const FeaturedCollections = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.collections.featured) || {
    isFetching: true,
    items: [],
  };
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(fetchFeaturedCollectionsIfNeeded());
  }, [dispatch]);

  if (result.isFetching)
    return (
      <Card className={classes.root}>
        <CardContent className={classes.loading}>
          <CircularProgress />
        </CardContent>
      </Card>
    );

  const collections = result.items;
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Card className={classes.root}>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {collections.map((collection, index) => {
          const createdBy = collection.get('createdBy');
          const image = collection.has('image')
            ? collection.get('image').url()
            : createdBy.has('image')
            ? createdBy.get('image').url()
            : null;
          return (
            <>
              <CardActionArea
                onClick={() =>
                  history.push('/collections/shared/' + collection.id)
                }
              >
                <CardMedia
                  className={classes.media}
                  image={image}
                  alt={collection.get('name')}
                />
              </CardActionArea>
              <CardHeader
                avatar={
                  <Avatar
                    src={createdBy.has('image') && createdBy.get('image').url()}
                  />
                }
                title={collection.get('name')}
                subheader={`Created by ${createdBy.get('username')}`}
              />
            </>
          );
        })}
      </AutoPlaySwipeableViews>
    </Card>
  );
};

export default FeaturedCollections;

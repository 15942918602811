import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../../components/Header';
import { fetchCollectionBaseIfNeeded } from '../../redux/ducks/collections';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTabs, StyledTab } from '../../components/StyledTabs';
import { getContent } from '@mui-treasury/layout';
import styled from 'styled-components';
import CollectionInformation from '../CollectionDetail/components/CollectionInformation';
import CollectionComments from '../CollectionDetail/components/CollectionComments';

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(15),
    padding: theme.spacing(3),
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const CollectionBaseDetail = ({ location, match, user }) => {
  const { url, params } = match;
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(
    (state) => state.collections.byBaseId[params.id]
  ) || {
    isFetching: true,
  };

  useEffect(() => {
    dispatch(fetchCollectionBaseIfNeeded({ id: params.id }));
  }, [dispatch, params]);

  if (result.isFetching)
    return (
      <>
        <Header user={user} title='Loading collection details..' subtitle='' />
        <Content
          className={classes.container}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='primary' />
        </Content>
      </>
    );

  const base = result.item;
  const createdBy = base.get('createdBy');
  return (
    <>
      <Header
        user={user}
        title={base.get('name')}
        subtitle={`Collection created by ${createdBy.get('username')}`}
      />
      <Content className={classes.container}>
        <StyledTabs
          className={classes.tabsContainer}
          value={location.pathname}
          variant='standard'
        >
          <StyledTab label='Details' value={url} to={url} />

          <StyledTab
            label='Comments'
            value={url + '/comments'}
            to={url + '/comments'}
          />
        </StyledTabs>
        <Switch>
          <Route path='/collections/shared/:id' exact>
            <CollectionInformation base={base} />
          </Route>
          <Route path='/collections/shared/:id/comments' exact>
            <CollectionComments base={base} />
          </Route>
        </Switch>
      </Content>
    </>
  );
};

export default CollectionBaseDetail;

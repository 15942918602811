import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  makeStyles,
  TextField,
} from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDescription,
  setFileObject,
  setTitle,
} from '../../../redux/ducks/workoutbuilder';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '30%',
  },
}));

const BasicInformation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const basicInformation = useSelector((state) => state.workoutbuilder);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      dispatch(setFileObject(acceptedFiles[0]));
    },
  });
  const { ref, ...rootProps } = getRootProps();

  return (
    <Card>
      <CardHeader title='Information' />
      <RootRef rootRef={ref}>
        <CardMedia
          {...rootProps}
          className={classes.media}
          image={
            basicInformation.image ||
            'https://via.placeholder.com/800x300?text=Drag+and+drop+image+here'
          }
        >
          <input {...getInputProps()} />
        </CardMedia>
      </RootRef>
      <CardContent>
        <TextField
          label='Title'
          variant='outlined'
          margin='normal'
          value={basicInformation.title}
          onChange={(e) => dispatch(setTitle(e.target.value))}
          fullWidth
        />
        <TextField
          label='Description'
          variant='outlined'
          margin='normal'
          rows={5}
          value={basicInformation.description}
          onChange={(e) => dispatch(setDescription(e.target.value))}
          fullWidth
          multiline
        />
      </CardContent>
    </Card>
  );
};

export default BasicInformation;

import { makeStyles, Toolbar, Typography } from "@material-ui/core";
import { getHeader, getSidebarTrigger } from "@mui-treasury/layout";
import React from "react";
import styled from "styled-components";
import ToolbarProfile from "../ToolbarProfile/ToolbarProfile";

const MuiHeader = getHeader(styled);
const SidebarTrigger = getSidebarTrigger(styled);

const useStyles = makeStyles((theme) => ({
  header: {
    height: "auto !important",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
  },
  toolbarContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(15),
    flexGrow: 1,
  },
}));

const Header = ({ user, title, subtitle }) => {
  const classes = useStyles();

  return (
    <MuiHeader color="primary" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <SidebarTrigger sidebarId="sidebar" color="inherit"></SidebarTrigger>
        <div className={classes.toolbarContent}>
          <Typography variant="h3" color="inherit" gutterBottom>
            {title.toUpperCase()}
          </Typography>
          {typeof subtitle === "string"
            ? subtitle.split("\n").map((subtitle, idx) => (
                <Typography key={idx} variant="body1" color="inherit">
                  {subtitle}
                </Typography>
              ))
            : subtitle}
        </div>

        <ToolbarProfile user={user} />
      </Toolbar>
    </MuiHeader>
  );
};

export default Header;

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./parse-config";
import App from "./App";
import history from "./history";
import "./index.css";
import store from "./redux/store";
import "./firebase";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        maxSnack={3}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </SnackbarProvider>
    </Router>
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ChevronRightRounded, TimerRounded } from "@material-ui/icons";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import { getContent } from "@mui-treasury/layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import DistanceIcon from "../../components/DistanceIcon";
import Header from "../../components/Header";
import ListItemLink from "../../components/ListItemLink";
import WorkoutTypeValueText from "../../components/WorkoutTypeValueText";
import { fetchMyCustomWorkoutsIfNeeded } from "../../redux/ducks/mycustom";

const Content = getContent(styled);

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  centerself: {
    alignSelf: "center",
  },
}));

const MyCustom = ({ user }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.mycustom.workouts) || {
    isFetching: true,
    items: [],
  };
  useEffect(() => {
    dispatch(fetchMyCustomWorkoutsIfNeeded());
  }, [dispatch]);
  const workouts = result.items;

  return (
    <>
      <Header
        user={user}
        title="My Custom Workouts"
        subtitle="Create new single distance/time and custom interval workouts easily here and they will be available for you in the KREW app as well."
      />
      <Content className={classes.container}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={4} md="auto" item>
            <Card>
              <CardActionArea
                onClick={(_) => history.push("/builder/distance")}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid className={classes.centerself} item>
                      <DistanceIcon />
                    </Grid>
                    <Grid item sm>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography variant="h4" gutterBottom>
                            Single distance
                          </Typography>
                          <Typography variant="body1">
                            Create a new single distance workout
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} sm={4} md="auto" item>
            <Card>
              <CardActionArea onClick={(_) => history.push("/builder/time")}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid className={classes.centerself} item>
                      <TimerRounded />
                    </Grid>
                    <Grid item sm>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography variant="h4" gutterBottom>
                            Single time
                          </Typography>
                          <Typography variant="body1">
                            Create a new single time workout
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} sm={4} md="auto" item>
            <Card>
              <CardActionArea onClick={(_) => history.push("/builder/custom")}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid className={classes.centerself} item>
                      <ListRoundedIcon />
                    </Grid>
                    <Grid item sm>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography variant="h4" gutterBottom>
                            Custom intervals
                          </Typography>
                          <Typography variant="body1">
                            Create a new complex workout
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              {result.isFetching && (
                <CardContent>
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                </CardContent>
              )}
              {!result.isFetching && workouts.length === 0 && (
                <CardContent>
                  <div className={classes.loading}>
                    <Typography variant="h4">
                      You have not created any custom workouts yet
                    </Typography>
                  </div>
                </CardContent>
              )}
              {!result.isFetching && workouts.length > 0 && (
                <List>
                  {workouts &&
                    workouts.map((workout, index) => {
                      const createdBy = workout.get("createdBy");
                      const image = workout.has("image")
                        ? workout.get("image").url()
                        : createdBy.has("image")
                        ? createdBy.get("image").url()
                        : null;

                      return (
                        <ListItem
                          component={ListItemLink}
                          to={"/builder/" + workout.id}
                          button
                          exact
                        >
                          <ListItemAvatar>
                            <Avatar variant="rounded" src={image} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={workout.get("name")}
                            secondary={
                              <WorkoutTypeValueText
                                workoutType={workout}
                                isCreator={
                                  workout.get("createdBy").id === user.id
                                }
                              />
                            }
                          />
                          <ListItemSecondaryAction>
                            <ChevronRightRounded />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              )}
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default MyCustom;

import Parse from 'parse';

class Collection extends Parse.Object {
  constructor(attributes, options) {
    super('Playlist', attributes, options);
  }

  static query() {
    const query = new Parse.Query(Collection);
    query.include('base.createdBy');
    query.include('base.items.workoutType');
    query.include('workouts');
    return query;
  }
}

export default Collection;

import Parse from 'parse';

class LiveWorkout extends Parse.Object {
  constructor(attributes, options) {
    super('LiveWorkout', attributes, options);
  }

  static query() {
    const query = new Parse.Query(LiveWorkout);
    query.include('createdBy');
    query.include('workoutType.segments');
    return query;
  }
}

export default LiveWorkout;

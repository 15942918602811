import React from 'react';
import { Chip, Slider, Typography, Grid, makeStyles } from '@material-ui/core';
import RateValue from './RateValue';
import { useDispatch } from 'react-redux';
import { setTargetRate } from '../../../redux/ducks/workoutbuilder';
const { Segment } = require('../../../parse');

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const TargetRate = ({
  index,
  target,
  linkedWorkoutType,
  hasPreviousTargetRate,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant='h5'>TARGET RATE</Typography>
        </Grid>

        <Grid item>
          <RateValue value={target} previousWorkout={linkedWorkoutType} />
        </Grid>
      </Grid>

      {target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
      target.type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT ? (
        <Slider
          value={10 + target.delta}
          step={1}
          valueLabelDisplay='off'
          onChange={(_, value) =>
            dispatch(setTargetRate({ ...target, index, delta: value - 10 }))
          }
          min={0}
          max={20}
        />
      ) : (
        <Slider
          value={target.value}
          step={1}
          valueLabelDisplay='off'
          onChange={(_, value) =>
            dispatch(
              setTargetRate({
                index,
                type: Segment.TARGET_TYPE_CUSTOM,
                value,
                delta: 0,
              })
            )
          }
          min={0}
          max={26}
        />
      )}

      <div className={classes.chipGroup}>
        <Chip
          label='Fixed rate'
          color={target.type === Segment.TARGET_TYPE_CUSTOM ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetRate({
                index,
                type: Segment.TARGET_TYPE_CUSTOM,
                value: 0,
                delta: 0,
              })
            )
          }
          clickable
        />

        {hasPreviousTargetRate && (
          <>
            <Chip
              label='Previous target'
              color={
                target.type === Segment.TARGET_TYPE_PREVIOUS_TARGET
                  ? 'primary'
                  : ''
              }
              onClick={(_) =>
                dispatch(
                  setTargetRate({
                    index,
                    type: Segment.TARGET_TYPE_PREVIOUS_TARGET,
                    value: 0,
                    delta: 0,
                  })
                )
              }
              clickable
            />
          </>
        )}

        {linkedWorkoutType && (
          <>
            <Chip
              label={`Previous workout (${linkedWorkoutType})`}
              color={
                target.type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT
                  ? 'primary'
                  : ''
              }
              onClick={(_) =>
                dispatch(
                  setTargetRate({
                    index,
                    type: Segment.TARGET_TYPE_PREVIOUS_WORKOUT,
                    value: 0,
                    delta: 0,
                    base: null,
                  })
                )
              }
              clickable
            />
          </>
        )}
      </div>
    </>
  );
};

export default TargetRate;

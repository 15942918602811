import React from 'react';
import { WorkoutType } from '../parse';
import { TimeFormat } from './TimeFormat';
import { Chip } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const distanceFromValue = (distance, suffix) => {
  if (distance >= 1000) {
    return (
      <NumberFormat
        value={distance / 1000}
        displayType='text'
        suffix={'k' + suffix}
        thousandSeparator={true}
      />
    );
  } else {
    return (
      <NumberFormat
        value={distance}
        displayType='text'
        suffix={'m' + suffix}
        thousandSeparator={true}
      />
    );
  }
};

const timeFromValue = (time, suffix) => {
  if (time <= 59) {
    return `${time}sec${suffix}`;
  } else if (time % 60 === 0) {
    return `${time / 60}min${suffix}`;
  } else {
    return TimeFormat.format(time);
  }
};

const PaceTarget = ({ workoutType, segments, index }) => {
  const pace = WorkoutType.paceTargetForSegment(segments, index);
  let result = '-';
  let paceChip = null;
  let deltaChip = null;

  if (pace) result = <TimeFormat time={pace[0]} />;

  if (segments[index]) {
    const segment = segments[index];
    const targetPaceVariable = segment.get('targetPaceVariable');
    if (targetPaceVariable && targetPaceVariable.type !== undefined) {
      const base = targetPaceVariable.base;
      let delta = 0;

      switch (targetPaceVariable.type) {
        case WorkoutType.TARGET_TYPE_PR_DISTANCE:
          delta = targetPaceVariable.delta || 0;
          paceChip = (
            <Chip
              size='small'
              label={distanceFromValue(base, ' PB')}
              color='primary'
            />
          );
          break;

        case WorkoutType.TARGET_TYPE_PR_TIME:
          delta = targetPaceVariable.delta || 0;
          paceChip = (
            <Chip
              size='small'
              label={timeFromValue(base, ' PB')}
              color='primary'
            />
          );
          break;

        case WorkoutType.TARGET_TYPE_PREVIOUS_TARGET:
          delta = targetPaceVariable.delta || 0;
          paceChip = <Chip size='small' label='Prev' color='primary' />;
          break;

        case WorkoutType.TARGET_TYPE_PREVIOUS_WORKOUT:
          const linkedWorkoutTypes = workoutType.get('linkedWorkoutTypes');
          if (
            linkedWorkoutTypes &&
            Object.keys(linkedWorkoutTypes).length > 0
          ) {
            delta = targetPaceVariable.delta || 0;
            paceChip = (
              <Chip
                size='small'
                label={Object.keys(linkedWorkoutTypes)[0].toUpperCase()}
                color='primary'
              />
            );
          }
          break;

        default:
      }

      if (delta < 0) {
        deltaChip = (
          <Chip
            size='small'
            label={delta}
            style={{ backgroundColor: '#479D2E', color: '#fff' }}
          />
        );
      } else if (delta > 0) {
        deltaChip = (
          <Chip
            size='small'
            label={'+' + delta}
            style={{ backgroundColor: '#C5B331', color: '#fff' }}
          />
        );
      }
    }
  }

  return (
    <>
      {result}
      {paceChip && (
        <div>
          {paceChip} {deltaChip}
        </div>
      )}
    </>
  );
};

export default PaceTarget;

import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Header from '../../components/Header';
import image from './undraw_in_progress_ql66.svg';
import { getContent } from '@mui-treasury/layout';
import styled from 'styled-components';

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: '100vh',
  },

  container: {
    zIndex: 1101,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  image: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
    maxWidth: '80%',
    width: '30vh',
  },
}));

const InProgress = ({ user }) => {
  const classes = useStyles();

  return (
    <>
      <Header
        user={user}
        title="We're working on it!"
        subtitle="Check back later, we're constantly working on adding new functionality to the KREW Web Dashboard"
      />
      <Content className={classes.container}>
        <img alt='Working hard' className={classes.image} src={image} />
      </Content>
    </>
  );
};

export default InProgress;

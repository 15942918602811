import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownRounded as ArrowDropDownIcon } from "@material-ui/icons";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../redux/ducks/global";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    cursor: "pointer",
  },
  username: {
    color: "#fff",
  },
}));

const ToolbarProfile = ({ user }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const popupState = usePopupState({ variant: "popover", popupId: "profile" });

  if (!user) return <div />;

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        variant="text"
        color="default"
        endIcon={<ArrowDropDownIcon htmlColor="#fff" />}
      >
        <Grid
          spacing={2}
          justify="flex-end"
          alignItems="center"
          className={classes.root}
          container
        >
          <Grid item>
            <Avatar src={user.has("image") && user.get("image").url()} />
          </Grid>
          <Grid item>
            <Typography className={classes.username} variant="h5">
              {user.get("username")}
            </Typography>
          </Grid>
        </Grid>
      </Button>

      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        {...bindMenu(popupState)}
      >
        <MenuItem component={Link} to="/me">
          Account
        </MenuItem>
        <MenuItem onClick={(_) => dispatch(logout())}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ToolbarProfile;

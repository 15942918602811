import { ThemeProvider, makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { renderRoutes } from "react-router-config";
import theme from "../../theme";
import {
  Root,
  getContent,
  getFooter,
  getFullscreen,
} from "@mui-treasury/layout";
import styled from "styled-components";
import background from "./background.png";

const Content = getContent(styled);
const Footer = getFooter(styled);
const Fullscreen = getFullscreen(styled);

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

const Minimal = ({ route }) => {
  const classes = useStyles();
  return (
    <Fullscreen className={classes.background}>
      <Root>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Content>{renderRoutes(route.routes)}</Content>
          <Footer
            style={{
              padding: theme.spacing(2),
              textAlign: "center",
            }}
          >
            © KREW. {new Date().getFullYear()}
          </Footer>
        </ThemeProvider>
      </Root>
    </Fullscreen>
  );
};

export default Minimal;

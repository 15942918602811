import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../../components/Header';
import WorkoutInformation from './components/WorkoutInformation';
import WorkoutComments from './components/WorkoutComments';
import { fetchWorkoutTypeIfNeeded } from '../../redux/ducks/workouttype';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTabs, StyledTab } from '../../components/StyledTabs';
import { getContent } from '@mui-treasury/layout';
import styled from 'styled-components';

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(15),
    padding: theme.spacing(3),
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const WorkoutTypeDetail = ({ location, match, user }) => {
  const { url, params } = match;
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.workouttype.byId[params.id]) || {
    isFetching: true,
  };

  useEffect(() => {
    dispatch(fetchWorkoutTypeIfNeeded({ id: params.id }));
  }, [dispatch, params]);

  if (result.isFetching)
    return (
      <>
        <Header user={user} title='Loading workout details..' subtitle='' />
        <Content
          className={classes.container}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='primary' />
        </Content>
      </>
    );

  const workoutType = result.item;
  const createdBy = workoutType.get('createdBy');
  return (
    <>
      <Header
        user={user}
        title={workoutType.get('name')}
        subtitle={`Workout created by ${createdBy.get('username')}`}
      />
      <Content className={classes.container}>
        <StyledTabs
          className={classes.tabsContainer}
          value={location.pathname}
          variant='standard'
        >
          <StyledTab label='Details' value={url} to={url} />

          <StyledTab
            label='Comments'
            value={url + '/comments'}
            to={url + '/comments'}
          />
          <StyledTab
            label='Leaderboard'
            value={url + '/leaderboards'}
            to={url + '/leaderboards'}
            disabled
          />
        </StyledTabs>
        <Switch>
          <Route path='/explorer/:id' exact>
            <WorkoutInformation workoutType={workoutType} />
          </Route>
          <Route path='/explorer/:id/comments' exact>
            <WorkoutComments workoutType={workoutType} />
          </Route>
          <Route path='/explorer/:id/leaderboards' exact>
            leaderboards
          </Route>
        </Switch>
      </Content>
    </>
  );
};

export default WorkoutTypeDetail;

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import Height from "./Height";
import Weight from "./Weight";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  profile: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(1),
  },
}));

const inchesToCm = (input) => {
  return Math.ceil(input * 2.54);
};

const lbsToKg = (input) => {
  return Math.ceil(input / 2.205);
};

const cmToInches = (input) => {
  return Math.floor(input / 2.54);
};

const kgToLbs = (input) => {
  return Math.floor(input * 2.205);
};

const GeneralInfo = ({ user }) => {
  const classes = useStyles();
  const [dateOfBirth, setDateOfBirth] = useState(user.get("dob"));
  const [gender, setGender] = useState(user.get("gender"));
  const [height, setHeight] = useState(user.get("height"));
  const [weight, setWeight] = useState(user.get("weight"));
  const [isMetric, setMetric] = useState(user.get("isMetric"));

  const swapMetricSystem = (isMetric) => {
    setMetric(isMetric);
    if (isMetric) {
      setHeight(inchesToCm(height));
      setWeight(lbsToKg(weight));
    } else {
      setHeight(cmToInches(height));
      setWeight(kgToLbs(weight));
    }
  };

  return (
    <Grid spacing={2} container>
      <Grid xs={12} lg={4} item>
        <Card>
          <CardContent className={classes.profile}>
            <Avatar
              className={classes.avatar}
              src={user.has("image") && user.get("image").url()}
            />
            <Typography variant="h3" gutterBottom>
              {user.get("username")}
            </Typography>
            <Typography variant="body1">{user.get("email")}</Typography>

            {/*<br />
            <br />
            <Button fullWidth>Change profile picture</Button>
            <Button fullWidth>Remove picture</Button>*/}
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} lg={8} item>
        <Card>
          <CardHeader title="Profile" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  disableFuture
                  format="MM/DD/YYYY"
                  value={dateOfBirth}
                  onChange={(date) => setDateOfBirth(date)}
                  inputVariant="outlined"
                  label="Birth date"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    value={gender}
                    label="Gender"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Not specified</em>
                    </MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Chip
                  label="Imperial"
                  clickable
                  color={!isMetric ? "primary" : ""}
                  onClick={(_) => swapMetricSystem(false)}
                />{" "}
                <Chip
                  label="Metric"
                  color={isMetric ? "primary" : ""}
                  clickable
                  onClick={(_) => swapMetricSystem(true)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Height
                  height={height}
                  setHeight={setHeight}
                  isMetric={isMetric}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Weight
                  weight={weight}
                  setWeight={setWeight}
                  isMetric={isMetric}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="body" color="error">
              Saving profile is not yet available on the web, please use the app
              to update.
            </Typography>
            {/*
            <Button
              variant="contained"
              color="primary"
              disabled
              onClick={(_) => {}}
            >
              Save changes
            </Button>
            */}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GeneralInfo;

import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
} from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import Pluralize from "react-pluralize";
import CreatedByCard from "../../../components/CreatedByCard";
import PaceTarget from "../../../components/PaceTarget";
import RateTarget from "../../../components/RateTarget";
import { TimeFormat } from "../../../components/TimeFormat";
import { Segment } from "../../../parse";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  media: {
    height: 240,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

const Work = ({ value, type }) => {
  if (type === Segment.VALUE_TYPE_TIMED) {
    return <TimeFormat time={value} />;
  } else if (type === Segment.VALUE_TYPE_DISTANCE) {
    return (
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator={true}
        suffix="m"
        decimalScale={0}
      />
    );
  }
};

const Rest = ({ value, type }) => {
  if (type === Segment.REST_TYPE_NORMAL) {
    if (value) {
      return (
        <>
          <TimeFormat time={value} />r
        </>
      );
    }
  } else if (type === Segment.REST_TYPE_VARIABLE) {
    return "Undefined rest";
  }

  return "-";
};

const WorkoutInformation = ({ workoutType }) => {
  const user = useSelector((state) => state.global.user);
  const classes = useStyles();
  const createdBy = workoutType.get("createdBy");
  const segments = workoutType.getSegments();
  const description = workoutType.get("descriptionText");
  const image = workoutType.has("image")
    ? workoutType.get("image").url()
    : createdBy.has("image")
    ? createdBy.get("image").url()
    : null;
  return (
    <>
      <Grid spacing={2} container>
        <Grid xs={12} lg={8} item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                {image && <CardMedia className={classes.media} image={image} />}
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Information
                  </Typography>
                  {description.trim() === "" ? (
                    <Typography variant="body1" paragraph>
                      <i>No description given</i>
                    </Typography>
                  ) : (
                    description.split("\n").map((description, idx) => (
                      <Typography key={idx} variant="body1" paragraph>
                        {description}
                      </Typography>
                    ))
                  )}

                  {user && createdBy.id === user.id && (
                    <>
                      <Divider className={classes.divider} />

                      <Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="overline" gutterBottom>
                              Workout finished #
                            </Typography>
                            <Typography variant="h6">
                              {workoutType.get("usageCount")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Pluralize
                      singular={workoutType.getSegmentType()}
                      count={segments.length}
                      showCount={false}
                    />
                  }
                />
                <CardContent className={classes.cardContent}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "40px" }}>#</TableCell>
                        <TableCell>Work</TableCell>
                        <TableCell>Rest</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Pace</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {segments.map((segment, idx) => {
                        return (
                          <TableRow hover>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>
                              <Typography variant="h6">
                                <Work
                                  value={segment.get("value")}
                                  type={segment.get("valueType")}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                <Rest
                                  value={segment.get("restValue")}
                                  type={segment.get("restType")}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                <RateTarget
                                  workoutType={workoutType}
                                  segments={segments}
                                  index={idx}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                <PaceTarget
                                  workoutType={workoutType}
                                  segments={segments}
                                  index={idx}
                                />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} lg={4} item>
          <CreatedByCard createdBy={createdBy} />
        </Grid>
        <Grid xs={12} lg={8} item></Grid>
      </Grid>
    </>
  );
};

export default WorkoutInformation;

import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

const WorkoutSplitIntervalStats = ({ valueType, splitInterval }) => {
  const segmentType = valueType === 4 ? 'Interval' : 'Split';
  const segmentNumber =
    valueType === 4 ? splitInterval.splitNumber : splitInterval.splitNumber + 1;
  return (
    <>
      <Typography variant='h4' gutterBottom>
        {segmentType} #{segmentNumber}
      </Typography>
      <Grid spacing='2' container>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Average watts
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={splitInterval.splitAvgWatts}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Calories
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={splitInterval.splitCals}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Strokes
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={splitInterval.splitStrokeCount}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Drag Factor
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={splitInterval.splitAvgDragFactor}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Heart rate
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={splitInterval.splitHeartRate}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkoutSplitIntervalStats;

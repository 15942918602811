import React from 'react';
import { TimeFormat } from '../../../components/TimeFormat';
import { DistanceFormat } from '../../../components/DistanceFormat';
import { Segment } from '../../../parse';

const PaceValue = ({
  value: { type, value, delta, base },
  previousWorkout,
}) => {
  if (
    type === Segment.TARGET_TYPE_PREVIOUS_TARGET ||
    type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT ||
    type === Segment.TARGET_TYPE_PR_DISTANCE ||
    type === Segment.TARGET_TYPE_PR_TIME
  ) {
    const deltaObject =
      delta < 0 ? (
        <>
          <b>{delta}</b>s
        </>
      ) : delta > 0 ? (
        <>
          <b>+{delta}</b>s
        </>
      ) : null;

    if (type === Segment.TARGET_TYPE_PREVIOUS_TARGET) {
      return <>Previous target {deltaObject}</>;
    } else if (type === Segment.TARGET_TYPE_PREVIOUS_WORKOUT) {
      return (
        <>
          {previousWorkout} {deltaObject}
        </>
      );
    } else if (type === Segment.TARGET_TYPE_PR_DISTANCE) {
      return (
        <>
          <DistanceFormat meters={base} /> PB {deltaObject}
        </>
      );
    } else if (type === Segment.TARGET_TYPE_PR_TIME) {
      return (
        <>
          {base / 60}min PB {deltaObject}
        </>
      );
    }
  } else {
    if (value === 0) {
      return <i>No target</i>;
    } else {
      return (
        <>
          <b>
            <TimeFormat time={89 + value} />
          </b>
          /500m
        </>
      );
    }
  }
};

export default PaceValue;

import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";

const DistanceGraph = ({ year, month, workouts }) => {
  const startDate = new Date(`${year}-${month}-01`);
  const endDate = moment(startDate).endOf("month").set({ hour: 12 }).toDate();
  const chartOptions = {
    chart: {
      type: "column",
      height: 300,
    },
    credits: false,
    title: {
      text: "",
    },
    tooltip: {
      formatter: function () {
        return Highcharts.numberFormat(this.y, 0) + "m";
      },
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      min: Date.parse(startDate),
      max: Date.parse(endDate),
      tickInterval: 24 * 3600 * 1000,
      labels: {
        rotation: -45,
        align: "right",
      },
      dateTimeLabelFormats: {
        day: "%e. %b",
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      allowDecimals: false,
      min: 0,
    },
    series: [
      {
        color: "#5773A0",
        data: [],
      },
    ],
    plotOptions: {
      min: "2021-02-01",
      max: "2021-02-28",
      series: {
        pointRange: 24 * 3600 * 1000, // one day
      },
    },
  };

  const data = {};
  workouts.forEach((workout) => {
    const finishTime = workout.get("finishTime");
    const date = new Date(
      finishTime.getFullYear(),
      finishTime.getMonth(),
      finishTime.getDate()
    );

    if (!data[date]) {
      data[date] = 0;
    }
    data[date] += workout.get("meters");
  });

  const seriesData = [];
  for (const date in data) {
    seriesData.push({
      x: new Date(date),
      y: data[date],
    });
  }

  chartOptions.series[0].data = seriesData;

  if (seriesData.length === 0) return <></>;

  return (
    <Grid xs={12} item>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Grid>
  );
};

export default DistanceGraph;

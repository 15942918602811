import Parse, { User } from 'parse';
import { combineReducers } from 'redux';
import { WorkoutType } from '../../parse';

export const INVALIDATE_MYCUSTOM_WORKOUTS =
  'mycustom/INVALIDATE_MYCUSTOM_WORKOUTS';
export const REQUEST_MYCUSTOM_WORKOUTS = 'mycustom/REQUEST_MYCUSTOM_WORKOUTS';
export const RECEIVE_MYCUSTOM_WORKOUTS = 'mycustom/RECEIVE_MYCUSTOM_WORKOUTS';

// #region Workouts
export const fetchMyCustomWorkoutsIfNeeded = (payload) => {
  return (dispatch, getState) => {
    if (shouldFetchMyCustomWorkouts(getState())) {
      return dispatch(fetchMyCustomWorkouts(payload));
    }
  };
};

export const invalidateCustomWorkouts = () => {
  return {
    type: INVALIDATE_MYCUSTOM_WORKOUTS,
  };
};

function requestMyCustomWorkouts() {
  return {
    type: REQUEST_MYCUSTOM_WORKOUTS,
  };
}

function receiveMyCustomWorkouts(items) {
  return {
    type: RECEIVE_MYCUSTOM_WORKOUTS,
    receivedAt: Date.now(),
    items,
  };
}

function fetchMyCustomWorkouts(payload) {
  return async (dispatch) => {
    dispatch(requestMyCustomWorkouts());
    const user = Parse.User.current();
    const query = WorkoutType.query();
    query.equalTo('createdBy', User.createWithoutData(user.id));
    query.descending('createdAt');
    const items = await query.find();
    return dispatch(receiveMyCustomWorkouts(items));
  };
}

function shouldFetchMyCustomWorkouts(state) {
  const workouts = state.mycustom.workouts;
  if (workouts.items.length === 0) {
    return true;
  } else if (workouts.isFetching) {
    return false;
  } else {
    return workouts.didInvalidate;
  }
}

const workouts = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case INVALIDATE_MYCUSTOM_WORKOUTS:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case REQUEST_MYCUSTOM_WORKOUTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_MYCUSTOM_WORKOUTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

export const reducer = combineReducers({
  workouts,
});

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import NumberFormat from 'react-number-format';
import { TimeFormat } from '../../../../components/TimeFormat';
import WorkoutSplitIntervalStats from './components/WorkoutSplitIntervalStats';
import WorkoutStats from './components/WorkoutStats';
import WorkoutStatsOverview from './components/WorkoutStatsOverview';

(function (H) {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  /**
   * Highlight a point by showing tooltip, setting hover state and draw crosshair
   */
  H.Point.prototype.highlight = function (event) {
    event = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    //this.series.chart.tooltip.refresh(this); // Show the tooltip
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
  };

  H.syncExtremes = function (e) {
    var thisChart = this.chart;

    if (e.trigger !== 'syncExtremes') {
      // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart && chart !== thisChart) {
          if (chart.xAxis[0].setExtremes) {
            // It is null while updating
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
              trigger: 'syncExtremes',
            });
          }
        }
      });
    }
  };
})(Highcharts);

const useStyles = (theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(15),
    padding: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(3),
  },
  cardContent: {
    padding: 0,
    overflow: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHeader: {
    cursor: 'pointer',
    borderTop: '2px solid #aaa',
    borderBottom: '2px solid #aaa',
  },
});

class WorkoutSummary extends React.Component {
  chart = null;
  state = {
    chart: null,
    currentSplitInterval: -1,
    chartOptions: null,
  };

  resetZoom = () => {
    this.setState({ currentSplitInterval: -1 });
    this.chart.zoomOut();
  };

  zoomToSplitInterval = (currentSplitInterval) => {
    this.setState({ currentSplitInterval });
    const WorkoutData = this.props.workout.get('data').WorkoutData;
    let from = 0.0;
    WorkoutData.splits.forEach((split, index) => {
      if (index < currentSplitInterval)
        from += parseFloat(split.splitTime) + parseFloat(split.splitRestTime);
    });
    const to =
      from +
      parseFloat(WorkoutData.splits[currentSplitInterval].splitTime) +
      parseFloat(WorkoutData.splits[currentSplitInterval].splitRestTime);
    this.chart.xAxis[0].setExtremes(from, to);
  };

  componentDidMount() {
    const me = this;
    const { workout } = this.props;
    const { WorkoutData, StrokeData } = workout.get('data');

    let intervalBaseStart = 0.0;
    let currentInterval = 0;
    let previousStroke = { t: 0 };
    let previous = 0.0;
    const options = {
      chart: {
        animation: false,
        height: 200,
        marginLeft: 40,
        marginRight: 0,
        spacingTop: 10,
        spacingBottom: 0,
        resetZoomButton: {
          theme: {
            display: 'none',
          },
        },
      },
      title: {
        text: '-',
        align: 'left',
        style: {
          color: '#fff',
        },
      },
      credits: false,
      xAxis: {
        crosshair: true,
        labels: {
          formatter: (data) => {
            return TimeFormat.format(data.value, false);
          },
        },
        plotBands: WorkoutData.splits.map((split, splitIndex) => {
          const band = {
            splitIndex,
            from: previous,
            to:
              previous +
              parseFloat(split.splitTime) +
              parseFloat(split.splitRestTime),
            color: 'transparent',
            borderColor: '#f1f1f1',
            borderWidth: 1,
            zIndex: 0,
            label: {
              text: '',
              style: {
                color: '#999999',
              },
            },
            events: {
              mouseover: function (e) {
                //this.svgElem.attr('fill', '#f1f1f1');
              },
              mouseout: function (e) {
                //this.svgElem.attr('fill', this.options.color);
              },
              click: function (e) {
                me.zoomToSplitInterval(this.options.splitIndex);
              },
            },
          };
          previous +=
            parseFloat(split.splitTime) + parseFloat(split.splitRestTime);
          return band;
        }),
        events: {
          setExtremes: function (e) {
            var thisChart = this.chart;

            if (e.trigger !== 'syncExtremes') {
              Highcharts.each(Highcharts.charts, function (chart) {
                if (chart && chart !== thisChart) {
                  if (chart.xAxis[0].setExtremes) {
                    chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                      trigger: 'syncExtremes',
                    });
                  }
                }
              });
            }
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          turboThreshold: 0,
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get('rgba'),
              ],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
    };

    const paceChartOptions = {
      ...options,
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null,
        },
        labels: {
          formatter: (data) => {
            return TimeFormat.format(data.value, false);
          },
        },
      },
      tooltip: {
        positioner: function () {
          return {
            x: this.chart.chartWidth - this.label.width,
            y: 15,
          };
        },
        pointFormatter: function (e) {
          return '<b>Pace:</b> ' + TimeFormat.format(this.y, true);
        },
        borderWidth: 0,
        backgroundColor: 'none',
        headerFormat: '',
        shadow: false,
        style: {
          fontSize: '18px',
        },
      },
      series: [
        {
          type: 'area',
          name: 'Pace',
          color: Highcharts.color('#000000').setOpacity(0.6).get('rgba'),
          fillColor: Highcharts.color('#000000').setOpacity(0.2).get('rgba'),
          data: StrokeData.map((stroke) => {
            if (stroke.t < previousStroke.t) {
              intervalBaseStart +=
                parseFloat(WorkoutData.splits[currentInterval].splitTime) +
                parseFloat(WorkoutData.splits[currentInterval].splitRestTime);
              currentInterval++;
            }
            previousStroke = stroke;
            return {
              x: intervalBaseStart + stroke.t / 10,
              y: stroke.p / 10,
            };
          }),
        },
      ],
    };

    intervalBaseStart = 0.0;
    currentInterval = 0;
    previousStroke = { t: 0 };
    const rateChartOptions = {
      ...options,
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null,
        },
        min: 0,
      },
      tooltip: {
        positioner: function () {
          return {
            x: this.chart.chartWidth - this.label.width,
            y: 15,
          };
        },
        pointFormatter: function (e) {
          return '<b>Rate:</b> ' + this.y + ' SPM';
        },
        borderWidth: 0,
        backgroundColor: 'none',
        headerFormat: '',
        shadow: false,
        style: {
          fontSize: '18px',
        },
      },
      series: [
        {
          type: 'area',
          name: 'Rate',
          color: Highcharts.color('#7DB5ED').setOpacity(0.6).get('rgba'),
          fillColor: Highcharts.color('#7DB5ED').setOpacity(0.2).get('rgba'),
          data: StrokeData.map((stroke) => {
            if (stroke.t < previousStroke.t) {
              intervalBaseStart +=
                parseFloat(WorkoutData.splits[currentInterval].splitTime) +
                parseFloat(WorkoutData.splits[currentInterval].splitRestTime);
              currentInterval++;
            }
            previousStroke = stroke;
            return {
              x: intervalBaseStart + stroke.t / 10,
              y: stroke.spm,
            };
          }),
        },
      ],
    };

    intervalBaseStart = 0.0;
    currentInterval = 0;
    previousStroke = { t: 0 };
    const hrChartOptions = {
      ...options,
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null,
        },
        min: 0,
      },
      tooltip: {
        positioner: function () {
          return {
            x: this.chart.chartWidth - this.label.width,
            y: 15,
          };
        },
        pointFormatter: function (e) {
          return '<b>Heart rate:</b> ' + this.y + ' BPM';
        },
        borderWidth: 0,
        backgroundColor: 'none',
        headerFormat: '',
        shadow: false,
        style: {
          fontSize: '18px',
        },
      },
      series: [
        {
          type: 'area',
          name: 'Heart rate',
          color: Highcharts.color('#ff0000').setOpacity(0.6).get('rgba'),
          fillColor: Highcharts.color('#ff0000').setOpacity(0.2).get('rgba'),
          data: StrokeData.map((stroke) => {
            if (stroke.t < previousStroke.t) {
              intervalBaseStart +=
                parseFloat(WorkoutData.splits[currentInterval].splitTime) +
                parseFloat(WorkoutData.splits[currentInterval].splitRestTime);
              currentInterval++;
            }
            previousStroke = stroke;
            return {
              x: intervalBaseStart + stroke.t / 10,
              y: stroke.hr,
            };
          }),
        },
      ],
    };

    this.setState({ paceChartOptions, rateChartOptions, hrChartOptions });
  }

  onChartCreated = (chart) => {
    this.chart = chart;
    const container = chart.container;
    ['mouseleave', 'mouseout'].forEach(function (eventType) {
      container.addEventListener(eventType, function (e) {
        var chart, point, i, event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
          try {
            chart = Highcharts.charts[i];
            event = chart.pointer.normalize(e);
            point = chart.series[0].searchPoint(event, true);

            point.onMouseOut();
            chart.tooltip.hide(point);
            chart.xAxis[0].hideCrosshair();
          } catch (e) {}
        }
      });
    });
    ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
      container.addEventListener(eventType, function (e) {
        var chart, point, i, event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
          try {
            chart = Highcharts.charts[i];
            event = chart.pointer.normalize(e);
            point = chart.series[0].searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          } catch (e) {}
        }
      });
    });
  };

  render() {
    const {
      paceChartOptions,
      rateChartOptions,
      hrChartOptions,
      currentSplitInterval,
    } = this.state;
    const { classes, workout } = this.props;
    const workoutType = workout.get('workoutType');
    const segmentType =
      workoutType.get('valueType') === 4 ? 'Intervals' : 'Splits';
    return (
      <>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <WorkoutStatsOverview workout={workout} />
          </Grid>

          <Grid xs={12} lg={8} item>
            <Paper className={classes.content}>
              {currentSplitInterval === -1 && (
                <WorkoutStats workout={workout} />
              )}
              {currentSplitInterval > -1 && (
                <WorkoutSplitIntervalStats
                  valueType={workoutType.get('valueType')}
                  splitInterval={
                    workout.get('data').WorkoutData.splits[currentSplitInterval]
                  }
                />
              )}
              <HighchartsReact
                highcharts={Highcharts}
                options={paceChartOptions}
                callback={this.onChartCreated}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={rateChartOptions}
                callback={this.onChartCreated}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={hrChartOptions}
                callback={this.onChartCreated}
              />
            </Paper>
          </Grid>
          <Grid xs={12} lg={4} item>
            <Card>
              <CardHeader
                title={segmentType}
                subheader='Tap any row below to zoom in to it in the graphs.'
              />
              <CardContent className={classes.cardContent}>
                <Button
                  variant='text'
                  color='primary'
                  onClick={this.resetZoom}
                  disabled={currentSplitInterval === -1}
                >
                  Back to Overview
                </Button>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Meters</TableCell>
                      <TableCell>Pace</TableCell>
                      <TableCell>SPM</TableCell>
                      <TableCell>Cals</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      className={classes.tableRowHeader}
                      onClick={() => this.resetZoom()}
                      selected={currentSplitInterval === -1}
                      hover
                    >
                      <TableCell>
                        <TimeFormat
                          time={workout.get('duration')}
                          withMsPrecision
                        />
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={workout.get('meters')}
                          displayType='text'
                          thousandSeparator={true}
                          decimalScale={0}
                        />
                      </TableCell>
                      <TableCell>
                        <TimeFormat
                          time={workout.get('averageSplitTime')}
                          withMsPrecision
                        />
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={workout.get('averageSPM')}
                          displayType='text'
                          thousandSeparator={true}
                          decimalScale={0}
                        />
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          value={workout.get('caloriesBurned')}
                          displayType='text'
                          thousandSeparator={true}
                          decimalScale={0}
                        />
                      </TableCell>
                    </TableRow>
                    {workout
                      .get('data')
                      .WorkoutData.splits.map((split, index) => (
                        <TableRow
                          onClick={() => this.zoomToSplitInterval(index)}
                          className={classes.tableRow}
                          selected={currentSplitInterval === index}
                          hover
                        >
                          <TableCell>
                            <TimeFormat
                              time={split.splitTime}
                              withMsPrecision
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={split.splitDistance}
                              displayType='text'
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <TimeFormat
                              time={split.splitAvgPace}
                              withMsPrecision
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={split.splitStrokeRate}
                              displayType='text'
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={split.splitCals}
                              displayType='text'
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(useStyles)(WorkoutSummary);

import { Chip, Grid, makeStyles, Slider, Typography } from '@material-ui/core';
import React from 'react';
import { User, Segment } from '../../../parse';
import HRValue from './HRValue';
import { useDispatch } from 'react-redux';
import { setTargetHeartRate } from '../../../redux/ducks/workoutbuilder';

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const TargetHR = ({ index, target }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant='h5'>TARGET HEART RATE</Typography>
        </Grid>

        <Grid item>
          <HRValue value={target} />
        </Grid>
      </Grid>

      <Slider
        step={1}
        valueLabelDisplay='off'
        min={0}
        max={100}
        value={target.value}
        onChange={(_, value) =>
          dispatch(
            setTargetHeartRate({
              index,
              type: Segment.HRM_TARGET_TYPE_CUSTOM,
              value,
            })
          )
        }
      />

      <div className={classes.chipGroup}>
        <Chip
          label='Custom'
          color={target.type === -1 ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetHeartRate({
                index,
                type: Segment.HRM_TARGET_TYPE_CUSTOM,
                value: [0, 100],
              })
            )
          }
          clickable
        />

        <Chip
          label='Zone 1 (UT2)'
          color={target.type === 0 ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetHeartRate({
                index,
                type: Segment.HRM_TARGET_TYPE_ZONE1,
                value: User.heartRateZones[Segment.HRM_TARGET_TYPE_ZONE1],
              })
            )
          }
          clickable
        />

        <Chip
          label='Zone 2 (UT1)'
          color={target.type === 1 ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetHeartRate({
                index,
                type: Segment.HRM_TARGET_TYPE_ZONE2,
                value: User.heartRateZones[Segment.HRM_TARGET_TYPE_ZONE2],
              })
            )
          }
          clickable
        />

        <Chip
          label='Zone 3 (AT)'
          color={target.type === 2 ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetHeartRate({
                index,
                type: Segment.HRM_TARGET_TYPE_ZONE3,
                value: User.heartRateZones[Segment.HRM_TARGET_TYPE_ZONE3],
              })
            )
          }
          clickable
        />

        <Chip
          label='Zone 4 (TR)'
          color={target.type === 3 ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetHeartRate({
                index,
                type: Segment.HRM_TARGET_TYPE_ZONE4,
                value: User.heartRateZones[Segment.HRM_TARGET_TYPE_ZONE4],
              })
            )
          }
          clickable
        />

        <Chip
          label='Zone 5 (AN)'
          color={target.type === 4 ? 'primary' : ''}
          onClick={(_) =>
            dispatch(
              setTargetHeartRate({
                index,
                type: Segment.HRM_TARGET_TYPE_ZONE5,
                value: User.heartRateZones[Segment.HRM_TARGET_TYPE_ZONE5],
              })
            )
          }
          clickable
        />
      </div>
    </>
  );
};

export default TargetHR;

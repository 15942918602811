import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  ListItemSecondaryAction,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItemLink from '../../../components/ListItemLink';
import Pluralize from 'react-pluralize';
import {
  PlaylistPlayRounded as PlaylistPlayIcon,
  ChevronRightRounded as ChevronRightIcon,
} from '@material-ui/icons';
import { fetchCollectionsIfNeeded } from '../../../redux/ducks/global';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
}));

const Collections = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.global.collections);

  useEffect(() => {
    dispatch(fetchCollectionsIfNeeded());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <CardHeader title='Collections' />
      <CardContent className={classes.content}>
        {collections.isFetching && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        {collections.items && (
          <List>
            {collections.items.map((collection, index) => {
              const base = collection.get('base');
              const workouts = (base.get('items') || []).length;
              const completed = (collection.get('workouts') || []).length;
              return (
                <ListItem
                  component={ListItemLink}
                  to={`/collections/${collection.id}`}
                  button
                >
                  <ListItemAvatar>
                    <Avatar
                      variant='rounded'
                      src={base.has('image') && base.get('image').url()}
                    >
                      <PlaylistPlayIcon color='action' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={base.get('name')}
                    secondary={
                      <>
                        <Pluralize singular='workout' count={workouts} /> •{' '}
                        {completed} completed
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <ChevronRightIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default Collections;

import {
  Card,
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
} from '@material-ui/core';
import {
  LanguageRounded as CommunityIcon,
  PeopleRounded as FriendsFollowersIcon,
  StyleRounded as TagsIcon,
  StarRounded,
} from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPublic,
  setFriend,
  toggleTag,
  setFeatured,
} from '../../../redux/ducks/workoutbuilder';

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function ListItemLink(props) {
  return <ListItem button component='a' {...props} />;
}

const SettingsTags = ({ canEditWorkout }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const state = useSelector((state) => state.workoutbuilder);

  return (
    <Card>
      <CardHeader title='Settings &amp; Tags' />
      <Divider />
      <List>
        <ListItemLink
          disabled={!canEditWorkout && state.isPublic}
          alignItems='flex-start'
          onClick={(_) => dispatch(setPublic(!state.isPublic))}
        >
          <ListItemIcon>
            <CommunityIcon />
          </ListItemIcon>
          <ListItemText
            primary='Community workout'
            secondary='Make publicly available, once public you can not edit the workout anymore'
          />
          <ListItemSecondaryAction>
            <Switch
              disabled={!canEditWorkout && state.isPublic}
              edge='end'
              checked={state.isPublic}
              onChange={(_, state) => dispatch(setPublic(state))}
            />
          </ListItemSecondaryAction>
        </ListItemLink>
        <ListItemLink
          disabled={!canEditWorkout && state.isFriend}
          alignItems='flex-start'
          onClick={(_) => dispatch(setFriend(!state.isFriend))}
        >
          <ListItemIcon>
            <FriendsFollowersIcon />
          </ListItemIcon>
          <ListItemText
            primary='Friends &amp; Followers'
            secondary='Allow your friends &amp; followers to use this workout'
          />
          <ListItemSecondaryAction>
            <Switch
              disabled={!canEditWorkout && state.isFriend}
              edge='end'
              checked={state.isFriend}
              onChange={(_, state) => dispatch(setFriend(state))}
            />
          </ListItemSecondaryAction>
        </ListItemLink>
        {user.get('isFeatured') === true && (
          <ListItemLink
            alignItems='flex-start'
            onClick={(_) => dispatch(setFeatured(!state.isFeatured))}
          >
            <ListItemIcon>
              <StarRounded />
            </ListItemIcon>
            <ListItemText
              primary='Featured workout'
              secondary='Enabling will allow the workout to show up in carousels in-app and on the web.'
            />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                checked={state.isFeatured}
                onChange={(_, state) => dispatch(setFeatured(state))}
              />
            </ListItemSecondaryAction>
          </ListItemLink>
        )}
      </List>
      <Divider />
      <List>
        <ListItem alignItems='flex-start'>
          <ListItemIcon>
            <TagsIcon />
          </ListItemIcon>
          <div className={classes.chipGroup}>
            <Chip
              label='Power'
              color={state.tags[0] === 1 ? 'primary' : ''}
              onClick={(_) => dispatch(toggleTag(0))}
              clickable
            />
            <Chip
              label='Cardio'
              color={state.tags[1] === 1 ? 'primary' : ''}
              onClick={(_) => dispatch(toggleTag(1))}
              clickable
            />
            <Chip
              label='Cross training'
              color={state.tags[2] === 1 ? 'primary' : ''}
              onClick={(_) => dispatch(toggleTag(2))}
              clickable
            />
            <Chip
              label='HIIT'
              color={state.tags[3] === 1 ? 'primary' : ''}
              onClick={(_) => dispatch(toggleTag(3))}
              clickable
            />
            <Chip
              label='Speed'
              color={state.tags[4] === 1 ? 'primary' : ''}
              onClick={(_) => dispatch(toggleTag(4))}
              clickable
            />
            <Chip
              label='Weight loss'
              color={state.tags[5] === 1 ? 'primary' : ''}
              onClick={(_) => dispatch(toggleTag(5))}
              clickable
            />
          </div>
        </ListItem>
      </List>
    </Card>
  );
};

export default SettingsTags;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLiveWorkoutIfNeeded } from "../../redux/ducks/live";

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const LiveWorkoutDetails = ({ id }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => {
    history.push("/liveworkouts");
  };

  const dispatch = useDispatch();
  const result = useSelector((state) => state.live.byId[id]) || {
    isFetching: true,
  };

  useEffect(() => {
    dispatch(fetchLiveWorkoutIfNeeded({ id }));
  }, [dispatch, id]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={result.isFetching !== true}
      >
        <DialogTitle className={classes.titleRoot} disableTypography>
          <Typography variant="h2">Live Workout Details</Typography>

          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>Coming soon..</DialogContent>
      </Dialog>
    </>
  );
};

export default LiveWorkoutDetails;

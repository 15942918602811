import { makeStyles } from "@material-ui/core";
import React from "react";
import { Route, useLocation, useRouteMatch, Switch } from "react-router-dom";
import Header from "../../components/Header";
import { StyledTab, StyledTabs } from "../../components/StyledTabs";
import GeneralInfo from "./components/GeneralInfo";
import { getContent } from "@mui-treasury/layout";
import styled from "styled-components";

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(15),
    padding: theme.spacing(3),
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const MyAccount = ({ user }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <>
      <Header
        user={user}
        title="My Account"
        subtitle="Change profile information, settings, subscription, etc."
      />
      <Content className={classes.container}>
        <StyledTabs
          className={classes.tabsContainer}
          value={location.pathname}
          variant="standard"
        >
          <StyledTab label="General" value={match.url} to={match.url} />

          <StyledTab
            label="Subscription"
            to={`/subscription/${user.get("email")}`}
          />
        </StyledTabs>
        <Switch>
          <Route path="/me" exact>
            <GeneralInfo user={user} />
          </Route>
          <Route path="/me/subscription" exact>
            subscription
          </Route>
        </Switch>
      </Content>
    </>
  );
};

export default MyAccount;

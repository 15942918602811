import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { loadStripe } from "@stripe/stripe-js";
import Parse from "parse";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "../../theme";
import CustomWorkouts from "./custom-workouts.png";
import AudioGuidance from "./audio-guidance.png";
import Leaderboards from "./leaderboards.png";
const stripePromise = loadStripe("pk_live_gT2dDfsdEN7CGjSiogc4NxyR");

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    flexGrow: 1,
    display: "flex",
    height: "100vh",
    overflow: "scroll",
  },
  container: {
    margin: "auto",
    paddingTop: theme.spacing(5),
  },
  paper: {
    maxWidth: 600,
    width: "90vw",
    marginBottom: theme.spacing(2),
  },
  paperContent: {
    padding: theme.spacing(2),
  },
  benefit: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    position: "relative",
    top: -theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: -theme.spacing(3),
    border: "4px solid #fafafa",
  },
  benefitImage: {
    width: 64,
    float: "right",
    marginLeft: 8,
  },
}));

const Subscription = () => {
  const { userId } = useParams();
  const classes = useStyles();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [username, setUsername] = useState(userId);
  const [usernameOrEmail, setUsernameOrEmail] = useState(userId);

  useEffect(() => {
    async function fetchData(userId) {
      const query = new Parse.Query("_User");

      try {
        if (username.toLowerCase() === "me" && Parse.User.current()) {
          setUser(Parse.User.current());
        } else if (username.indexOf("@") > -1) {
          query.equalTo("email", username);
          setUser(await query.first({ sessionToken: "" }));
        } else {
          query.equalTo("username", username);
          setUser(await query.first({ sessionToken: "" }));
        }
      } catch (e) {
        //setError(e.message);
      } finally {
        setLoading(false);
      }
    }
    fetchData(username);
  }, [username]);

  const handleSubscriptionClick = async (plan) => {
    try {
      setLoading(true);
      const sessionId = await Parse.Cloud.run("getStripePortalUrl", {
        userId: user.id,
        plan,
      });

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) setError(error);
    } catch (e) {
      alert("Something went wrong, please contact support@krewfit.net");
    }
  };

  const manageStripeSubscription = async () => {
    try {
      setLoading(true);
      const result = await Parse.Cloud.run("getStripePortalUrl", {
        userId: user.id,
      });

      window.location.assign(result);
    } catch (e) {
      alert("Something went wrong, please contact support@krewfit.net");
    }
  };

  if (loading)
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <CircularProgress color="primary" />
        </div>
      </div>
    );

  if (error)
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography variant="h2">Uhoh, could not find that user</Typography>
        </div>
      </div>
    );

  if (!user) {
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <div className={classes.paperContent}>
              <Typography variant="h3">Unable to find that user</Typography>

              <form
                onSubmit={(e) => {
                  setUsername(usernameOrEmail);
                  e.preventDefault();
                }}
              >
                <TextField
                  label="Username or e-mail"
                  type="text"
                  margin="normal"
                  value={usernameOrEmail}
                  onChange={(e) => setUsernameOrEmail(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <Button variant="contained" color="primary" type="submit">
                  Check my options
                </Button>
              </form>
            </div>
          </Paper>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Avatar
            className={classes.avatar}
            src={user.has("image") && user.get("image").url()}
          />

          <Typography
            variant="overline"
            component="p"
            style={{ paddingBottom: theme.spacing(2), textAlign: "center" }}
          >
            {user.get("username")} {" | "}
            {user.isPremiumSubscriber() ? "Premium" : "Free Plan"}
          </Typography>

          <div style={{ backgroundColor: "#f1f1f1" }}>
            <div className={classes.paperContent}>
              {user.isPremiumSubscriber() ? (
                <>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    style={{ textAlign: "center" }}
                  >
                    Your subscription expires on{" "}
                    {new Intl.DateTimeFormat().format(user.get("paidThru"))}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    style={{ textAlign: "center" }}
                  >
                    Unlock new features to help you reach your goals
                  </Typography>
                </>
              )}
            </div>
          </div>
          <div className={classes.paperContent}>
            {user.isPremiumSubscriber() ? (
              user.get("purchasePlatform") === "Google" ? (
                "You subscribed to KREW via Google Play, please use the app or play.google.com to manage your subscription."
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={manageStripeSubscription}
                    variant="contained"
                    color="primary"
                  >
                    Manage subscription
                  </Button>
                </div>
              )
            ) : (
              <>
                <Grid spacing={2} container>
                  <Grid xs={6} item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(_) => {
                        handleSubscriptionClick("plan_HButtO0vNunfIt");
                      }}
                    >
                      Monthly ($9.99)
                    </Button>
                  </Grid>
                  <Grid xs={6} item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(_) => {
                        handleSubscriptionClick("plan_HBuuaoEg0HUbhm");
                      }}
                    >
                      Yearly ($49.99)
                    </Button>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{
                        marginTop: theme.spacing(1),
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <WhatshotIcon fontSize="small" />
                      <span>Most Popular, a savings of 58%</span>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </Paper>

        {!user.isPremiumSubscriber() && (
          <Paper className={classes.paper}>
            <div className={classes.paperContent}>
              <Card variant="outlined" className={classes.benefit}>
                <CardContent>
                  <img
                    src={CustomWorkouts}
                    className={classes.benefitImage}
                    alt=""
                  />
                  <Typography variant="h6" gutterBottom>
                    Build Interval Workouts
                  </Typography>
                  <Typography variant="body1">
                    Endless workout combinations with the custom interval
                    builder.
                  </Typography>
                </CardContent>
              </Card>

              <Card variant="outlined" className={classes.benefit}>
                <CardContent>
                  <img
                    src={AudioGuidance}
                    className={classes.benefitImage}
                    alt=""
                  />

                  <Typography variant="h6" gutterBottom>
                    Audio Guidance
                  </Typography>
                  <Typography variant="body1">
                    Get live workout updates stats, progress and targets.
                  </Typography>
                </CardContent>
              </Card>

              <Card variant="outlined" className={classes.benefit}>
                <CardContent>
                  <img
                    src={Leaderboards}
                    className={classes.benefitImage}
                    alt=""
                  />
                  <Typography variant="h6" gutterBottom>
                    Leaderboards
                  </Typography>
                  <Typography variant="body1">
                    See progress, get results. Move with motivation,
                    determination and with a community.
                  </Typography>
                </CardContent>
              </Card>

              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Compare &amp; Compete
                  </Typography>
                  <Typography variant="body1">
                    Compare workout results and challenge your previous workouts
                    or other users via leaderboards.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default Subscription;

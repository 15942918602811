import Parse from "parse";
import {
  WorkoutType,
  Segment,
  Collection,
  CollectionBase,
  Comment,
  User,
} from "./parse";

Parse.initialize("ugn8WWO3EcgFvcTaFIMyOaE6RldMWwkDScwC1hwo");
Parse.serverURL = "https://app.krewfit.net/";
//Parse.serverURL = "http://localhost:4000/";

Parse.Object.registerSubclass("WorkoutType", WorkoutType);
Parse.Object.registerSubclass("Segment", Segment);
Parse.Object.registerSubclass("Playlist", Collection);
Parse.Object.registerSubclass("PlaylistBase", CollectionBase);
Parse.Object.registerSubclass("Comment", Comment);
Parse.Object.registerSubclass("_User", User);

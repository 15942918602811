import {
  Button,
  Divider,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import KrewIcon from '../../../components/KrewIcon/KrewIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(3),
    maxWidth: 800,
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  logo: {
    color: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
}));

const Signup = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <KrewIcon className={classes.logo} />
      <Paper className={classes.paper}>
        <Typography variant='h4'>Sign up</Typography>
        <form noValidate autoComplete='off'>
          <TextField
            className={classes.formControl}
            variant='outlined'
            label='Username'
            autoComplete='off'
            type='text'
            InputProps={{
              type: 'text',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position='start'>
                  <AccountCircleRoundedIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className={classes.formControl}
            variant='outlined'
            label='Email'
            autoComplete='off'
            type='email'
            InputProps={{
              type: 'email',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailRoundedIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className={classes.formControl}
            variant='outlined'
            autoComplete='off'
            label='Password'
            InputProps={{
              type: 'password',
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position='start'>
                  <LockRoundedIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant='contained'
            className={classes.formControl}
            color='primary'
          >
            Join now
          </Button>
        </form>

        <Divider className={classes.divider} />

        <Link component={RouterLink} to='/auth/login'>
          Have an account?
        </Link>
      </Paper>
    </div>
  );
};

export default Signup;

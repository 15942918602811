import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { PlaylistPlayRounded as PlaylistPlayIcon } from '@material-ui/icons';
import { getContent } from '@mui-treasury/layout';
import React, { useEffect } from 'react';
import Pluralize from 'react-pluralize';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/Header';
import ListItemLink from '../../components/ListItemLink';
import {
  CREATED_BY_COMMUNITY,
  CREATED_BY_FRIENDS,
  CREATED_BY_ME,
  fetchCollectionsIfNeeded,
  setCreatedBy,
} from '../../redux/ducks/collections';
import FeaturedCollections from './components/FeaturedCollections';
import { Collection } from '../../parse';

const Content = getContent(styled);

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  largeAvatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const Collections = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const createdBy = useSelector((state) => state.collections.createdBy);
  const result = useSelector((state) => state.collections.collections) || {
    isFetching: true,
    items: [],
  };
  useEffect(() => {
    dispatch(fetchCollectionsIfNeeded({ createdBy }));
  }, [dispatch, createdBy]);
  const collections = result.items;

  return (
    <>
      <Header
        user={user}
        title='Collections'
        subtitle='Public &amp; Featured collections and collections created by friends can be found here.'
      />
      <Content className={classes.container}>
        <Grid spacing={2} direction='row-reverse' container>
          <Grid xs={12} item>
            <FeaturedCollections />
          </Grid>
          <Grid xs={12} item>
            <Card>
              <CardHeader title='Collections created by' />
              <CardContent>
                <Chip
                  label='Community'
                  color={createdBy === CREATED_BY_COMMUNITY ? 'primary' : ''}
                  onClick={() => dispatch(setCreatedBy(CREATED_BY_COMMUNITY))}
                  disabled={result.isFetching}
                />{' '}
                <Chip
                  label='Friends'
                  color={createdBy === CREATED_BY_FRIENDS ? 'primary' : ''}
                  onClick={() => dispatch(setCreatedBy(CREATED_BY_FRIENDS))}
                  disabled={result.isFetching}
                />{' '}
                <Chip
                  label='Me'
                  color={createdBy === CREATED_BY_ME ? 'primary' : ''}
                  onClick={() => dispatch(setCreatedBy(CREATED_BY_ME))}
                  disabled={result.isFetching}
                />
                {result.isFetching && (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                )}
                {!result.isFetching && collections && (
                  <List>
                    {collections.map((collection, index) => {
                      let base, link;
                      if (collection instanceof Collection) {
                        base = collection.get('base');
                        link = '/collections/' + collection.id;
                      } else {
                        base = collection;
                        link = '/collections/shared/' + base.id;
                      }
                      const workoutCount = base.has('items') ? base.get('items').length : 0;
                      const description = base.has('description')
                        ? base.get('description').substring(0, 250) + '..'
                        : false;

                      return (
                        <ListItem
                          key={index}
                          alignItems='flex-start'
                          component={ListItemLink}
                          to={link}
                          button
                        >
                          <ListItemAvatar>
                            <Avatar
                              variant='rounded'
                              src={base.has('image') && base.get('image').url()}
                            >
                              <PlaylistPlayIcon color='action' />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={base.get('name')}
                            secondary={
                              <>
                                <Typography
                                  component='span'
                                  variant='body2'
                                  className={classes.inline}
                                  color='textPrimary'
                                >
                                  <Pluralize
                                    singular={'workout'}
                                    count={workoutCount}
                                  />
                                </Typography>
                                {description && ` — ${description}`}
                              </>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default Collections;

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import image from './undraw_Taken_if77.svg';
import { getContent } from '@mui-treasury/layout';
import styled from 'styled-components';

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  image: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
    maxWidth: '100%',
    width: '30vh',
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Content className={classes.root}>
      <Typography variant='h1' gutterBottom>
        That's embarrassing..
      </Typography>
      <Typography variant='subtitle2' gutterBottom>
        Seems like we can't find that page.
      </Typography>
      <img alt='Not found' className={classes.image} src={image} />
    </Content>
  );
};

export default NotFound;

import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCollectionsIfNeeded } from "../../../redux/ducks/global";
import { toggleAddToCollection } from "../../../redux/ducks/workoutbuilder";

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
}));

const AddToCollection = ({ workoutTypeId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addToCollections = useSelector(
    (state) => state.workoutbuilder.addToCollections
  );
  const { user, collections } = useSelector((state) => state.global);

  useEffect(() => {
    dispatch(fetchCollectionsIfNeeded());
  }, [dispatch]);

  useEffect(() => {
    collections.items
      .filter(
        (collection) => collection.get("base").get("createdBy").id === user.id
      )
      .forEach((collection) => {
        const base = collection.get("base");
        const items = base.get("items");
        if (
          items &&
          items.filter((item) => item.has('workoutType') && item.get("workoutType").id === workoutTypeId)
            .length > 0
        ) {
          dispatch(toggleAddToCollection({ base: base.id, state: true }));
        }
      });
  }, [dispatch, user, workoutTypeId, collections.items]);

  return (
    <Card>
      <CardHeader title="Add to collection" />
      <Divider />
      <CardContent>
        {collections.isFetching && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        {collections.items && (
          <div className={classes.chipGroup}>
            {collections.items
              .filter(
                (collection) =>
                  collection.get("base").get("createdBy").id === user.id
              )
              .map((collection) => {
                const base = collection.get("base");
                return (
                  <Chip
                    key={base.id}
                    label={base.get("name")}
                    color={addToCollections[base.id] ? "primary" : ""}
                    onClick={(_) =>
                      dispatch(
                        toggleAddToCollection({
                          base: base.id,
                          state: !addToCollections[base.id],
                        })
                      )
                    }
                    clickable
                  />
                );
              })}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default AddToCollection;

import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

const WorkoutStats = ({ workout }) => {
  return (
    <>
      <Typography variant='h4' gutterBottom>
        Workout summary
      </Typography>
      <Grid spacing='2' container>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Average watts
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={workout.get('averageWatts')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Calories
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={workout.get('caloriesBurned')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Strokes
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={workout.get('totalStrokeCount')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Drag Factor
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={workout.get('dragFactor')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline' gutterBottom>
            Heart rate
          </Typography>
          <Typography variant='h6'>
            <NumberFormat
              value={workout.get('averageHeartRate')}
              displayType='text'
              thousandSeparator={true}
              decimalScale={0}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkoutStats;

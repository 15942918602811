const palette = {
  common: { black: "rgba(0, 0, 0, 1)", white: "#fff" },
  background: { paper: "#fff", default: "#ff0000", secondary: "#f1f1f1" },
  primary: {
    light: "rgba(155, 172, 195, 1)",
    main: "#577399",
    dark: "rgba(34, 65, 105, 1)",
    contrastText: "#fff",
  },
  secondary: {
    light: "rgba(48, 84, 130, 1)",
    main: "rgba(55, 92, 138, 1)",
    dark: "rgba(48, 84, 130, 1)",
    contrastText: "#fff",
  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
  },

  valueType: {
    1: { background: "#7BB4A7", border: "#4F9686" },
    2: { background: "#9189BE", border: "#685FA1" },
    3: { background: "#9189BE", border: "#685FA1" },
    4: { background: "#8298B7", border: "#577399" },
    5: { background: "#577399", border: "#8298B7" },
  },
};

export default palette;

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { getContent } from '@mui-treasury/layout';
import styled from 'styled-components';

const Content = getContent(styled);
const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1101,
    marginTop: -theme.spacing(12),
    padding: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  input: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const Explorer = ({ user }) => {
  const classes = useStyles();
  const createdByPopupState = usePopupState({
    variant: 'popover',
    popupId: 'createdBy',
  });
  const typePopupState = usePopupState({ variant: 'popover', popupId: 'type' });
  const durationPopupState = usePopupState({
    variant: 'popover',
    popupId: 'duration',
  });

  return (
    <>
      <Header
        user={user}
        title='Workout Explorer'
        subtitle={
          'KREW has thousands of workouts to choose from, find your favourite here.'
        }
      />
      <Content className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper component='form' style={{ overflow: 'hidden' }}>
              <InputBase
                className={classes.input}
                placeholder='Search by name'
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Divider />
              <Box p={2}>
                <Chip
                  label='Community'
                  className={classes.chip}
                  onDelete={(_) => {}}
                />
                <Chip
                  label='20-30 min'
                  className={classes.chip}
                  onDelete={(_) => {}}
                />
              </Box>
              <Divider />
              <Box
                px={1}
                py={1.5}
                bgcolor='#fafafa'
                style={{ display: 'flex' }}
              >
                <Button
                  size='medium'
                  endIcon={<ArrowDropDownIcon />}
                  {...bindTrigger(createdByPopupState)}
                >
                  Created by
                </Button>
                <Popover {...bindPopover(createdByPopupState)}>
                  <List>
                    <ListItem button>
                      <ListItemText primary='Me' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='Featured' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='KREW' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='Community' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='Friends' />
                    </ListItem>
                  </List>
                </Popover>
                <Button
                  size='medium'
                  endIcon={<ArrowDropDownIcon />}
                  {...bindTrigger(typePopupState)}
                >
                  Type
                </Button>
                <Popover {...bindPopover(typePopupState)}>
                  <List>
                    <ListItem button>
                      <ListItemText primary='Any' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='Single distance' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='Single time' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='Custom intervals' />
                    </ListItem>
                  </List>
                </Popover>
                <Button
                  size='medium'
                  endIcon={<ArrowDropDownIcon />}
                  {...bindTrigger(durationPopupState)}
                >
                  Duration
                </Button>
                <Popover {...bindPopover(durationPopupState)}>
                  <List>
                    <ListItem button>
                      <ListItemText primary='Any duration' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='10-20min' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='20-30min' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='30-45min' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='45-60min' />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary='+60min' />
                    </ListItem>
                  </List>
                </Popover>

                <div style={{ flex: 1, textAlign: 'right' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label='Target rate'
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label='Target pace'
                  />
                </div>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardActionArea component={Link} to='/explorer/maxeffort'>
                <CardMedia
                  className={classes.media}
                  image='https://liverowing-media-staging.s3.amazonaws.com/0fd06e1d634d6be8ca74f5fa4161e172_conor_sunGlasses.png'
                  title='MAX EFFORT TWO'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='h2'>
                    MAX EFFORT TWO
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    component='p'
                  >
                    Maximum Effort: 20 seconds x 8 times | Rest period: 3
                    minutes
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size='small' color='primary'>
                  Share
                </Button>
                <Button size='small' color='primary'>
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default Explorer;

import { combineReducers } from "redux";
import { Collection, User } from "../../parse";
import history from "../../history";
import { setUserId, initializeAnalytics } from "firebase/analytics";
import app from "../../firebase";

export const REQUEST_COLLECTIONS = "global/REQUEST_COLLECTIONS";
export const RECEIVE_COLLECTIONS = "global/RECEIVE_COLLECTIONS";

export const LOGOUT_SUCCESS = "global/LOGOUT_SUCCESS";
export const SET_USER = "global/SET_USER";

// #region Collections
export const fetchCollectionsIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchCollections(getState())) {
      return dispatch(fetchCollections());
    }
  };
};

function requestCollections() {
  return {
    type: REQUEST_COLLECTIONS,
  };
}

function receiveCollections(items) {
  return {
    type: RECEIVE_COLLECTIONS,
    receivedAt: Date.now(),
    items,
  };
}

function fetchCollections(payload) {
  return async (dispatch) => {
    dispatch(requestCollections(payload));
    const query = Collection.query();
    query.descending("updatedAt");
    const items = await query.find();
    return dispatch(receiveCollections(items));
  };
}

function shouldFetchCollections(state) {
  const collections = state.global.collections;
  if (collections.items.length === 0) {
    return true;
  } else if (collections.isFetching) {
    return false;
  } else {
    return collections.didInvalidate;
  }
}

const collections = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COLLECTIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_COLLECTIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
};
// #endregion

export const logout = () => {
  return async (dispatch) => {
    try {
      await User.logOut();
    } catch (e) {}
    history.push("/auth/login");
    dispatch({ type: LOGOUT_SUCCESS });
  };
};

export const refreshUser = () => {
  return async (dispatch) => {
    const user = User.current();
    if (user) {
      try {
        await user.fetchWithInclude("userStats");
        dispatch(setUser(user));
      } catch (e) {
        dispatch(logout());
      }
    }
  };
};

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

const user = (state = User.current(), action) => {
  switch (action.type) {
    case SET_USER:
      try {
        const analytics = initializeAnalytics(app, {
          config: {
            userid: action.user.id,
            username: action.user.get("username"),
          },
        });
        setUserId(analytics, action.user.id, { global: true });
      } catch (e) {}
      return action.user;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  collections,
  user,
});
